/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Content = styled.div`
  background-color: rgb(23, 24, 54);
  height: 100%;
  max-height: calc(100% - 52px);
  overflow: hidden;
  flex: 1;
  padding: 26px 0px;
  margin-left: 70px;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

/* eslint-enable import/prefer-default-export */
