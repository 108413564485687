import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { authentication, auth } from 'app/authConfig';
import { toast } from 'react-toastify';

import {
  Container,
  ThemedButton,
  ThemedTextField,
  FormDiv,
  ContainerHeader,
  FirstSpacing,
  Spacing,
  DivButton,
} from './styles';

const changePasswordValidationschema = yup.object().shape({
  currentPassword: yup.string().min(6, 'A senha precisa ter 6 ou mais digitos')
    .required('Por favor, preencha a senha atual'),
  newPassword: yup.string().min(6, 'A senha precisa ter 6 ou mais digitos')
    .required('Por favor, preencha a nova senha'),
  retypePassword: yup.string().min(6, 'A senha precisa ter 6 ou mais digitos')
    .required('Por favor, preencha a confirmação da nova senha'),
});

const changePasswordInitialValue = {
  currentPassword: '',
  newPassword: '',
  retypePassword: '',
};

function ChangePassword() {
  function handleChangePassword(values, { setFieldError, resetForm, setSubmitting }) {
    if (values.newPassword === values.retypePassword) {
      const user = auth.currentUser;
      const credentials = authentication.EmailAuthProvider.credential(
        user.email,
        values.currentPassword,
      );
      user.reauthenticateWithCredential(credentials).then(() => {
        user.updatePassword(values.newPassword).then(() => {
          toast.info('Senha alterada com sucesso');
          resetForm({});
        }).catch(() => {
          setSubmitting(false);
          toast.error('Erro ao cadastrar nova senha');
        });
      }).catch(() => {
        setSubmitting(false);
        setFieldError('currentPassword', 'Senha incorreta');
      });
    } else {
      setSubmitting(false);
      setFieldError('retypePassword', 'A confirmação de senha está diferente da nova senha');
    }
  }
  return (
    <Container>
      <FormDiv>
        <ContainerHeader>
          Alterar senha
        </ContainerHeader>

        <Formik
          initialValues={changePasswordInitialValue}
          validationSchema={changePasswordValidationschema}
          onSubmit={handleChangePassword}
          validateOnChange={false}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <FirstSpacing>
                <ThemedTextField
                  id="currentPassword"
                  data-testid="currentPassword-input"
                  label="Senha atual"
                  name="currentPassword"
                  autoComplete="off"
                  type="password"
                  variant="outlined"
                  helperText={formik.errors.currentPassword ? formik.errors.currentPassword : ''}
                  error={formik.errors.currentPassword && Boolean(formik.errors.currentPassword)}
                  onChange={(e) => {
                    formik.setFieldValue('currentPassword', e.target.value);
                    formik.setFieldError('currentPassword', undefined);
                  }}
                  value={formik.values.currentPassword}
                />
              </FirstSpacing>
              <Spacing>
                <ThemedTextField
                  id="newPassword"
                  data-testid="newPassword-input"
                  label="Nova senha"
                  name="newPassword"
                  autoComplete="off"
                  type="password"
                  variant="outlined"
                  helperText={formik.errors.newPassword ? formik.errors.newPassword : ''}
                  error={formik.errors.newPassword && Boolean(formik.errors.newPassword)}
                  onChange={(e) => {
                    formik.setFieldValue('newPassword', e.target.value);
                    formik.setFieldError('newPassword', undefined);
                  }}
                  value={formik.values.newPassword}
                />
              </Spacing>
              <Spacing>
                <ThemedTextField
                  id="retypePassword"
                  data-testid="retypePassword-input"
                  label="Confirmar senha"
                  name="retypePassword"
                  autoComplete="off"
                  type="password"
                  variant="outlined"
                  helperText={formik.errors.retypePassword ? formik.errors.retypePassword : ''}
                  error={formik.errors.retypePassword && Boolean(formik.errors.retypePassword)}
                  onChange={(e) => {
                    formik.setFieldValue('retypePassword', e.target.value);
                    formik.setFieldError('retypePassword', undefined);
                  }}
                  value={formik.values.retypePassword}
                />
              </Spacing>
              <DivButton>
                <ThemedButton disabled={formik.isSubmitting} type="submit">Alterar</ThemedButton>
              </DivButton>
            </form>
          )}
        </Formik>
      </FormDiv>
    </Container>
  );
}

export default ChangePassword;
