import React from 'react';
import { Link } from 'react-router-dom';

import SetaEmpresas from 'assets/SetaEmpresas.svg';
import Switch from 'components/Switch';
import moment from 'moment';

import {
  Container, Header, Cell, Body, Row, Card,
} from './styles';

function Table({ data, onCompanyStatusChange, showingActive }) {
  return (
    <Container>
      <Header>
        <Cell>Nome</Cell>
        <Cell>Vagas abertas</Cell>
        <Cell>Requisitante</Cell>
        <Cell>Último evento</Cell>
        <Cell />
        <Cell />
      </Header>
      <Body>
        {data.map((item) => (
          <Row key={item.id}>
            <Card>
              <Cell>{item.company.name}</Cell>
              <Cell>{item.company.openVacancies}</Cell>
              <Cell>{item.user.name}</Cell>
              <Cell>{moment(item.company.lastEvent).format('DD/MM/YYYY')}</Cell>
              <Cell>
                <Link to={{ pathname: '/company', state: { id: item.companyId, idrequester: item.id } }}>
                  <img
                    width="16px"
                    height="24px"
                    src={SetaEmpresas}
                    alt="Acessar Empresa"
                  />
                </Link>
              </Cell>
            </Card>
            <Cell>
              <Switch
                name={item.company.id}
                onChange={onCompanyStatusChange}
                defaultChecked={showingActive}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Cell>
          </Row>
        ))}
      </Body>

    </Container>
  );
}

export default Table;
