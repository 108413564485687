import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { fetchRequesterById, setRequesterChoosedId } from 'features/requester';
import { fetchFirstVacancyByCompany, fetchEditVacancyId, setVacancyIdChoosed } from 'features/vacancy';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import voltarImg from 'assets/Voltar.svg';
import Pagination from 'components/Pagination';
import * as qs from 'utils/queryString';
import VacancyItem from './VacancyItem';

const Container = styled.div`
  padding: 0 var(--horizontal-padding);
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContainerTitulo = styled.div`
    flex: 1;
    margin-left: 18px;
`;
const TitleRegister = styled.span`
  font-size: 26px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const SubTitleRegister = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
  width: 100%;
`;

const EmptyList = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #9F9F9F;
`;

const ListCards = styled.div`
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '85px',
  },
})(Button);

const ThemedButtonAddCompany = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '200px',
    alignSelf: 'flex-start',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    direction: 'column',
  },
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(20),
      float: 'right',
      color: 'white',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function VacancyList() {
  const classes = useStyles();
  const history = useHistory();
  const { id: requesterId } = useParams();
  const dispatch = useDispatch();
  const {
    vacancies, requesters, totalList, authType, user,
  } = useSelector(
    ({ features, entities }) => ({
      requesters: entities.requesters,
      vacancies: entities.vacancies,
      totalList: features.vacancy.totalList,
      pageAccessed: features.vacancy.pagesAccessed,
      authType: features.auth.type,
      user: features.auth.user,
    }),
  );
  const requester = requesters.byId[requesterId];
  const page = Number(qs.qsStringToObject(history.location.search).page || 1);

  useEffect(() => {
    if (authType) {
      if (authType === 'requester' && (requesterId !== user.id)) {
        history.replace(`/vacancies/company/${user.id}`);
        return;
      }
      dispatch(fetchRequesterById(requesterId));
    }
    // eslint-disable-next-line
  }, [requesterId, authType]);

  useEffect(() => {
    if (requester) {
      dispatch(fetchFirstVacancyByCompany(requester.companyId, (page || 1) - 1));
    }
    // eslint-disable-next-line
  }, [requesters.byId, dispatch, history.location.search]);

  const handlePageChange = (event, value) => {
    history.push(`/vacancies/company/${requesterId}${qs.qsObjectToString({ page: value })}`);
  };

  const handleVacancy = () => {
    dispatch(fetchEditVacancyId(0));
    dispatch(setRequesterChoosedId(requesterId));
    history.push('/vacancyregistervaga');
  };

  const handleEditVacancy = (idvacancy) => {
    dispatch(fetchEditVacancyId(idvacancy));
    dispatch(setRequesterChoosedId(requesterId));
    history.push('/vacancyregistervaga');
  };

  const openVacancyKanban = (vacancyId) => {
    dispatch(setVacancyIdChoosed(vacancyId));
    history.push(`/vacancies/${vacancyId}/kanban`);
  };

  return (
    <Container>
      <ContainerHeader>
        {
       authType === 'admin' || authType === 'specialist'
         ? <BackButton onClick={history.goBack} />
         : <></>
        }
        <ContainerTitulo>
          {
              requester
                ? (
                  <div>
                    <TitleRegister>
                      {requester.company.name}
                    </TitleRegister>
                    <p />
                    <SubTitleRegister>
                      {requester.user.name}
                    </SubTitleRegister>
                    <SubTitleRegister style={{ paddingLeft: '10px' }}>
                      {requester.user.phoneNumber}
                    </SubTitleRegister>
                  </div>
                )
                : <></>
            }
        </ContainerTitulo>
        {
          authType === 'admin' || authType === 'specialist'
            ? (
              <ThemedButtonAddCompany onClick={handleVacancy}>
                Criar vaga
              </ThemedButtonAddCompany>
            )
            : <></>
        }
      </ContainerHeader>
      { totalList !== 0
        ? (
          <div>
            <ListCards className={classes.root}>
              <Grid container spacing={2}>
                { vacancies.allIds ? vacancies.allIds.map((item) => {
                  const vacancy = vacancies.byId[item];
                  return (
                    <Grid key={vacancy.id} item md={3} sm={4} xs={6}>
                      <VacancyItem vacancies={vacancy} edit={handleEditVacancy} onClick={openVacancyKanban} />
                    </Grid>
                  );
                }) : <></>}
              </Grid>
            </ListCards>
            <Pagination
              style={{ paddingRight: 0 }}
              count={totalList !== 0 ? Math.ceil(totalList / 8) : 1}
              page={page}
              onChange={handlePageChange}
              shape="rounded"
            />
          </div>
        )
        : (
          <EmptyList>
            Não existem vagas na Empresa selecionada
          </EmptyList>
        )}
    </Container>
  );
}

export default VacancyList;
