/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  createMuiTheme,
  MuiThemeProvider,
  FormControl,
  Select,
  Avatar,
  FormHelperText,
  Slider,
  makeStyles,
} from '@material-ui/core';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { storage } from 'app/authConfig';
import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import * as yup from 'yup';
import * as moment from 'moment';
import { useDebounce } from 'use-debounce';

import FormatGender from 'components/FormatGender';
import FormatStatus from 'components/FormatStatus';
import TextFieldMask from 'components/TextFieldMaks';
import uploadImg from 'assets/upload.svg';
import uploadCV from 'assets/cvupload.svg';
import downloadCV from 'assets/cvdownload.png';
import removeCV from 'assets/Remove.svg';
import { returnCities } from 'features/city';
import { uploadTalentProfilePic, uploadTalentCV } from 'features/auth';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AsyncAutoComplete from 'components/Autocomplete';
import Person from 'assets/Person.svg';
import { addNewTalent, updateTalentData } from '.';

const ComponentTheme = styled.div`
  background: #171836;
`;

const FormDiv = styled.div`
  margin-top: 18px;
`;

const PicDiv = styled.div`
  display: flex;
  flex-direction: row !important;
  height: 145px;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    margin-top: 16px;
  }
`;

const InputPic = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const ImgLabel = styled.img`
  display: block;
  margin-right: 18px;
`;

const CVdiv = styled.div`
  align-items: center;
  display: inline-block;
  height: 145px;
`;

const InputCV = styled.input`
  display: none;
`;

const UploadLabel = styled.label`
  color: #ffffff;
  font-size: 20pt;
  display: block;
  align-items: center;
  margin-top: 25px;
  text-align: center;
`;

const DivUpload = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const DivLabelDownload = styled.div`
  margin-left: 25%;
  margin-right: auto;

  display: inline-flex;

`;

const ButtonUpload = styled.button`
background: #171836;
border: #171836;
`;

const LabelCV = styled.label`
  color: #ffffff;
  font-size: 14pt;
  height: 35px;
  width: 200px;
  border: 1px solid #425AE0;
  border-radius:5px;
  display: block;
  padding-top: 10px;
  vertical-align: middle;
  text-align: center;
  align-items: center;
`;

const ImageCV = styled.img`
  margin-left: 40px;
  cursor: pointer;
  color: #ffffff;
`;

const DownloadDiv = styled.button`
cursor: pointer;
color: #ffffff;
background: #171836;
border: 1px solid #425AE0;
border-radius:5px;
margin-left: 10px;
height: 48px;
`;

const DownloadCV = styled.img`
  cursor: pointer;
  width: 30px;
  height:30px;
`;
const TextDiv = styled.div`
    padding-top: 32px;
    margin-bottom: 0px;
`;

const CityDiv = styled.div`

`;

const DoubleDiv = styled.div`
    width: 100%;
`;

const SexDiv = styled.div`
  width:40%;
  display: inline-block;
`;

const DateDiv = styled.div`
  width: 50%;
  display: inline-block;
  margin-left:10%;
`;

const StatusDiv = styled.div`
    width: 442px;
`;

const DivButton = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  padding: 0px 20%;
`;

const UploadPictureButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border: solid 1px #425AE0;
  border-radius: 5px;
  color: #ffffff;
`;

const ThemedAvatar = withStyles({
  root: {
    width: '110px',
    height: '110px',
  },
})(Avatar);

const RaisedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '100px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const ThemedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '442px',
    height: '45px',
    zIndex: '2',
    paddingBottom: '5px',
  },
})(TextField);

const ThemedFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-root': {
      color: '#425AE0',
    },
    width: '100%',
    height: '45px',
    color: '#425AE0',
    zIndex: '2',
  },
})(FormControl);

const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#171836',
      },
    },
  },
});

const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: 'black',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
  },
})(MenuItem);

const ThemedSelect = withStyles({
  root: {
    color: '#ffffff',
    height: '-10px',
    '& .MuiInputBase-input': {
      color: '#425AE0',
    },
    '&$disabled': {
      borderColor: '#425AE0',
    },
  },
  disabled: { },
})(Select);

const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '239px',
    marginRight: '10px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '442px',
      flexDirection: 'column',
    },
  },
  icon: {
    color: '#425AE0',
  },
}));

const optionsGender = [
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Feminino', label: 'Feminino' },
  { value: 'Transexual Masculino', label: 'Transexual Masculino' },
  { value: 'Transexual Feminino', label: 'Transexual Feminino' },
  { value: 'N/A', label: 'N/A' },
  { value: 'Outro', label: 'Outro' },
];

const optionsStatus = [
  { value: 'single', label: 'Solteiro' },
  { value: 'married', label: 'Casado' },
  { value: 'widow', label: 'Viúvo' },
  { value: 'separated', label: 'Separado' },
  { value: 'divorced', label: 'Divorciado' },
];

const notAvailableOption = { label: 'Não disponível', value: 'Não disponível' };

const candidateRegisterValidationschema = yup.object().shape({
  name: yup.string().required('Por favor, preencha o nome do talento'),
  email: yup.string().email('Por favor, preencha um e-mail válido'),
  phoneNumber: yup.string(),
  date: yup.date('Insira uma data correta').nullable(),
  gender: yup.string(),
  city: yup.string().nullable(),
  linkedin: yup.string().url('Por favor, digite um link válido').required('Por favor, digite um link válido'),
  github: yup.string().url('Por favor, digite um link válido'),
  status: yup.string().nullable(),
});

const candidateRegisterInitialValue = {
  name: '',
  email: '',
  phoneNumber: '',
  gender: '',
  status: '',
  linkedin: '',
  city: '',
  github: '',
  cvLink: '',
  birthday: '',
};

const ProfilePictureUrlSchema = yup.string().url('Insira uma URL válida');

function CandidateRegister({ talentId }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [profilePic, setProfilePic] = useState();
  const [profileURL, setProfileURL] = useState();
  const [profileUpURL, setProfileUpURL] = useState();

  const [cropperOpen, setCropperOpen] = useState(false);
  const [zoom, setZoom] = useState(1.2);
  const [picEditor, setPicEditor] = useState();
  const [stepPic, setStepPic] = useState();

  const [Cv, setCv] = useState();
  const [upCv, setUpCv] = useState(null);
  // Mecanica de Removed criada porém backend não aceitar CV nulo depois de ter inserido algum
  const [cvRemoved, setCvRemoved] = useState(null);
  const [cityUpdated, setCityUpdated] = useState(false);
  const [pictureUrlField, setPictureUrlField] = useState('');
  const [pictureUrlError, setPictureUrlError] = useState('');
  const [debouncedPictureUrlField] = useDebounce(pictureUrlField, 500);

  const { talents, authType } = useSelector(
    ({ features, entities }) => ({
      talents: entities.talents,
      authType: features.auth.type,
    }),
  );
  const isRequester = authType === 'requester';

  useEffect(() => {
    const openCropper = async () => {
      if (!debouncedPictureUrlField) return;
      try {
        await ProfilePictureUrlSchema.validate(debouncedPictureUrlField);
        setStepPic(debouncedPictureUrlField);
        setCropperOpen(true);
      } catch (err) {
        setPictureUrlError(err.errors);
      }
    };

    openCropper();
  }, [debouncedPictureUrlField]);

  useEffect(() => {
    setProfilePic(null);
    setProfileURL(null);
    setProfileUpURL(null);
    setCv(null);
    setUpCv(null);
  }, [history.location.pathname]);

  useEffect(() => {
    setProfileURL(profileUpURL);
  }, [profileUpURL]);

  useEffect(() => {
    setCityUpdated(!!talentId);
  }, [talentId]);

  const handleSendToVacancy = () => {
    history.push(`/talents/edit/${talentId}/vacancy`);
  };

  const handleAutoComplete = (e, values, formik) => {
    setCityUpdated(false);
    formik.setFieldValue('city', values);
    formik.setFieldError('city', undefined);
  };

  function handleRegisterTalent(values, { setSubmitting, setFieldError }) {
    // const date = new Date(values.date).toLocaleDateString();
    const submit = async () => {
      setSubmitting(true);
      const gender = FormatGender(values.gender);
      const maritalStatus = FormatStatus(values.status);
      const path = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      // let cvLink;
      let city;
      if (values.city) {
        city = values.city.id;
      }
      const data = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        gender,
        birthday: values.birthday,
        cityId: city,
        linkedinLink: values.linkedin,
        githubLink: values.github,
        maritalStatus: maritalStatus || null,
      };

      const isNew = !talentId;

      try {
        if (profilePic) await dispatch(uploadTalentProfilePic(profilePic, path));
        if (Cv) await dispatch(uploadTalentCV(Cv, path));

        let pictureUrl = profileUpURL || '';
        if (profilePic) pictureUrl = await storage.ref(`talent/picture/${path}/profile.jpg`).getDownloadURL();

        let cvUrl = upCv || '';
        if (cvRemoved) {
          cvUrl = '';
        } else if (Cv) {
          cvUrl = await storage.ref(`talent/cv/${path}/curriculum.pdf`).getDownloadURL();
        }

        if (isNew) {
          const response = await dispatch(addNewTalent({ ...data, cvLink: cvUrl, pictureLink: pictureUrl }));
          toast.info('Talento criado com sucesso');
          history.replace(`/talents/edit/${response.data.id}`); // replace com o id do response
          setUpCv(null);
        } else {
          await dispatch(updateTalentData(talentId, { ...data, cvLink: cvUrl, pictureLink: pictureUrl }));
          toast.info('Talento atualizado com sucesso');
          setUpCv(null);
        }
      } catch (err) {
        if (err.data?.displayMessage?.toUpperCase()?.includes('LINKEDIN')) {
          setFieldError('linkedin', err.data.displayMessage);
        } else {
          toast.error(isNew ? 'Erro ao criar talento' : 'Erro ao atualizar talento');
        }
      } finally {
        setSubmitting(false);
      }
    };
    submit();
  }

  function chooseFile(event) {
    setStepPic(URL.createObjectURL(event.target.files[0]));
    setCropperOpen(true);
  }

  const setEditorRef = (editor) => setPicEditor(editor);

  function handleCancel() {
    setCropperOpen(false);
    setPictureUrlField('');
  }

  function handleZoomSlider(event, value) {
    setZoom(value);
  }

  function handleSave() {
    if (picEditor) {
      const canvasScaled = picEditor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      canvasScaled.toBlob((blob) => {
        setProfilePic(blob);
      });
      setCropperOpen(false);
      setProfileURL(croppedImg);
      setPictureUrlField('');
    }
  }

  function downloadCurriculum() {
    if (talentId !== 0 && talents.allIds) {
      const talentUpdate = talents.byId[talentId];
      window.open(talentUpdate.cvLink, '_blank');
    }
  }
  function chooseCV(event) {
    if (upCv !== null) {
      setCv(null);
      setUpCv(null);
      setCvRemoved(true);
    } else {
      setCv(event.target.files[0]);
      setUpCv(event.target.files[0]);
      setCvRemoved(false);
    }
  }

  const fetchOptions = (searchText) => (searchText !== '' ? returnCities({ 'city.name': `%${searchText}%` }) : '');

  return (
    <ComponentTheme>
      {cropperOpen && (
      <div
        className="cropper-wrapper"
        style={{
          position: 'absolute',
          top: '10%',
          left: '31%',
          width: '40%',
          height: '70%',
          background: 'rgba(200,200,200,.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '5',
        }}
      >
        <AvatarEditor
          ref={setEditorRef}
          image={stepPic}
          width={200}
          height={200}
          border={50}
          borderRadius={100}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={zoom}
          rotate={0}
          crossOrigin
          onLoadFailure={() => {
            handleCancel();
            toast.error('Houve um problema ao carregar esta foto.');
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label
            style={{
              fontSize: 12,
              marginRight: 10,
              paddingBottom: 22,
              fontWeight: 600,
            }}
          >
            Zoom
          </label>
          <Slider
            min={1}
            max={10}
            step={0.1}
            value={zoom}
            onChange={handleZoomSlider}
            style={{ width: 200 }}
          />
        </div>
        <div>
          <RaisedButton
            label="CANCEL"
            style={{
              marginRight: '20px',
            }}
            onClick={handleCancel}
          >
            Cancelar
          </RaisedButton>
          <RaisedButton
            label="SAVE"
            onClick={handleSave}
          >
            Salvar
          </RaisedButton>
        </div>
      </div>
      )}
      <FormDiv>
        <Formik
          initialValues={candidateRegisterInitialValue}
          validationSchema={candidateRegisterValidationschema}
          onSubmit={handleRegisterTalent}
          validateOnChange={false}
        >
          {(formik) => {
            if (talentId !== 0 && talents.allIds && talents.byId[talentId]) {
              const talentUpdate = talents.byId[talentId];
              // setProfilePic(talentUpdate.pictureLink);
              if (talentUpdate.pictureLink) setProfileUpURL(talentUpdate.pictureLink);
              if (talentUpdate.cvLink) setUpCv(talentUpdate.cvLink);
              formik.initialValues.name = talentUpdate.name || talentUpdate.shortId;
              formik.initialValues.city = talentUpdate.city;
              formik.initialValues.phoneNumber = isRequester && !talentUpdate.phoneNumber
                ? 'Não disponível' : talentUpdate.phoneNumber;
              formik.initialValues.gender = FormatGender(talentUpdate.gender);
              formik.initialValues.status = isRequester && !talentUpdate.maritalStatus
                ? 'Não disponível' : FormatStatus(talentUpdate.maritalStatus);
              formik.initialValues.birthday = talentUpdate.birthday
                ? moment(talentUpdate.birthday).format('YYYY-MM-DD') : '';
              formik.initialValues.linkedin = isRequester && !talentUpdate.linkedinLink
                ? 'Não disponível' : talentUpdate.linkedinLink;
              formik.initialValues.github = isRequester && !talentUpdate.githubLink
                ? 'Não disponível' : talentUpdate.githubLink;
              formik.initialValues.email = isRequester && !talentUpdate.email
                ? 'Não disponível' : talentUpdate.email;
            } else {
              formik.initialValues.name = '';
              formik.initialValues.city = '';
              formik.initialValues.phoneNumber = '';
              formik.initialValues.gender = '';
              formik.initialValues.status = '';
              formik.initialValues.birthday = '';
              formik.initialValues.linkedin = '';
              formik.initialValues.github = '';
              formik.initialValues.email = '';
            }
            return (
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid className={classes.root} item sm={12} md={6}>
                    <PicDiv>
                      <div style={{ margin: authType === 'requester' ? 'auto' : 'initial' }}>
                        <label htmlFor="file-upload">
                          <ThemedAvatar
                            style={{
                              cursor: 'pointer',
                            }}
                            src={profileURL || Person}
                            size={200}
                          />
                        </label>
                      </div>

                      {!isRequester ? (
                        <div style={{ marginLeft: 32, flex: 1 }}>
                          <div>
                            <label htmlFor="file-upload">
                              <UploadPictureButton>
                                <ImgLabel src={uploadImg} alt="" />
                                <span>Upload</span>
                              </UploadPictureButton>
                            </label>
                            <InputPic
                              id="file-upload"
                              type="file"
                              alt=""
                              accept="image/png, image/jpeg"
                              onChange={(event) => chooseFile(event)}
                            />
                          </div>
                          <ThemedTextField
                            size="small"
                            error={Boolean(pictureUrlError)}
                            helperText={pictureUrlError}
                            id="URL FOTO"
                            data-testid="name-input"
                            label="Cole a URL da imagem"
                            name="URL FOTO"
                            autoComplete="off"
                            variant="outlined"
                            value={pictureUrlField}
                            onChange={(e) => {
                              setPictureUrlField(e.target.value);
                              setPictureUrlError('');
                            }}
                          />
                        </div>
                      ) : null}
                    </PicDiv>
                    <ThemedTextField
                      id="name"
                      data-testid="name-input"
                      style={{ marginTop: '20px' }}
                      label="Nome"
                      name="name"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.name ? formik.errors.name : ''}
                      error={formik.errors.name && Boolean(formik.errors.name)}
                      InputProps={{
                        readOnly: isRequester,
                      }}
                      onChange={(e) => {
                        formik.setFieldValue('name', e.target.value); formik.setFieldError('name', undefined);
                      }}
                      value={formik.values.name}
                    />
                    <TextDiv style={{ paddingTop: '27px' }}>
                      <ThemedTextField
                        id="phoneNumber-text"
                        data-testid="phoneNumber-input"
                        label="Telefone"
                        name="phoneNumber"
                        autoComplete="off"
                        variant="outlined"
                        helperText={
                          formik.errors.phoneNumber
                            ? formik.errors.phoneNumber
                            : ''
                      }
                        error={
                          formik.errors.phoneNumber
                          && Boolean(formik.errors.phoneNumber)
                      }
                        onChange={(e) => {
                          formik.setFieldValue('phoneNumber', e.target.value);
                          formik.setFieldError('phoneNumber', undefined);
                        }}
                        value={formik.values.phoneNumber}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...(isRequester && formik.initialValues.phoneNumber === 'Não disponível'
                            ? {} : { inputComponent: TextFieldMask }),
                          readOnly: isRequester,
                        }}
                      />
                    </TextDiv>
                    <DoubleDiv style={{ marginTop: '40px' }}>
                      <SexDiv>
                        <MuiThemeProvider theme={MuiListThem}>
                          <ThemedFormControl
                            variant="outlined"
                          >
                            <InputLabel id="labelgender">Sexo</InputLabel>
                            <ThemedSelect
                              id="gender"
                              labelId="labelgender"
                              value={formik.values.gender}
                              name="gender"
                              error={
                                formik.errors.gender
                                && Boolean(formik.errors.gender)
                              }
                              onChange={formik.handleChange('gender')}
                              label="Sexo"
                              disabled={isRequester}
                              inputProps={{
                                classes: {
                                  icon: classes.icon,
                                },
                              }}
                            >
                              {optionsGender.map((option) => (
                                <ThemedMenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </ThemedMenuItem>
                              ))}
                            </ThemedSelect>
                            <FormHelperText style={{
                              color: '#f44336',
                              fontSize: '0.75rem',
                              fontFamily: "'Roboto', sans-serif'",
                            }}
                            >
                              {
                    formik.errors.gender
                      ? formik.errors.gender
                      : ''
                  }
                            </FormHelperText>
                          </ThemedFormControl>
                        </MuiThemeProvider>
                      </SexDiv>
                      <DateDiv>
                        <ThemedTextField
                          style={{ width: '220px' }}
                          id="birthday"
                          data-testid="birthday-input"
                          label="Data de nascimento"
                          name="birthday"
                          autoComplete="off"
                          variant="outlined"
                          type="date"
                          helperText={formik.errors.birthday ? formik.errors.birthday : ''}
                          error={formik.errors.birthday && Boolean(formik.errors.birthday)}
                          onChange={(e) => {
                            formik.setFieldValue('birthday', e.target.value);
                            formik.setFieldError('birthday', undefined);
                          }}
                          value={formik.values.birthday}
                          InputProps={{
                            readOnly: isRequester,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </DateDiv>
                    </DoubleDiv>
                    <StatusDiv style={{ marginTop: '39px' }}>
                      <MuiThemeProvider theme={MuiListThem}>
                        <ThemedFormControl
                          variant="outlined"
                        >
                          <InputLabel id="labelstatus">Estado civil</InputLabel>
                          <ThemedSelect
                            id="status"
                            labelId="labelstatus"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange('status')}
                            error={
                              formik.errors.status
                              && Boolean(formik.errors.status)
                            }
                            label="Estado civil"
                            disabled={isRequester}
                            inputProps={{
                              classes: {
                                icon: classes.icon,
                              },
                            }}
                          >
                            {optionsStatus.concat(isRequester && !formik.initialValues.maritalStatus
                              ? [notAvailableOption] : []).map((option) => (
                                <ThemedMenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </ThemedMenuItem>
                            ))}
                          </ThemedSelect>
                        </ThemedFormControl>
                      </MuiThemeProvider>
                    </StatusDiv>
                  </Grid>
                  <Grid className={classes.root} item sm={12} md={6} style={{ alignItems: 'flex-end' }}>
                    <CVdiv style={{ opacity: isRequester && !upCv ? 0 : 1 }}>
                      <UploadLabel>Upload CV</UploadLabel>
                      <InputCV
                        id="cv-upload"
                        type="file"
                        alt=""
                        accept="application/pdf, .doc, .docx"
                        onChange={(event) => chooseCV(event, formik)}
                      />
                      <InputCV
                        id="cv-upload-remove"
                        type="button"
                        alt=""
                        onClick={(event) => chooseCV(event, formik)}
                      />

                      <DivUpload>
                        <DivLabelDownload>
                          {
                            upCv === null
                              ? (
                                <ButtonUpload disabled={formik.isSubmitting} type="button">
                                  <LabelCV htmlFor={isRequester ? '' : 'cv-upload'}>
                                    Upload
                                    <ImageCV src={uploadCV} alt="" />
                                  </LabelCV>
                                </ButtonUpload>
                              )

                              : (
                                <ButtonUpload disabled={formik.isSubmitting} type="button">
                                  <LabelCV htmlFor={isRequester ? '' : 'cv-upload-remove'}>
                                    CV
                                    {' '}
                                    {formik.initialValues.name.substring(0, 8)}
                                    <ImageCV src={removeCV} alt="" />
                                  </LabelCV>
                                </ButtonUpload>
                              )
                          }
                          {(talentId !== 0 && upCv !== null) ? (
                            <DownloadDiv disabled={formik.isSubmitting} type="button">
                              <form method="get" action={upCv}>
                                <DownloadCV
                                  onClick={() => downloadCurriculum(talentId)}
                                  src={downloadCV}
                                  alt=""
                                />
                              </form>
                            </DownloadDiv>
                          ) : ''}
                        </DivLabelDownload>
                      </DivUpload>
                    </CVdiv>
                    <CityDiv>
                      <AsyncAutoComplete
                        fetchOptions={fetchOptions}
                        formik={formik}
                        handleChange={handleAutoComplete}
                        isUpdating={cityUpdated}
                        errorField={formik.errors.city && Boolean(formik.errors.city)}
                        helperText={formik.errors.city ? formik.errors.city : ' '}
                        formikValue={formik.values.city}
                        authType={authType}
                        autoComplete="off"
                      />
                    </CityDiv>
                    <TextDiv style={{ paddingTop: '6px' }}>
                      <ThemedTextField
                        id="email"
                        label="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={(e) => {
                          formik.setFieldValue('email', e.target.value); formik.setFieldError('email', undefined);
                        }}
                        InputProps={{
                          readOnly: isRequester,
                        }}
                        variant="outlined"
                        helperText={formik.errors.email ? formik.errors.email : ''}
                        error={formik.errors.email && Boolean(formik.errors.email)}
                      />
                    </TextDiv>
                    <TextDiv>
                      <ThemedTextField
                        id="linkedin"
                        data-testid="linkedin-input"
                        name="linkedin"
                        label="Link Linkedin"
                        autoComplete="off"
                        variant="outlined"
                        InputProps={{
                          readOnly: isRequester,
                        }}
                        helperText={
                        formik.errors.linkedin ? formik.errors.linkedin : ''
                      }
                        error={
                        formik.errors.linkedin && Boolean(formik.errors.linkedin)
                      }
                        onChange={(e) => {
                          formik.setFieldValue('linkedin', e.target.value); formik.setFieldError('linkedin', undefined);
                        }}
                        value={formik.values.linkedin}
                      />
                    </TextDiv>
                    <TextDiv>
                      <ThemedTextField
                        id="github"
                        data-testid="github-input"
                        name="github"
                        label="Link GitHub"
                        autoComplete="off"
                        variant="outlined"
                        InputProps={{
                          readOnly: isRequester,
                        }}
                        helperText={
                        formik.errors.github ? formik.errors.github : ''
                      }
                        error={
                        formik.errors.github && Boolean(formik.errors.github)
                      }
                        onChange={formik.handleChange}
                        value={formik.values.github}
                      />
                    </TextDiv>
                  </Grid>
                  { isRequester
                    ? (
                      <Grid item xs={12}>
                        <DivButton>
                          <ThemedButton disabled={formik.isSubmitting} onClick={handleSendToVacancy} type="button">
                            Vaga
                          </ThemedButton>
                        </DivButton>

                      </Grid>
                    )
                    : (
                      <Grid item xs={12}>
                        {
                        // FIXME: Botão vaga não tera função nesse story, apenas a lógica de aparecer e não aparecer
                        talentId
                          ? (
                            <DivButton>
                              <ThemedButton disabled={formik.isSubmitting} onClick={handleSendToVacancy} type="button">
                                Vaga
                              </ThemedButton>
                              <ThemedButton disabled={formik.isSubmitting} type="submit">
                                Atualizar
                              </ThemedButton>
                            </DivButton>
                          )
                          : (
                            <DivButton>
                              <ThemedButton disabled={formik.isSubmitting} type="submit">
                                Cadastrar
                              </ThemedButton>
                            </DivButton>
                          )
                      }

                      </Grid>
                    )}
                </Grid>
              </form>
            );
          }}
        </Formik>
      </FormDiv>
    </ComponentTheme>
  );
}

export default CandidateRegister;
