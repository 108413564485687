export const defaultConfig = {
  // withCredentials: true,
  timeout: 60000,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
    // To fix CORS problem ?
    // 'Access-Control-Allow-Origin': '*',
  },
};

export const defaultAuthFirebaseRequestHandler = (config, firebaseAuth) => {
  const isAuthenticated = !!firebaseAuth.currentUser;
  // config.withCredentials = isAuthenticated;
  if (isAuthenticated) {
    firebaseAuth.currentUser.getIdToken(/* forceRefresh true */).then((idToken) => {
      config.headers.Authorization = `Bearer ${idToken}`;
    });
  } else {
    delete config.headers.Authorization;
  }

  return config;
};

export const defaultResponseHandlers = {
  response: (response) => {
    const { status, data } = response;
    return Promise.resolve({ status, data });
  },
  error: (error) => {
    const isNetworkError = !!error.isAxiosError && !error.response;
    return Promise.reject({
      status: !isNetworkError ? error.response.status : null,
      data: !isNetworkError ? error.response.data : null,
      message: error.message,
    });
  },
};

// export const defaultAuthLocalStorageRequestHandler = (config, authLocalStorageItemName) => {
//   const apiAuthToken = localStorage.getItem(authLocalStorageItemName);
//   if (apiAuthToken) {
//     config.headers.Authorization = `Bearer ${apiAuthToken}`;
//     config.withCredentials = true;
//   }

//   return config;
// };

// export const defaultAuthLocalStorageSetter = (axiosInstance, authLocalStorageItemName, token) => {
//   axiosInstance.defaults.withCredentials = !!token;
//   if (!token) {
//     localStorage.removeItem(authLocalStorageItemName);
//     delete axiosInstance.defaults.headers.common.Authorization;
//   } else {
//     localStorage.setItem(authLocalStorageItemName, token);
//     axiosInstance.defaults.headers.common.Authorization = token;
//   }
// };

// export const defaultAuthRefreshLocalStorageSetter = (
//   axiosInstance, refreshAuthLocalStorageItemName, token,
// ) => {
//   axiosInstance.defaults.withCredentials = !!token;
//   if (!token) {
//     localStorage.removeItem(refreshAuthLocalStorageItemName);
//     delete axiosInstance.defaults.headers.common.Authorization;
//   } else {
//     localStorage.setItem(refreshAuthLocalStorageItemName, token);
//     axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
//   }
// };
