import { createSlice } from '@reduxjs/toolkit';
import {
  mergeFav, dropAll, dropId,
} from 'entities/favorites';

import api from 'app/axios';

const initialState = {
  favoriteCreatedId: 0,
  pageRegister: 0,
  pageList: 0,
  totalList: 0,
  pagesAccessed: [],
};
export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    setFavoriteCreatedId: (state, { payload: vacancyCreatedId }) => {
      state.favoriteCreatedId = !vacancyCreatedId ? initialState.favoriteCreatedId : vacancyCreatedId;
    },
    setPageRegister: (state, { payload: page }) => {
      state.pageRegister = !page ? initialState.pageRegister : page;
    },
    setPageList: (state, { payload: page }) => {
      state.pageList = !page ? initialState.pageList : page;
      if (!state.pagesAccessed.includes(page)) {
        state.pagesAccessed.push(page);
      }
    },
    setTotalList: (state, { payload: total }) => {
      state.totalList = !total ? initialState.totalList : total;
    },
    setPagesAccessedEmpty: (state) => {
      state.pagesAccessed = [];
    },
  },
});

const {
  setTotalList, setPageList, setPagesAccessedEmpty,
} = favoriteSlice.actions;

const vacancyEndpoint = '/vacancy';

export const fetchFavoriteListPageBack = (page) => (dispatch) => {
  dispatch(setPageList(page));
};

export const fetchFavorites = (vacancyId) => (dispatch) => api
  .get(`${vacancyEndpoint}/${vacancyId}/favorites`).then(({ data }) => dispatch(mergeFav(data.results)));

export const fetchFirstFavoritesPagination = (vacancyId, page) => (dispatch) => api
  .get(`${vacancyEndpoint}/${vacancyId}/favorites`, { params: { page } }).then(({ data }) => {
    dispatch(dropAll());
    dispatch(setPagesAccessedEmpty());
    dispatch(mergeFav(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(page));
  });

export const fetchFavoritesPagination = (vacancyId, { page = 1, status }) => (dispatch) => api
  .get(`${vacancyEndpoint}/${vacancyId}/favorites`, { params: { page: page - 1, status } }).then(({ data }) => {
    dispatch(dropAll());
    dispatch(setPagesAccessedEmpty());
    dispatch(mergeFav(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(page - 1));
  });

export const resetTalentsFavoriteCompany = () => (dispatch) => {
  dispatch(setPagesAccessedEmpty());
  dispatch(dropAll());
};

export const favoriteTalent = (data) => (dispatch) => api
  .post(`${vacancyEndpoint}/favorite`, data).then(() => dispatch(fetchFavorites(data.vacancyId)));

export const removeFavoriteTalent = (payload) => (dispatch) => api
  .delete(`${vacancyEndpoint}/${payload.vacancyId}/${payload.talentId}/favorite`)
  .then(() => {
    dispatch(dropId(payload.id));
    dispatch(setTotalList(payload.totalList - 1));
  });

export default favoriteSlice.reducer;
