import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--horizontal-padding);
  > div {
      flex: 1;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px var(--horizontal-padding);
`;
