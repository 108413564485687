/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#171836',
    border: '1px solid #425AE0',
    borderRadius: 24,
    boxShadow: theme.shadows[5],
    padding: '24px 48px',
    color: 'white',
    outline: 0,
    textAlign: 'center',
  },
  button: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    letterSpacing: '0px',
    alignSelf: 'center',
    padding: '6px 14px',
    width: '140px',
    maxWidth: '100%',
    textTransform: 'capitalize',
    margin: '0px 12px',
  },
  font: { fontWeight: 'normal' },
}));

/* eslint-enable import/prefer-default-export */
