/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Button,
  MenuItem,
  createMuiTheme,
  MuiThemeProvider,
  Grid,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioGroup from '@material-ui/core/RadioGroup';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import NumberFormatCustom from 'components/TextFieldCurrencyMask';
import CheckboxGroup from 'components/CheckboxGroup';
import MainAreaOfPractice from './TalentTechniqueOccupationAreaPrimary';
import SoftSkillLevelRadio from './SoftSkillLevelRadioButton';
import ModalSendVacancy from './ModalSendVacancy';
import { updateTalentTechInfo } from '..';

const TitleRegister = styled.h2`
  font-size: "26px";
  font-weight: bolder;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const TitleRegisterAccordion = styled.h3`
  font-size: "26px";
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const ButtonBox = styled.div`
  display: flex;
  margin-top: 3%;
  justify-content: center;
`;

const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '197px',
    height: '44px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
  },
})(TextField);

const ThemedAccordion = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0px',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '&$expanded': {
      margin: '0px',
    },
    boxShadow: 'none',
  },
  expanded: {},
})(Accordion);

const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&:hover': {
      backgroundColor: '#425AE0',
    },
  },
})(MenuItem);

const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#171836',
      },
    },
  },
});

const ContainerTextFields = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  button {
    :first-child {
      margin-right: 8px
    }
  }
`;

const ContainerIdiomas = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
`;

const EmptyContainer10 = styled.div`
  width: 10%;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: `${theme.spacing(1)}px 0px`,
    },
  },
  typeChoosed: {
    opacity: 1,
  },
  radioGroupContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

function equalMoreThan(ref, msg) {
  return this.test({
    name: 'equalMoreThan',
    exclusive: false,
    message: msg || 'erro',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value >= this.resolve(ref);
    },
  });
}

yup.addMethod(yup.number, 'equalMoreThan', equalMoreThan);

const talentTechniqueValidationschema = yup.object({
  experience: yup.string().required('Necessário informar a experiência do talento'),
  particularity: yup.string(),
  reasonToChange: yup.string().required('Necessário informar o motivador de mudança do talento'),
  hiringFormats: yup.array().min(1, 'Escolha pelo menos um Modelo de Contratação'),
  currentSalary: yup.number().min(1, 'O Salário deve ser maior que 0')
    .required('Necessário informar salário atual do talento'),
  intendedSalary: yup.number().required('Necessário informar a pretenção salarial do talento'),
  mainAreaOfPractice: yup.string().required('Necessário informar a área principal de atuação'),
  areasOfPractice: yup.array(),
  languages: yup.array(),
  presentation: yup.string(),
  relationshipIntra: yup.string(),
  relationshipInter: yup.string(),
  comunication: yup.string(),
  speedThinking: yup.string(),
  adaptation: yup.string(),
  autonomy: yup.string(),
  organization: yup.string(),
  selfconfidence: yup.string(),
  teamwork: yup.string(),
  hardskills: yup.array(),
  isPCD: yup.number(),
  PCDInfo: yup.string().nullable(),
  softskills: yup.array(),
  crmsTools: yup.array(),
  levels: yup.array().min(1, 'Escolha pelo menos um nível'),
  universityRanks: yup.string(),
  universityLevels: yup.array().min(1, 'Escolha pelo menos um tipo de Formação'),
  languageTypeOne: yup.string(),
  languageLevelOne: yup.string(),
  languageTypeTwo: yup.string(),
  languageLevelTwo: yup.string(),
  languageTypeThree: yup.string(),
  languageLevelThree: yup.string(),
  languageTypeFour: yup.string(),
  languageLevelFour: yup.string(),
});

const talentTechniqueInitialValue = {
  experience: '',
  particularity: '',
  reasonToChange: '',
  hiringFormats: [],
  currentSalary: 0,
  intendedSalary: 0,
  mainAreaOfPractice: '',
  areasOfPractice: [],
  languages: [],
  presentation: '',
  relationshipIntra: '',
  relationshipInter: '',
  comunication: '',
  speedThinking: '',
  adaptation: '',
  autonomy: '',
  organization: '',
  selfconfidence: '',
  isPCD: '0',
  PCDInfo: '',
  teamwork: '',
  hardskills: [],
  softskills: [],
  levels: [],
  universityRanks: [],
  universityLevels: [],
  languageTypeOne: 'nd',
  languageLevelOne: 'nd',
  languageTypeTwo: 'nd',
  languageLevelTwo: 'nd',
  languageTypeThree: 'nd',
  languageLevelThree: 'nd',
  languageTypeFour: 'nd',
  languageLevelFour: 'nd',
};

const yesNoPCD = [
  { id: '1', name: 'Sim' },
  { id: '0', name: 'Não' },
];

const universityRank = [
  { id: 'a', name: 'Rank A' },
  { id: 'b', name: 'Rank B' },
  { id: 'c', name: 'Rank C' },
];

const universityLevel = [
  { id: 'technic', name: 'Técnico' },
  { id: 'undergraduate', name: 'Sem graduação' },
  { id: 'masters', name: 'Mestrado' },
  { id: 'graduate', name: 'Graduação' },
  { id: 'doctorate', name: 'Doutorado' },
  { id: 'incomplete', name: 'Incompleto' },
];

const hiringFormat = [
  { id: 'clt', name: 'CLT' },
  { id: 'freelancer', name: 'Freelancer' },
  { id: 'pj', name: 'PJ' },
  { id: 'other', name: 'Outro' },
];

const talentLevel = [
  { id: 'junior', name: 'Júnior' },
  { id: 'senior', name: 'Sênior' },
  { id: 'coordinator', name: 'Coordenador' },
  { id: 'full', name: 'Pleno' },
  { id: 'director', name: 'Diretor' },
  { id: 'specialist', name: 'Especialista' },
  { id: 'manager', name: 'Gerente' },
];

export default function Talenttechniqueform({ talentId }) {
  const classes = useStyles();
  const history = useHistory();
  const [type, setType] = useState('primary');
  const [primaryColor, setPrimaryColor] = useState('#0e1958');
  const [secondaryColor, setSecondaryColor] = useState('#171836');
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const dispatch = useDispatch();

  const {
    areaOfPractice, softSkill, hardskillByArea, talentRegister, authType,
  } = useSelector(
    ({ entities, features }) => ({
      areaOfPractice: entities.areaofpractice,
      softSkill: entities.softskill,
      hardskillByArea: entities.hardskillAreas,
      talentRegister: entities.talents,
      authType: features.auth.type,
    }),
  );

  const handleTypeChange = (typeChoosed) => {
    if (typeChoosed === 'primary') {
      setPrimaryColor('#0e1958');
      setSecondaryColor('#171836');
    } else {
      setPrimaryColor('#171836');
      setSecondaryColor('#0e1958');
    }
    setType(typeChoosed);
  };

  const handleNewTalentTechInfo = (values, { setFieldError, setSubmitting }) => {
    const postObject = JSON.parse(JSON.stringify(values));
    postObject.isPCD = values.isPCD === '1';
    postObject.languages = [];
    if (
      values.languageTypeOne !== 'nd'
      && values.languageTypeOne !== ''
      && values.languageLevelOne !== 'nd'
      && values.languageLevelOne !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeOne,
        level: values.languageLevelOne,
      });
    }
    if (
      values.languageTypeTwo !== 'nd'
      && values.languageTypeTwo !== ''
      && values.languageLevelTwo !== 'nd'
      && values.languageLevelTwo !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeTwo,
        level: values.languageLevelTwo,
      });
    }
    if (
      values.languageTypeThree !== 'nd'
      && values.languageTypeThree !== ''
      && values.languageLevelThree !== 'nd'
      && values.languageLevelThree !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeThree,
        level: values.languageLevelThree,
      });
    }
    if (
      values.languageTypeFour !== 'nd'
      && values.languageTypeFour !== ''
      && values.languageLevelFour !== 'nd'
      && values.languageLevelFour !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeFour,
        level: values.languageLevelFour,
      });
    }

    delete postObject.languageTypeOne;
    delete postObject.languageLevelOne;
    delete postObject.languageTypeTwo;
    delete postObject.languageLevelTwo;
    delete postObject.languageTypeThree;
    delete postObject.languageLevelThree;
    delete postObject.languageTypeFour;
    delete postObject.languageLevelFour;
    delete postObject.presentation;
    delete postObject.relationshipIntra;
    delete postObject.relationshipInter;
    delete postObject.comunication;
    delete postObject.speedThinking;
    delete postObject.teamwork;
    delete postObject.selfconfidence;
    delete postObject.adaptation;
    delete postObject.autonomy;
    delete postObject.organization;
    // Convert to number
    postObject.softskills = values.softskills.map(Number);
    postObject.hardskills = values.hardskills.map(Number);
    dispatch(updateTalentTechInfo(talentId, postObject))
      .then(() => {
        toast.info('Informações técnicas salvas com sucesso');
        setShowModalConfirmation(true);
        setSubmitting(false);
      }).catch((rejection) => {
        const { data } = rejection;
        if (data.data) {
          setFieldError(data.data, data.message);
        }
        setSubmitting(false);
        toast.error('Erro ao atualizar informações técnicas');
      });
  };

  const getInitialValues = () => {
    const talent = talentRegister?.byId?.[talentId];
    if (!talent) return talentTechniqueInitialValue;

    return {
      experience: talent.experience,
      particularity: talent.particularity,
      reasonToChange: talent.reasonToChange,
      intendedSalary: talent.intendedSalary,
      currentSalary: talent.currentSalary,
      mainAreaOfPractice: talent.mainAreaOfPractice
        ? talent.mainAreaOfPractice.id.toString() : '',
      isPCD: talent.isPCD.toString(),
      PCDInfo: talent.PCDInfo,
      areasOfPractice: talent.areasOfPractice.map((item) => item.id),
      hardskills: talent.hardskills.map((item) => item.id),
      softskills: talent.softskills.map((item) => String(item.id)),
      levels: talent.levels.map((item) => item.level.toString()),
      universityRanks: talent.universityRanks.map((item) => item.rank.toString()),
      universityLevels: talent.universityLevels.map((item) => item.level.toString()),
      hiringFormats: talent.hiringFormats.map((item) => item.format.toString()),
      languageLevelOne: talent.languages[0]?.level || '',
      languageTypeOne: talent.languages[0]?.language || '',
      languageLevelTwo: talent.languages[1]?.level || '',
      languageTypeTwo: talent.languages[1]?.language || '',
      languageLevelThree: talent.languages[2]?.level || '',
      languageTypeThree: talent.languages[2]?.language || '',
      languageLevelFour: talent.languages[3]?.level || '',
      languageTypeFour: talent.languages[3]?.language || '',
    };
  };

  function confirmSendVacancy() {
    history.push(`/talents/edit/${talentId}/vacancy`);
  }

  return (
    <div style={{ marginTop: '2%' }}>
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={talentTechniqueValidationschema}
        validateOnChange={false}
        onSubmit={handleNewTalentTechInfo}
      >
        {(formik) => {
          function handleCheckboxChange(name, selected) {
            const oldValue = formik.values[name];
            const newValue = oldValue.includes(selected)
              ? oldValue.filter((item) => item !== selected)
              : [...oldValue, selected];
            formik.setFieldValue(name, newValue);
          }
          return (
            <form onSubmit={formik.handleSubmit}>
              <Container className={classes.root}>
                <ThemedTextField
                  id="experience-text"
                  data-testid="experience-input"
                  multiline
                  fullWidth
                  label="Experiência"
                  name="experience"
                  autoComplete="off"
                  variant="outlined"
                  rows={7}
                  InputProps={{
                    readOnly: authType === 'requester',
                  }}
                  helperText={
                    formik.errors.experience ? formik.errors.experience : ''
                  }
                  error={
                    formik.errors.experience && Boolean(formik.errors.experience)
                  }
                  onBlur={(e) => formik.setFieldValue('experience', e.currentTarget.value)}
                  defaultValue={formik.values.experience}
                />
                {' '}
                <ThemedTextField
                  id="particularity-text"
                  data-testid="particularity-input"
                  multiline
                  fullWidth
                  label="Diferencial"
                  name="particularity"
                  autoComplete="off"
                  variant="outlined"
                  rows={7}
                  InputProps={{
                    readOnly: authType === 'requester',
                  }}
                  helperText={
                    formik.errors.particularity ? formik.errors.particularity : ''
                  }
                  error={
                    formik.errors.particularity
                    && Boolean(formik.errors.particularity)
                  }
                  // onChange={formik.handleChange}
                  onBlur={(e) => formik.setFieldValue('particularity', e.currentTarget.value)}
                  defaultValue={formik.values.particularity}
                />
                {' '}
                <ThemedTextField
                  id="reasonToChange-text"
                  data-testid="reasonToChange-input"
                  multiline
                  fullWidth
                  label="Motivador da mudança"
                  name="reasonToChange"
                  autoComplete="off"
                  variant="outlined"
                  rows={7}
                  InputProps={{
                    readOnly: authType === 'requester',
                  }}
                  helperText={
                    formik.errors.reasonToChange
                      ? formik.errors.reasonToChange
                      : ''
                  }
                  error={
                    formik.errors.reasonToChange
                    && Boolean(formik.errors.reasonToChange)
                  }
                  onBlur={(e) => formik.setFieldValue('reasonToChange', e.currentTarget.value)}
                  defaultValue={formik.values.reasonToChange}
                />
                {' '}
                <ContainerTextFields>
                  <ThemedTextField
                    id="currentSalary-text"
                    data-testid="currentSalary-input"
                    label="Salário atual"
                    name="currentSalary"
                    style={{ width: '40%' }}
                    autoComplete="off"
                    variant="outlined"
                    helperText={
                      formik.errors.currentSalary
                        ? formik.errors.currentSalary
                        : ''
                    }
                    InputProps={{
                      readOnly: authType === 'requester',
                      inputComponent: NumberFormatCustom,
                    }}
                    error={
                      formik.errors.currentSalary
                      && Boolean(formik.errors.currentSalary)
                    }
                    onBlur={(e) => formik.setFieldValue('currentSalary',
                      Number(e.currentTarget.value.replace('R$ ', '').replace('.', '').replace(',', '.')))}
                    defaultValue={formik.values.currentSalary}
                  />
                  <ThemedTextField
                    id="intendedSalary-text"
                    data-testid="intendedSalary-input"
                    label="Pretensão salarial"
                    name="intendedSalary"
                    style={{ width: '40%' }}
                    autoComplete="off"
                    variant="outlined"
                    helperText={
                      formik.errors.intendedSalary
                        ? formik.errors.intendedSalary
                        : ''
                    }
                    InputProps={{
                      readOnly: authType === 'requester',
                      inputComponent: NumberFormatCustom,
                    }}
                    error={
                      formik.errors.intendedSalary
                      && Boolean(formik.errors.intendedSalary)
                    }
                    onBlur={(e) => formik.setFieldValue(
                      'intendedSalary',
                      Number(e.currentTarget.value.replace('R$ ', '').replace('.', '').replace(',', '.')),
                    )}
                    defaultValue={formik.values.intendedSalary}
                  />
                </ContainerTextFields>
                <ContainerTextFields>
                  <TitleRegister>Área de Atuação</TitleRegister>
                  <ContainerButtons>
                    <ThemedButton
                      style={{ backgroundColor: primaryColor }}
                      onClick={() => handleTypeChange('primary')}
                    >
                      Principal
                    </ThemedButton>
                    <ThemedButton
                      style={{ backgroundColor: secondaryColor }}
                      onClick={() => handleTypeChange('secondary')}
                    >
                      Secundária
                    </ThemedButton>
                  </ContainerButtons>
                </ContainerTextFields>
                {formik.errors.mainAreaOfPractice && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.mainAreaOfPractice}
                </div>
                )}
                <ThemedAccordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <TitleRegisterAccordion>Tecnologia</TitleRegisterAccordion>
                  </AccordionSummary>
                  <AccordionDetails>
                    {type === 'primary' ? (
                      <RadioGroup
                        classes={{
                          root: classes.radioGroupContainer,
                        }}
                        aria-label="mainAreaOfPractice"
                        name="mainAreaOfPractice"
                        error={
                          formik.errors.mainAreaOfPractice
                          && Boolean(formik.errors.mainAreaOfPractice)
                        }
                        onChange={formik.handleChange('mainAreaOfPractice')}
                        value={formik.values.mainAreaOfPractice}
                      >
                        {areaOfPractice.byId[1] ? (
                          areaOfPractice.byId[1].areasOfPractice.map((item) => (
                            <MainAreaOfPractice disabled={authType === 'requester'} key={item.id} areas={item} />
                          ))
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                    ) : (
                      <CheckboxGroup
                        disabled={authType === 'requester'}
                        data={areaOfPractice.byId[1]?.areasOfPractice}
                        value={formik.values.areasOfPractice}
                        onChange={(event) => handleCheckboxChange('areasOfPractice', Number(event.target.value))}
                      />
                    )}
                  </AccordionDetails>
                </ThemedAccordion>
                <ThemedAccordion>
                  <AccordionSummary
                    expandIcon={(
                      <ExpandMoreIcon
                        style={{ color: 'white' }}
                      />
                    )}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <TitleRegisterAccordion>
                      Produto Digital
                    </TitleRegisterAccordion>
                  </AccordionSummary>
                  <AccordionDetails>
                    {type === 'primary' ? (
                      <RadioGroup
                        classes={{
                          root: classes.radioGroupContainer,
                        }}
                        aria-label="mainAreaOfPractice"
                        name="mainAreaOfPractice"
                        error={
                          formik.errors.mainAreaOfPractice
                          && Boolean(formik.errors.mainAreaOfPractice)
                        }
                        onChange={formik.handleChange}
                        value={formik.values.mainAreaOfPractice}
                      >
                        {areaOfPractice.byId[2] ? (
                          areaOfPractice.byId[2].areasOfPractice.map((item) => (
                            <MainAreaOfPractice disabled={authType === 'requester'} key={item.id} areas={item} />
                          ))
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                    ) : (
                      <CheckboxGroup
                        disabled={authType === 'requester'}
                        data={areaOfPractice.byId[2]?.areasOfPractice}
                        value={formik.values.areasOfPractice}
                        onChange={(event) => handleCheckboxChange('areasOfPractice', Number(event.target.value))}
                      />
                    )}
                  </AccordionDetails>
                </ThemedAccordion>
                <ThemedAccordion>
                  <AccordionSummary
                    expandIcon={(
                      <ExpandMoreIcon
                        style={{ color: 'white' }}
                      />
                    )}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <TitleRegisterAccordion>Negócios</TitleRegisterAccordion>
                  </AccordionSummary>
                  <AccordionDetails>
                    {type === 'primary' ? (
                      <RadioGroup
                        classes={{
                          root: classes.radioGroupContainer,
                        }}
                        aria-label="mainAreaOfPractice"
                        name="mainAreaOfPractice"
                        error={
                          formik.errors.mainAreaOfPractice
                          && Boolean(formik.errors.mainAreaOfPractice)
                        }
                        onChange={formik.handleChange}
                        value={formik.values.mainAreaOfPractice}
                      >
                        {areaOfPractice.byId[3] ? (
                          areaOfPractice.byId[3].areasOfPractice.map((item) => (
                            <MainAreaOfPractice disabled={authType === 'requester'} key={item.id} areas={item} />
                          ))
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                    ) : (
                      <CheckboxGroup
                        disabled={authType === 'requester'}
                        data={areaOfPractice.byId[3]?.areasOfPractice}
                        value={formik.values.areasOfPractice}
                        onChange={(event) => handleCheckboxChange('areasOfPractice', Number(event.target.value))}
                      />
                    )}
                  </AccordionDetails>
                </ThemedAccordion>
                <ContainerTextFields>
                  <TitleRegister>Soft Skill</TitleRegister>
                </ContainerTextFields>
                <Grid container>
                  {
                    softSkill.allIds ? softSkill.allIds.map((skill) => {
                      const softskillItem = softSkill.byId[skill];
                      return (
                        <Grid key={skill} item xs={4}>
                          <ThemedAccordion>
                            <AccordionSummary
                              expandIcon={(
                                <ExpandMoreIcon
                                  style={{ color: 'white', alignItems: 'right' }}
                                />
                        )}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <TitleRegisterAccordion>
                                {softskillItem.name}
                              </TitleRegisterAccordion>
                            </AccordionSummary>
                            <AccordionDetails>
                              <RadioGroup
                                aria-label="softskill"
                                name={softskillItem.name}
                                error={formik.errors.softskills && Boolean(formik.errors.softskills)}
                                value={formik.values.softskills
                                  .find((option) => softskillItem.softskillLevel
                                    .some((level) => String(level.id) === option)) || null}
                                onChange={(e) => {
                                  const levelItems = softskillItem.softskillLevel.map((item) => String(item.id));

                                  const newSelection = formik.values.softskills
                                    .filter((idSelected) => !levelItems.includes(idSelected));
                                  formik.setFieldValue('softskills', [...newSelection, e.target.value]);
                                }}
                                // refactor 27/10
                                // onChange={(e) => {
                                //   softskillItem.softskillLevel.map((item) => {
                                //     if (
                                //       formik.values.softskills.includes(
                                //         item.id.toString(),
                                //       )
                                //     ) {
                                //       const idx = formik.values.softskills.indexOf(
                                //         item.id.toString(),
                                //       );
                                //       formik.values.softskills.splice(idx, 1);
                                //     }
                                //     return true;
                                //   });
                                //   formik.values.softskills.push(e.currentTarget.value);
                                //   setChange(changes !== true);
                                // }}
                                // value={formik.values.softskills.length > 0
                                //   ? (softskillItem.softskillLevel.filter(
                                //     (element) => formik.values.softskills.includes(element.id.toString()),
                                //   )[0]
                                //     ? softskillItem.softskillLevel
                                //       .filter(
                                //         (element) => formik.values.softskills.includes(element.id.toString()),
                                //       )[0].id.toString()
                                //     : formik.values.softskills[0])
                                //   : formik.values.softskills[0]}
                              >
                                {softskillItem ? (
                                  softskillItem.softskillLevel.map((item) => (
                                    <SoftSkillLevelRadio
                                      disabled={authType === 'requester'}
                                      key={item.id}
                                      areas={item}
                                    />
                                  ))
                                ) : (
                                  <></>
                                )}
                              </RadioGroup>
                            </AccordionDetails>
                          </ThemedAccordion>
                        </Grid>
                      );
                    }) : <></>
                  }
                </Grid>
                <ContainerTextFields>
                  <TitleRegister>Hard Skill</TitleRegister>
                </ContainerTextFields>
                {formik.errors.hardskills && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.hardskills}
                </div>
                )}
                {
                  hardskillByArea.allIds ? hardskillByArea.allIds.map((skill) => {
                    const hardSkillItem = hardskillByArea.byId[skill];
                    return (
                      <ContainerTextFields key={skill}>
                        <ThemedAccordion>
                          <AccordionSummary
                            expandIcon={(
                              <ExpandMoreIcon
                                style={{ color: 'white' }}
                              />
                      )}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <TitleRegisterAccordion>
                              {hardSkillItem.name}
                            </TitleRegisterAccordion>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxGroup
                              disabled={authType === 'requester'}
                              data={hardSkillItem?.hardskills || []}
                              value={formik.values.hardskills}
                              onChange={(event) => handleCheckboxChange('hardskills', Number(event.target.value))}
                            />
                          </AccordionDetails>
                        </ThemedAccordion>
                      </ContainerTextFields>
                    );
                  })
                    : <></>
                }
                <ContainerTextFields>
                  <TitleRegister>Nível</TitleRegister>
                </ContainerTextFields>
                {formik.errors.levels && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.levels}
                </div>
                )}
                <ContainerTextFields>
                  <Grid container>
                    <CheckboxGroup
                      disabled={authType === 'requester'}
                      data={talentLevel}
                      value={formik.values.levels}
                      onChange={(event) => handleCheckboxChange('levels', event.target.value)}
                    />
                  </Grid>
                </ContainerTextFields>
                <ContainerTextFields>
                  <TitleRegister>Modelo de contratação</TitleRegister>
                </ContainerTextFields>
                {formik.errors.hiringFormats && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.hiringFormats}
                </div>
                )}
                <ContainerTextFields>
                  <Grid container>
                    <CheckboxGroup
                      disabled={authType === 'requester'}
                      data={hiringFormat}
                      value={formik.values.hiringFormats}
                      onChange={(event) => handleCheckboxChange('hiringFormats', event.target.value)}
                    />
                  </Grid>
                </ContainerTextFields>
                <ContainerTextFields>
                  <TitleRegister>Formação</TitleRegister>
                </ContainerTextFields>
                {formik.errors.universityLevels && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.universityLevels}
                </div>
                )}
                <ContainerTextFields>
                  <Grid container>
                    <CheckboxGroup
                      disabled={authType === 'requester'}
                      data={universityLevel}
                      value={formik.values.universityLevels}
                      onChange={(event) => handleCheckboxChange('universityLevels', event.target.value)}
                    />
                  </Grid>
                </ContainerTextFields>
                <ContainerTextFields>
                  <TitleRegister>Universidade</TitleRegister>
                </ContainerTextFields>
                <ContainerTextFields>
                  <Grid container>
                    <CheckboxGroup
                      disabled={authType === 'requester'}
                      data={universityRank}
                      value={formik.values.universityRanks}
                      onChange={(event) => handleCheckboxChange('universityRanks', event.target.value)}
                    />
                  </Grid>
                </ContainerTextFields>
                <ContainerTextFields>
                  <TitleRegister>Idiomas</TitleRegister>
                </ContainerTextFields>
                <ContainerTextFields>
                  <ContainerIdiomas>
                    <MuiThemeProvider theme={MuiListThem}>
                      <ThemedTextField
                        id="languageTypeOne"
                        data-testid="languageTypeOne"
                        label="Idioma"
                        name="languageTypeOne"
                        style={{ width: '45%', marginBottom: '18px' }}
                        autoComplete="off"
                        variant="outlined"
                        onChange={formik.handleChange('languageTypeOne')}
                        value={formik.values.languageTypeOne}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="português">
                          Português
                        </ThemedMenuItem>
                        <ThemedMenuItem value="inglês">Inglês</ThemedMenuItem>
                        <ThemedMenuItem value="espanhol">Espanhol</ThemedMenuItem>
                        <ThemedMenuItem value="francês">Francês</ThemedMenuItem>
                      </ThemedTextField>
                      <ThemedTextField
                        id="languageLevelOne"
                        data-testid="languageLevelOne"
                        label="Nível"
                        name="languageLevelOne"
                        style={{ width: '45%', marginBottom: '18px' }}
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.languageLevelOne}
                        onChange={formik.handleChange('languageLevelOne')}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="fluente">Fluente</ThemedMenuItem>
                        <ThemedMenuItem value="avançado">Avançado</ThemedMenuItem>
                        <ThemedMenuItem value="intermediário">
                          Intermediário
                        </ThemedMenuItem>
                        <ThemedMenuItem value="iniciante">
                          Iniciante
                        </ThemedMenuItem>
                      </ThemedTextField>
                    </MuiThemeProvider>
                  </ContainerIdiomas>
                  <EmptyContainer10 />
                  <ContainerIdiomas>
                    <MuiThemeProvider theme={MuiListThem}>
                      <ThemedTextField
                        id="languageTypeTwo"
                        data-testid="languageTypeTwo"
                        label="Idioma"
                        name="languageTypeTwo"
                        style={{ width: '45%', marginBottom: '18px' }}
                        autoComplete="off"
                        variant="outlined"
                        onChange={formik.handleChange('languageTypeTwo')}
                        value={formik.values.languageTypeTwo}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="português">
                          Português
                        </ThemedMenuItem>
                        <ThemedMenuItem value="inglês">Inglês</ThemedMenuItem>
                        <ThemedMenuItem value="espanhol">Espanhol</ThemedMenuItem>
                        <ThemedMenuItem value="francês">Francês</ThemedMenuItem>
                      </ThemedTextField>
                      <ThemedTextField
                        id="languageLevelTwo"
                        data-testid="languageLevelTwo"
                        label="Nível"
                        name="languageLevelTwo"
                        style={{ width: '45%', marginBottom: '18px' }}
                        autoComplete="off"
                        variant="outlined"
                        onChange={formik.handleChange('languageLevelTwo')}
                        value={formik.values.languageLevelTwo}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="fluente">Fluente</ThemedMenuItem>
                        <ThemedMenuItem value="avançado">Avançado</ThemedMenuItem>
                        <ThemedMenuItem value="intermediário">
                          Intermediário
                        </ThemedMenuItem>
                        <ThemedMenuItem value="iniciante">
                          Iniciante
                        </ThemedMenuItem>
                      </ThemedTextField>
                    </MuiThemeProvider>
                  </ContainerIdiomas>
                </ContainerTextFields>
                <ContainerTextFields>
                  <ContainerIdiomas>
                    <MuiThemeProvider theme={MuiListThem}>
                      <ThemedTextField
                        id="languageTypeThree"
                        data-testid="languageTypeThree"
                        label="Idioma"
                        name="languageTypeThree"
                        style={{ width: '45%' }}
                        autoComplete="off"
                        variant="outlined"
                        onChange={formik.handleChange('languageTypeThree')}
                        value={formik.values.languageTypeThree}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="português">
                          Português
                        </ThemedMenuItem>
                        <ThemedMenuItem value="inglês">Inglês</ThemedMenuItem>
                        <ThemedMenuItem value="espanhol">Espanhol</ThemedMenuItem>
                        <ThemedMenuItem value="francês">Francês</ThemedMenuItem>
                      </ThemedTextField>
                      <ThemedTextField
                        id="languageLevelThree"
                        data-testid="languageLevelThree"
                        label="Nível"
                        name="languageLevelThree"
                        style={{ width: '45%' }}
                        autoComplete="off"
                        variant="outlined"
                        onChange={formik.handleChange('languageLevelThree')}
                        value={formik.values.languageLevelThree}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="fluente">Fluente</ThemedMenuItem>
                        <ThemedMenuItem value="avançado">Avançado</ThemedMenuItem>
                        <ThemedMenuItem value="intermediário">
                          Intermediário
                        </ThemedMenuItem>
                        <ThemedMenuItem value="iniciante">
                          Iniciante
                        </ThemedMenuItem>
                      </ThemedTextField>
                    </MuiThemeProvider>
                  </ContainerIdiomas>
                  <EmptyContainer10 />
                  <ContainerIdiomas>
                    <MuiThemeProvider theme={MuiListThem}>
                      <ThemedTextField
                        id="languageTypeFour"
                        data-testid="languageTypeFour"
                        label="Idioma"
                        name="languageTypeFour"
                        style={{ width: '45%' }}
                        autoComplete="off"
                        variant="outlined"
                        onChange={formik.handleChange('languageTypeFour')}
                        value={formik.values.languageTypeFour}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="português">
                          Português
                        </ThemedMenuItem>
                        <ThemedMenuItem value="inglês">Inglês</ThemedMenuItem>
                        <ThemedMenuItem value="espanhol">Espanhol</ThemedMenuItem>
                        <ThemedMenuItem value="francês">Francês</ThemedMenuItem>
                      </ThemedTextField>
                      <ThemedTextField
                        id="languageLevelFour"
                        data-testid="languageLevelFour"
                        label="Nível"
                        name="languageLevelFour"
                        style={{ width: '45%' }}
                        autoComplete="off"
                        variant="outlined"
                        onChange={formik.handleChange('languageLevelFour')}
                        value={formik.values.languageLevelFour}
                        InputProps={{
                          readOnly: authType === 'requester',
                        }}
                        select
                      >
                        <ThemedMenuItem value="nd">Não definido</ThemedMenuItem>
                        <ThemedMenuItem value="fluente">Fluente</ThemedMenuItem>
                        <ThemedMenuItem value="avançado">Avançado</ThemedMenuItem>
                        <ThemedMenuItem value="intermediário">
                          Intermediário
                        </ThemedMenuItem>
                        <ThemedMenuItem value="iniciante">
                          Iniciante
                        </ThemedMenuItem>
                      </ThemedTextField>
                    </MuiThemeProvider>
                  </ContainerIdiomas>
                </ContainerTextFields>
                <ContainerTextFields>
                  <TitleRegister>Talento PCD?</TitleRegister>
                </ContainerTextFields>
                <ContainerTextFields>
                  <RadioGroup
                    aria-label="pcd"
                    name="isPCD"
                    error={
                          formik.errors.isPCD
                          && Boolean(formik.errors.isPCD)
                        }
                    onChange={formik.handleChange('isPCD')}
                    value={formik.values.isPCD}
                  >
                    {
                      yesNoPCD.map((item) => (
                        <MainAreaOfPractice disabled={authType === 'requester'} key={item.id} areas={item} />
                      ))
                    }
                  </RadioGroup>
                  <ThemedTextField
                    id="pcdInformation-text"
                    data-testid="pcdInformation-input"
                    label="Mais informações PCD"
                    name="PCDInfo"
                    style={{ width: '462px' }}
                    autoComplete="off"
                    multiline
                    rows={3}
                    variant="outlined"
                    InputProps={{
                      readOnly: authType === 'requester',
                    }}
                    helperText={
                      formik.errors.PCDInfo
                        ? formik.errors.PCDInfo
                        : ''
                    }
                    error={
                      formik.errors.PCDInfo
                      && Boolean(formik.errors.PCDInfo)
                    }
                    onBlur={(e) => formik.setFieldValue('PCDInfo', e.currentTarget.value)}
                    defaultValue={formik.values.PCDInfo}
                  />
                </ContainerTextFields>
                <Grid style={{ marginTop: '70px' }} item xs={12}>

                  {authType !== 'requester'
                    ? (
                      <ButtonBox>
                        <ThemedButton disabled={formik.isSubmitting} type="submit">Atualizar</ThemedButton>
                      </ButtonBox>
                    )
                    : <></>}
                </Grid>
              </Container>
            </form>
          );
        }}
      </Formik>
      <ModalSendVacancy
        visible={showModalConfirmation}
        onClose={() => setShowModalConfirmation(false)}
        onConfirm={() => confirmSendVacancy()}
      />
    </div>
  );
}
