import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormHelperText } from '@material-ui/core';

import { resetPassword, verifyResetCode } from 'features/auth';
import { qsStringToObject } from 'utils/queryString';
import logo from 'assets/Jobler.svg';
import fundo from 'assets/J.svg';

import {
  Background,
  DivForm,
  LabelLogin,
  TitleLogin,
  DivLogin,
  PasswordTextField,
  DivButton,
  ButtonLogin,
  DivLabel,
  LogoImage,
  SubtitleLogin,
  PrivacyPolicyContainer,
  PrivacyPolicyForm,
  ThemedCheckBox,
} from './styles';

const BgImage = styled.div`
    background: url(${fundo});
    height: 50%;
    width: 100%;
    position: fixed;
    background-size: cover;

`;

const forgotValidationschema = yup.object({
  password: yup.string().required('A senha precisa ter 6 ou mais digitos')
    .min(6, 'A senha precisa ter 6 ou mais digitos'),
  agreedPrivacyPolicy: yup.boolean().equals([true], 'É necessário aceitar os termos de uso e política de privacidade'),
});

const forgotInitialValue = {
  password: '',
  agreedPrivacyPolicy: false,
};

function ResetPassword() {
  const history = useHistory();
  const user = useSelector((state) => state.features.auth.user);

  useEffect(() => {
    const { oobCode } = qsStringToObject(history.location.search);
    if (!oobCode || Boolean(user)) {
      history.replace('/');
      return;
    }
    verifyResetCode(oobCode)
      .catch(() => {
        toast.error('Sua solicitação para redefinir a senha expirou ou o link já foi usado');
        history.replace('/');
      });
    // eslint-disable-next-line
  }, [history.location.search]);

  function onSubmit(values, { setSubmitting }) {
    const { oobCode } = qsStringToObject(history.location.search);

    setSubmitting(true);
    resetPassword(oobCode, values.password)
      .then(() => {
        history.replace('/');
        toast.info('Senha redefinida com sucesso.');
      })
      .catch(() => toast.error('Erro ao redefinir senha.'))
      .finally(() => setSubmitting(false));
  }

  return (
    <Background>
      <BgImage />
      <DivForm>
        <Formik
          validationSchema={forgotValidationschema}
          onSubmit={onSubmit}
          initialValues={forgotInitialValue}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <LabelLogin>
                <TitleLogin>Redefinição de senha</TitleLogin>
                <SubtitleLogin>Insira a senha abaixo</SubtitleLogin>
              </LabelLogin>
              <DivLogin>
                <PasswordTextField
                  id="password-text"
                  value={formik.values.password}
                  label="Senha"
                  name="password"
                  onChange={formik.handleChange}
                  type="password"
                  variant="outlined"
                  helperText={formik.errors.password ? formik.errors.password : ''}
                  error={formik.errors.password && Boolean(formik.errors.password)}
                />
              </DivLogin>
              <PrivacyPolicyContainer>
                <PrivacyPolicyForm>
                  <ThemedCheckBox
                    name="agreedPrivacyPolicy"
                    checked={formik.values.agreedPrivacyPolicy}
                    onChange={formik.handleChange}
                  />
                  <span>
                    Concordo com os&nbsp;
                    <a href={process.env.REACT_APP_TERMS_OF_USE_URL} target="_blank" rel="noreferrer">
                      termos de uso
                    </a>
                    &nbsp;e&nbsp;
                    <a href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                      política de privacidade
                    </a>
                  </span>
                </PrivacyPolicyForm>
                {formik.errors.agreedPrivacyPolicy && (
                  <FormHelperText error>{formik.errors.agreedPrivacyPolicy}</FormHelperText>
                )}
              </PrivacyPolicyContainer>
              <DivButton>
                <ButtonLogin type="submit" disabled={formik.isSubmitting}>Confirmar</ButtonLogin>
              </DivButton>
            </form>
          )}
        </Formik>
      </DivForm>
      <DivLabel>
        <LogoImage src={logo} alt="Jobler" />
      </DivLabel>
    </Background>
  );
}

export default ResetPassword;
