import styled from 'styled-components';
import {
  Button, createMuiTheme, FormControl, makeStyles, MenuItem, Select, withStyles,
} from '@material-ui/core';

export const Container = styled.div`
  padding: 0 var(--horizontal-padding);
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContainerHeader = styled.div`
  display: inline-flex;
  width: 100%;
  color: #ffffff;
`;

export const SelectDiv = styled.div`
  margin: 20px 0;
`;

export const DivButton = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
`;

export const ListCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const DivNull = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #9F9F9F;
  font-size: '26px';
  font-family: 'Roboto', sans-serif;
`;
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    direction: 'column',
  },
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(20),
      float: 'right',
      color: 'white',
    },
  },
  icon: {
    color: '#425AE0',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '240px',
    maxWidth: '100%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export const ThemedFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '20%',
  },
})(FormControl);

export const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#171836',
      },
    },
  },
});

export const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: 'black',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
  },
})(MenuItem);

export const ThemedSelect = withStyles({
  root: {
    color: '#ffffff',
  },
})(Select);
