function FormatUniversityLevel(props) {
  let universityLevel = props;

  switch (universityLevel) {
    case 'Técnico':
      universityLevel = 'technic';
      break;
    case 'Pós graduação':
      universityLevel = 'undergraduate';
      break;
    case 'Mestrado':
      universityLevel = 'masters';
      break;
    case 'Graduação':
      universityLevel = 'graduate';
      break;
    case 'Doutorado':
      universityLevel = 'doctorate';
      break;
    case 'Incompleto':
      universityLevel = 'incomplete';
      break;
    case 'technic':
      universityLevel = 'Técnico';
      break;
    case 'undergraduate':
      universityLevel = 'Pós graduação';
      break;
    case 'masters':
      universityLevel = 'Mestrado';
      break;
    case 'graduate':
      universityLevel = 'Graduação';
      break;
    case 'doctorate':
      universityLevel = 'Doutorado';
      break;
    case 'incomplete':
      universityLevel = 'Incompleto';
      break;
    default:
      break;
  }
  return universityLevel;
}

export default FormatUniversityLevel;
