import React from 'react';
import NumberFormat from 'react-number-format';

function NumericInput({ onChange, name, ...rest }) {
  return (
    <NumberFormat
      onValueChange={(values) => onChange(values.value)}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      inputmode="numeric"
      isNumericString
      {...rest}
    />
  );
}

export default NumericInput;
