import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { fetch as fetchSoftskills } from 'entities/softskills';

import SoftSkillForm from 'features/softskill/SoftskillAreaForm';
import SoftSkillLabelInlineEdit from 'features/softskill/SoftskillLabelInlineEdit';
import SoftSkillLevelLabelInlineEdit from 'features/softskill/SoftskillLevelLabelInlineEdit';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const TitleRegister = styled.h2`
  font-size: '26px';
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const ThemedAccordion = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root': {
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionDetails-root': {
      margin: '20px',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '9%',
    },
    '&$expanded': {
      border: '1px solid #425AE0',
    },
    boxShadow: 'none',
  },
  expanded: {},
})(Accordion);

export default function HardskillAreasList() {
  const { softSkills } = useSelector((
    { entities },
  ) => ({
    softSkills: entities.softskill,
  }));
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const [expandedHardskillAreaId, setExpandedHardskillId] = useState(null);

  function handleChangeExpandedHardskillArea(hardskillId) {
    setExpandedHardskillId(hardskillId !== expandedHardskillAreaId ? hardskillId : null);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSoftskills()).then(() => {
      setIsInitialLoading(false);
    });
  }, [dispatch]);

  return (
    <Container style={{ marginTop: '26px' }}>
      { isInitialLoading
        ? <CircularProgress />
        : (
          <>
            <HeaderContainer>
              <TitleRegister>
                Soft skill
              </TitleRegister>
              <SoftSkillForm />
            </HeaderContainer>

            {softSkills.allIds.map((softskillAreaId) => {
              const softkillArea = softSkills.byId[softskillAreaId];
              console.log(softkillArea);
              const isExpanded = expandedHardskillAreaId === softskillAreaId;
              return (
                <Fragment key={softskillAreaId}>
                  <ContentContainer>
                    <SoftSkillLabelInlineEdit
                      hardskillAreaId={softskillAreaId}
                      isExpanded={isExpanded}
                      handleChangeExpandedHardskillArea={handleChangeExpandedHardskillArea}
                    />
                  </ContentContainer>
                  {
                    isExpanded
                      ? (
                        <ThemedAccordion expanded={isExpanded}>
                          <AccordionDetails>
                            <Grid container spacing={3}>
                              { softkillArea.softskillLevel.length > 0 && softkillArea.softskillLevel.map((hardskill) => (
                                <Grid item xs={4}>
                                  <SoftSkillLevelLabelInlineEdit key={hardskill.id} softskill={hardskill} />
                                </Grid>
                              )) }
                              {
                                softkillArea.softskillLevel.length < 3 ? (
                                  <Grid item xs={4}>
                                    <SoftSkillLevelLabelInlineEdit softskillId={softskillAreaId} />
                                  </Grid>
                                ) : <></>
                              }
                            </Grid>
                          </AccordionDetails>
                        </ThemedAccordion>
                      )
                      : <></>
                    }

                </Fragment>
              );
            }) }
          </>
        )}
    </Container>
  );
}
