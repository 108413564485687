import React, { useEffect, useState } from 'react';
import * as kanbanHelper from '@lourenci/react-kanban';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import StickyIcon from 'assets/StickyIcon.svg';
import ModalPlacement from 'components/PlacementModal';

import { fetchVacancyById } from 'features/vacancy';
import { fetchRequesterByCompanyId } from 'features/requester';
import { qsObjectToString, qsStringToObject } from 'utils/queryString';
import {
  moveTalent,
  fetchBoardData,
  renameColumn,
  kanbanSlice,
  moveColumn,
  createColumn,
  removeColumn,
  disapproveTalent,
  placeVacancy,
  reapproveTalent,
} from '../redux';
import Board from './components/Board';
import KanbanCard from './components/KanbanCard';
import EditMenu from './components/EditMenu';

import {
  BackButton,
  ContainerHeader,
  Title,
  AvailableVacancies,
  Deadline,
  Button,
  SwitchShowActiveTalents,
  PageContainer,
  Row,
  Column,
  ButtonsContainer,
  SwitchContainer,
} from './styles';

function updatePositionByIndex(data) {
  return data.map((item, index) => ({ ...item, position: index + 1 }));
}

function KanbanScreen({ history }) {
  const dispatch = useDispatch();
  const { id: vacancyId } = useParams();

  const boardData = useSelector((state) => ({
    columns: state.features.kanban.boardData,
  }));
  const vacancyInfo = useSelector((state) => state.entities.vacancies.byId[vacancyId]);
  const requesterInfo = useSelector((state) => Object.values(state.entities.requesters.byId || {})
    .find((item) => item.companyId === vacancyInfo?.companyId));
  const { user, type: authType } = useSelector((state) => state.features.auth);

  const [placementModalInfo, setPlacementModalInfo] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);

  const isCompany = authType === 'requester';
  const companyInfo = requesterInfo?.company;
  const showActive = (qsStringToObject(history.location.search).showActive || 'true') === 'true';
  const canModifyKanban = Boolean(vacancyInfo?.isActive) && !isCompany;
  const canMoveTalents = Boolean(vacancyInfo?.isActive) && !isCompany && showActive;
  const canDisapproveOrReapprove = Boolean(vacancyInfo?.isActive) && !isCompany;
  const canCloseVacancy = Boolean(vacancyInfo?.isActive) && !isCompany;

  useEffect(() => {
    if (authType) {
      if (!vacancyInfo) {
        dispatch(fetchVacancyById(vacancyId)).catch((err) => {
          if (err.status === 403 && isCompany) {
            history.replace(`/vacancies/company/${user.id}`);
          }
        });
        return;
      }
      if (!requesterInfo) {
        dispatch(fetchRequesterByCompanyId(vacancyInfo.companyId));
      }
    }
    // eslint-disable-next-line
  }, [authType, requesterInfo, vacancyInfo, vacancyId, dispatch]);

  useEffect(() => {
    if (companyInfo && vacancyInfo) {
      dispatch(fetchBoardData(vacancyId, { isActive: showActive ? 1 : 0 }));
    }
    // eslint-disable-next-line
  }, [companyInfo, vacancyInfo, history.location.search, dispatch]);

  function handleChangeStatus() {
    history.push(`/vacancies/${vacancyId}/kanban${qsObjectToString({ showActive: !showActive })}`);
  }

  function handleCloseVacancy() {
    history.push(`/vacancies/${vacancyId}/close`);
  }

  function discardChanges() { dispatch(kanbanSlice.actions.setBoardData(boardData.columns)); }

  function handleCardMove(card, source, destination) {
    // moving card
    const updatedBoard = kanbanHelper.moveCard(boardData, source, destination);
    dispatch(kanbanSlice.actions.setBoardData(updatedBoard.columns));

    // ignores drop in the same column
    if (source.fromColumnId === destination.toColumnId) return;

    // calls api if column isn't Placements
    const columnPlacement = boardData.columns.find((item) => item.isPlacement === 1).id;
    if (destination.toColumnId !== columnPlacement) {
      dispatch(moveTalent(vacancyInfo.id, card.talentId, destination.toColumnId))
        .then(() => toast.info('Talento movido com sucesso'))
        .catch(() => {
          toast.error('Erro ao mover talento');
          discardChanges();
        });
    } else {
      // opens modal to insert placement value
      setPlacementModalInfo({
        visible: true,
        source,
        destination,
        card,
      });
    }
  }

  function onCancelPlacement() {
    const { destination, source } = placementModalInfo;
    // setOpenPlacement(false);
    setPlacementModalInfo(null);
    // revert board changes
    const newUpdatedBoard = kanbanHelper.moveCard(boardData,
      { fromPosition: destination.toPosition, fromColumnId: destination.toColumnId },
      { toPosition: source.fromPosition, toColumnId: source.fromColumnId });
    dispatch(kanbanSlice.actions.setBoardData(newUpdatedBoard.columns));
  }

  function onConfirmPlacement(values) {
    const { destination, card } = placementModalInfo;
    setPlacementModalInfo({ ...placementModalInfo, visible: false });

    // dispatch(moveTalent(vacancyInfo.id, card.talentId, destination.toColumnId)),
    dispatch(placeVacancy(vacancyInfo?.id, card.talentId,
      { kanbanId: destination.toColumnId, salary: values.closeValue }))
      .then(() => {
        setPlacementModalInfo(null);
        toast.info('Talento movido com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao mover talento');
        onCancelPlacement();
      });
  }

  function onColumnDragEnd(column, source, destination) {
    const lastColumnIndex = boardData.columns.length - 1;
    const isMovingFirstColumn = source.fromPosition === 0 || destination.toPosition === 0;
    const isMovingLastColumn = source.fromPosition === lastColumnIndex || destination.toPosition === lastColumnIndex;

    if (isMovingFirstColumn || isMovingLastColumn) {
      toast.info('Não é possível mover a primeira e a última coluna');
      return;
    }

    const updatedBoard = kanbanHelper.moveColumn(boardData, source, destination);
    dispatch(kanbanSlice.actions.setBoardData(updatePositionByIndex(updatedBoard.columns)));

    const newPosition = destination.toPosition + 1; // position starts on 1
    dispatch(moveColumn(column.id, newPosition))
      .then(() => toast.info('Coluna movida com sucesso'))
      .catch(() => {
        toast.error('Erro ao mover coluna');
        discardChanges();
      });
  }

  function onColumnRename(column, name) {
    const updatedBoard = kanbanHelper.changeColumn(boardData, column, { name });
    dispatch(kanbanSlice.actions.setBoardData(updatedBoard.columns));

    dispatch(renameColumn(column.id, name))
      .then(() => toast.info('Coluna renomeada com sucesso'))
      .catch(() => {
        toast.error('Erro ao renomear coluna');
        discardChanges();
      });
  }

  function onAddColumn() {
    const newColumn = {
      vacancyId: vacancyInfo.id,
      name: 'Novo',
      position: 2,
    };

    const provisoryColumn = {
      ...newColumn,
      id: 'NEW_COLUMN',
      cards: [],
    };

    const addedToFinal = kanbanHelper.addColumn(boardData, provisoryColumn);

    const provisoryBoard = kanbanHelper.moveColumn(addedToFinal, {
      fromPosition: addedToFinal.columns.length - 1,
    }, {
      toPosition: 1,
    });

    dispatch(kanbanSlice.actions.setBoardData(updatePositionByIndex(provisoryBoard.columns)));

    dispatch(createColumn(newColumn))
      .then(({ data }) => {
        const createdColumn = data.find((item) => item.position === 2);
        const updatedBoard = kanbanHelper.changeColumn(provisoryBoard, provisoryColumn, createdColumn);
        dispatch(kanbanSlice.actions.setBoardData(updatePositionByIndex(updatedBoard.columns)));
        toast.info('Coluna criada com sucesso');
      }).catch(() => {
        toast.error('Erro ao criar coluna');
        discardChanges();
      });
  }

  function onColumnRemove(column) {
    setIsRemoving(false);
    const updatedBoard = kanbanHelper.removeColumn(boardData, column);
    dispatch(kanbanSlice.actions.setBoardData(updatePositionByIndex(updatedBoard.columns)));

    dispatch(removeColumn(column.id)).then(() => {
      toast.info('Coluna removida com sucesso');
    }).catch(() => {
      toast.error('Erro ao remover coluna');
      discardChanges();
    });
  }

  function onDisapprove(reason, card) {
    const column = boardData.columns.find((boardColumn) => boardColumn.id === card.kanbanId);
    const updatedBoard = kanbanHelper.removeCard(boardData, column, card);
    dispatch(kanbanSlice.actions.setBoardData(updatedBoard.columns));

    dispatch(disapproveTalent(vacancyInfo.id, card.talentId, reason))
      .then(() => toast.info('Talento reprovado com sucesso'))
      .catch(() => {
        toast.error('Erro ao reprovar talento');
        discardChanges();
      });
  }

  function onReapprove(card) {
    const column = boardData.columns.find((boardColumn) => boardColumn.id === card.kanbanId);
    const updatedBoard = kanbanHelper.removeCard(boardData, column, card);
    dispatch(kanbanSlice.actions.setBoardData(updatedBoard.columns));

    dispatch(reapproveTalent(vacancyInfo.id, card.talentId))
      .then(() => toast.info('Talento reaprovado com sucesso'))
      .catch(() => {
        toast.error('Erro ao reaprovar talento');
        discardChanges();
      });
  }

  function onMenuSelect(key) {
    if (key === 'ADD') onAddColumn();
    if (key === 'DELETE') setIsRemoving(!isRemoving);
  }

  return (
    <PageContainer>
      <ModalPlacement
        open={placementModalInfo?.visible}
        close={onCancelPlacement}
        sendPlacement={onConfirmPlacement}
        talentName={placementModalInfo?.card.talentInfo.name}
      />
      <ContainerHeader>
        <BackButton onClick={history.goBack} />
        {vacancyInfo && companyInfo && (
          <Row>
            <Column>
              <Title>{companyInfo?.name}</Title>
              <AvailableVacancies>{`${vacancyInfo?.nVacancies} Vagas disponíveis`}</AvailableVacancies>
              <Deadline isVacancyClosed={false}>
                {vacancyInfo?.deadline && moment(vacancyInfo?.deadline).format('DD/MM/YYYY')}
              </Deadline>
            </Column>
            <ButtonsContainer>
              <Column>
                {!isCompany && (
                  <Button
                    endIcon={<img src={StickyIcon} alt="Ícone Notas" />}
                    style={{ justifyContent: 'space-between' }}
                    onClick={() => history.push(`/vacancies/${vacancyId}/kanban/notes`)}
                  >
                    Notas
                  </Button>
                )}
                {canModifyKanban && <EditMenu onSelect={onMenuSelect} />}
              </Column>

              <Column>
                {canCloseVacancy && <Button onClick={handleCloseVacancy}>Fechar vaga</Button>}
                <SwitchContainer>
                  <SwitchShowActiveTalents
                    onChange={handleChangeStatus}
                    checked={showActive}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </SwitchContainer>
              </Column>
            </ButtonsContainer>
          </Row>
        )}
      </ContainerHeader>

      <Board
        data={boardData}
        canDragColumn={canModifyKanban}
        canRenameColumn={canModifyKanban}
        canRemoveColumn={canModifyKanban}
        canDragCard={canMoveTalents}
        onCardDragEnd={handleCardMove}
        onColumnDragEnd={onColumnDragEnd}
        onColumnRename={onColumnRename}
        isRemoving={isRemoving}
        onColumnRemove={onColumnRemove}
        renderCard={(item) => (
          <KanbanCard
            showActionButton={canDisapproveOrReapprove}
            onDisapprove={onDisapprove}
            onReapprove={onReapprove}
            data={item}
          />
        )}
      />
    </PageContainer>
  );
}

export default KanbanScreen;
