import React, { useEffect, useState } from 'react';
import {
  MuiThemeProvider,
  InputLabel,
  RadioGroup,
  InputAdornment,
  Grid,
  FormControl,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearch } from 'react-icons/fa';

import { fetchCompanyByParams, setCompanyIdChoosed } from 'features/company';
import { fetchVacancyByCompanyNonPaginated, setVacancyIdChoosed } from 'features/vacancy';
import { fetchFilterTalentRefactored, fetchAllFilterTalentRefactored } from 'features/talent';
import SearchTextField from 'components/SearchTextField';
import helpIcon from 'assets/helpIcon.svg';
import Pagination from 'components/Pagination';
import HelpModal from 'components/HelpModal';
import { fetchRequesterById } from 'features/requester';
import { qsStringToObject, qsObjectToString } from 'utils/queryString';

import TalentItem from '../TalentItem';
import Filters from './components/Filters';
import {
  Container,
  ContainerHeader,
  SelectDiv,
  ThemedFormControl,
  ThemedSelect,
  ThemedMenuItem,
  MuiListTheme,
  ContainerSearch,
  ThemedFormControlLabel,
  ThemedRadio,
  HelpButton,
  HelpImg,
  ListCards,
} from './styles';

const getDefaultTextFilterMode = (authType, search) => {
  if (authType === 'requester' || qsStringToObject(search).shortId) return 'shortId';
  return 'name';
};
const getDefaultTextFilterValue = (search) => (qsStringToObject(search).shortId || qsStringToObject(search).name || '');

function ViewTalents() {
  // hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId = '', vacancyId = '' } = useParams();

  // selectors
  const vacancies = useSelector((state) => state.entities.vacancies);
  const companies = useSelector((state) => state.entities.companies);
  const { user, type: authType } = useSelector((state) => state.features.auth);
  const requesters = useSelector((state) => state.entities.requesters);
  const totalList = useSelector((state) => state.features.talent.totalList);
  const talents = useSelector((state) => state.entities.talents);

  // state
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [textFilterMode, setTextFilterMode] = useState(getDefaultTextFilterMode(authType, history.location.search));
  const [textFilterValue, setTextFilterValue] = useState(getDefaultTextFilterValue(history.location.search));

  const classes = {};

  const convertedQueryString = React.useMemo(() => {
    const {
      page, name, shortId, ...filters
    } = qsStringToObject(history.location.search);
    return filters;
  }, [history.location.search]);

  // functions
  const handleCompanyChange = (event) => history.push(`/talents/${event.target.value}`);

  const handleVacancyChange = (event) => history.push(`/talents/${companyId}/${event.target.value}`);

  const changeSearchQuery = (overrideParams = {}) => {
    const currentQuery = qsStringToObject(history.location.search);
    const newQueryObject = { ...currentQuery, page: 1, ...overrideParams };
    const newQuery = Object.keys(newQueryObject).reduce((prev, cur) => {
      const value = newQueryObject[cur];
      if (!value || (Array.isArray(value) && !value.length)) return prev;
      prev[cur] = newQueryObject[cur];
      return prev;
    }, {});
    history.push(`${history.location.pathname}${qsObjectToString(newQuery)}`);
  };

  const handlePageChange = (_, value) => {
    if (value === (Number(qsStringToObject(history.location.search).page) || 1)) return;
    changeSearchQuery({ page: value });
  };

  const handleSearchChange = ({ mode = textFilterMode, value = textFilterValue }) => {
    setTextFilterMode(mode);
    setTextFilterValue(value);
    const { shortId, name } = qsStringToObject(history.location.search);
    if (!value && !shortId && !name) return;
    const updatedParams = {
      [mode]: value,
      page: 1,
      ...(mode !== textFilterMode ? { [textFilterMode]: undefined } : {}),
    };
    changeSearchQuery(updatedParams);
  };

  const fetchTalents = () => {
    let filters = qsStringToObject(history.location.search);
    if (authType === 'requester') {
      filters = { ...filters, status: 'available' };
      dispatch(fetchFilterTalentRefactored(companyId, vacancyId, filters));
    } else {
      dispatch(fetchFilterTalentRefactored(companyId, vacancyId, filters));
    }
  };

  const fetchAllTalents = () => {
    let filters = qsStringToObject(history.location.search);
    if (authType === 'requester') {
      filters = { ...filters, status: 'available' };
      dispatch(fetchAllFilterTalentRefactored(filters));
    } else {
      dispatch(fetchAllFilterTalentRefactored(filters));
    }
  };

  const clearFilter = () => history.push(history.location.pathname);

  const getCompanyOptions = () => {
    const companyRequester = requesters.byId[user?.id]?.company;
    if (authType === 'requester' && companyRequester) return [companyRequester];
    return companies.allIds?.map((id) => companies.byId[id]) || [];
  };

  // effects
  useEffect(() => { // fetch companies or requester data
    if (authType) {
      if (authType === 'requester') {
        dispatch(fetchRequesterById(user.id));
      } else {
        dispatch(fetchCompanyByParams({ isActive: 1 }));
      }
    }
    // eslint-disable-next-line
  }, [authType]);

  useEffect(() => { // fetch vacancies
    if (authType === 'requester') {
      const requester = requesters.byId[user.id];
      if (!requester) return;
      // force redirect to /talents/${requester.companyId} if accessed by /talents
      if (!companyId) {
        history.replace(`/talents/${requester.companyId}`);
        return;
      }
      // force redirect to /talents/${requester.companyId} if accessed by /talents/${notARequesterId}
      if (companyId && Number(companyId) !== requester.companyId) {
        history.replace(`/talents/${requester.companyId}`);
        return;
      }
      // fetch vacancies
      dispatch(setCompanyIdChoosed(companyId));
      dispatch(fetchVacancyByCompanyNonPaginated(companyId));
    } else {
      // force redirect to /talents if accessed by /talents/${notACompanyId}
      if (companies.allIds?.length) {
        if (companyId && !companies.byId[companyId]) {
          history.replace('/talents');
          return;
        }
      }

      // fetch vacancies
      if (companyId && companies.byId[companyId]) {
        dispatch(setCompanyIdChoosed(companyId));
        dispatch(fetchVacancyByCompanyNonPaginated(companyId));
      }
    }
    // eslint-disable-next-line
  }, [companyId, companies, requesters, dispatch]);

  useEffect(() => {
    if (!vacancyId) {
      const { shortId, name } = qsStringToObject(history.location.search);
      setTextFilterValue(shortId || name || '');
      fetchAllTalents();
    }
  }, [history.location.search]);

  useEffect(() => { // fetch talents
    if (vacancyId && vacancies.allIds?.length && !vacancies.byId[vacancyId]) {
      history.replace(`/talents/${companyId}`);
      return;
    }
    if (vacancyId && vacancies.byId[vacancyId]) {
      dispatch(setVacancyIdChoosed(vacancyId));
      const { shortId, name } = qsStringToObject(history.location.search);
      setTextFilterValue(shortId || name || '');
      fetchTalents();
    }
    // eslint-disable-next-line
  }, [vacancyId, vacancies, history.location.search]);

  return (
    <Container>
      <HelpModal open={helpModalVisible} close={() => setHelpModalVisible(false)} />
      <ContainerHeader>
        O talento será enviado para a vaga:
      </ContainerHeader>
      <MuiThemeProvider theme={MuiListTheme}>
        <SelectDiv>
          <ThemedFormControl
            variant="outlined"
            style={{ marginRight: '5%' }}
          >
            <InputLabel id="labelcompany">Empresa</InputLabel>
            <ThemedSelect
              id="company"
              labelId="labelcompany"
              label="Empresa"
              onChange={(event) => handleCompanyChange(event)}
              value={companyId}
              disabled={authType === 'requester'}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              { getCompanyOptions().map((company) => (
                <ThemedMenuItem key={company.id} value={company.id} name={company.name}>
                  {company.name}
                </ThemedMenuItem>
              ))}
            </ThemedSelect>
          </ThemedFormControl>

          <ThemedFormControl variant="outlined">
            <InputLabel id="labelvacancy">Vaga</InputLabel>
            <ThemedSelect
              id="vacancy"
              labelId="labelvacancy"
              label="Vaga"
              disabled={!companyId}
              value={vacancyId}
              onChange={(event) => handleVacancyChange(event)}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              { vacancies.allIds ? vacancies.allIds.map((item) => {
                const vacancy = vacancies.byId[item];
                return (
                  <ThemedMenuItem key={vacancy.id} value={vacancy.id} name={vacancy.name}>
                    {vacancy.name}
                  </ThemedMenuItem>
                );
              })
                : (
                  <ThemedMenuItem key={1}>
                    Não há vagas
                  </ThemedMenuItem>
                )}
            </ThemedSelect>
          </ThemedFormControl>
        </SelectDiv>
      </MuiThemeProvider>
      <Filters
        vacancy={vacancies.byId[vacancyId]}
        filterValue={convertedQueryString}
        onApplyFilter={changeSearchQuery}
        onClearFilter={clearFilter}
      />
      <div>
        <ContainerHeader>
          <p>Talentos</p>
          <ContainerSearch>

            {authType !== 'requester' && (
            <FormControl>
              <ContainerHeader>
                Filtrar por:
              </ContainerHeader>
              <RadioGroup
                row
                value={textFilterMode}
                onChange={(e) => handleSearchChange({ mode: e.target.value })}
              >
                <ThemedFormControlLabel value="name" control={<ThemedRadio />} label="Nome" />
                <ThemedFormControlLabel value="shortId" control={<ThemedRadio />} label="ID" />
              </RadioGroup>
            </FormControl>
            )}

            <SearchTextField
              value={textFilterValue}
              onChange={(e) => setTextFilterValue(e.target.value)}
              onDebounceValue={(value) => handleSearchChange({ value })}
              InputProps={{
                endAdornment: <InputAdornment position="start"><FaSearch size={20} /></InputAdornment>,
              }}
            />
            <HelpButton onClick={() => setHelpModalVisible(true)}>
              <HelpImg src={helpIcon} alt="Informações" />
            </HelpButton>
          </ContainerSearch>
        </ContainerHeader>
        <ListCards className={classes.root}>
          <Grid container spacing={2}>
            { talents.allIds ? talents.allIds.map((item) => {
              const talent = talents.byId[item];
              return (
                <Grid key={talent.id} item md={3} sm={4} xs={6}>
                  <TalentItem edit={() => {}} talents={talent} vacancyId={vacancyId} authType={authType} />
                </Grid>
              );
            }) : <></>}
          </Grid>
        </ListCards>
        <Pagination
          style={{ paddingRight: 0 }}
          count={totalList !== 0 ? Math.ceil(totalList / 8) : 1}
          page={Number(qsStringToObject(history.location.search).page) || 1}
          onChange={handlePageChange}
          shape="rounded"
        />
      </div>
    </Container>
  );
}

export default ViewTalents;
