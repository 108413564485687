import React from 'react';
import { Modal, Backdrop, Button } from '@material-ui/core';

import { useStyles } from './styles';

function ConfirmRemoveColumn({ onConfirm, onClose, visible }) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={visible}
      onClose={onClose}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 className={classes.font}>Deseja excluir essa coluna?</h2>
        <h4 className={classes.font}>Todos os candidatos dela serão removidos</h4>
        <div>
          <Button
            classes={{ root: classes.button }}
            onClick={onConfirm}
          >
            Sim
          </Button>
          <Button
            classes={{ root: classes.button }}
            onClick={onClose}
          >
            Não
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmRemoveColumn;
