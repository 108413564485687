import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { TextField, CircularProgress } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';

import { returnRegions } from 'features/region';

const ThemeAutoComplete = withStyles({
  root: {
    width: '20%',
    '& .MuiFormControl-root': {
      marginTop: '0px !important',
    },
  },
})(Autocomplete);

const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
  },
})(TextField);

const AsyncAutoComplete = ({
  value, onChange, ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchOptions = async (search) => {
    if (search === '') {
      setOptions([]);
      return;
    }
    try {
      setLoading(true);
      const data = await returnRegions({ name: `%${search}%` });
      setOptions(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const debounced = useDebouncedCallback(fetchOptions, 500);

  const autocompleteProps = {
    open,
    value,
    options,
    loading,
    loadingText: 'Carregando...',
    noOptionsText: 'Nenhuma região encontrada',
    onOpen() {
      setOpen(true);
    },
    onClose() {
      setOpen(false);
    },
    onInputChange(event, search) {
      debounced.callback(search);
    },
    onChange,
  };

  return (
    <ThemeAutoComplete
      style={{ marginRight: '5%', width: '20%' }}
      {...autocompleteProps}
      {...rest}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <ThemedTextField
          {...params}
          id="cities-autocomplete"
          margin="normal"
          name="city"
          label="Região"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            autoComplete: 'region-autocomplete-disabled',
          }}
        />
      )}
    />
  );
};

export default AsyncAutoComplete;
