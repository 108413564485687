import * as React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = styled(ToastContainer)`
.Toastify__toast--info {
  background: #242448;
  .Toastify__progress-bar {
    background-color: #4D4D7E;
  }
}
.Toastify__toast--success {
  background: 'rgb(52, 187, 102)';
}
.Toastify__toast--warning {
  background: 'rgb(233, 255, 20)';
}
.Toastify__toast--error {
  background: #5C1221;
  .Toastify__progress-bar {
    background-color: #A71633;
  }
}
`;

function ToastContainerCustom() {
  return <Toast autoClose={12000} />;
}

export default ToastContainerCustom;
