import styled from 'styled-components';
import {
  TextField, Button, Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Background = styled.div`
    background: transparent linear-gradient(180deg, #0A0B3A 0%, #425AE0 67%, #4662FF 100%);
    opacity: 1;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top:0;
  left:0;
  background-repeat: no-repeat;
`;

export const DivForm = styled.div`
    top:30%;
    left:11.49%;
    width: 32.35%;
    position: fixed;
`;

export const LabelLogin = styled.div`
    width: 500px;
`;

export const TitleLogin = styled.h1`
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 42px;
    font-weight: 400;
    opacity: 1;
    font-family: 'Spartan', sans-serif;
`;

export const SubtitleLogin = styled.h2`
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 500;
    opacity: 1;
    font-family: 'Spartan', sans-serif;
`;

export const DivLogin = styled.div`
    margin-top: 32px;
`;

export const PasswordTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
    },
    opacity: '1',
    width: '100%',
  },
})(TextField);

export const DivPassword = styled.div`
    padding-top:8%;
`;

export const DivButton = styled.div`
    height: 44px;
    width:100%;
    align-items: center;
    display: flex;
    margin-top:9%;
`;

export const ButtonLogin = withStyles({
  root: {
    background: '#161B5D 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 6px #00000029',
    borderRadius: '4px',
    opacity: '1',
    width: '50%',
    height: '44px',
    color: '#ffffff',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})(Button);

export const DivLabel = styled.div`
    top:30%;
    left:57.61%;
    position: fixed;
    width: 29%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LogoImage = styled.img`
    height: 100%;
    display:inline;
`;

export const DivForgot = styled.div`
    width:100%;
    align-items: center;
    display: flex;
    padding-top:1%;
`;

export const ForgotText = styled.p`
    text-align: left;
    text-decoration: underline;
    letter-spacing: 0px;
    font-family: 'Segoe UI', sans-serif;
    font-size: 20px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
`;

export const ThemedCheckBox = withStyles({
  root: {
    color: '#ffffff',
    paddingLeft: 0,
    '&$checked': {
      color: '#ffffff',
    },
    '&$disabled': {
    },
  },
  disabled: {},
  checked: {},
})(Checkbox);

export const PrivacyPolicyContainer = styled.div`
  margin-top: 8px;
`;

export const PrivacyPolicyForm = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  a {
    color: #ffffff;
  }
`;
