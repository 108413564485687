import { createEntitySlice } from 'utils/redux';
import api from 'app/axios';

const name = 'company/requester';
const endpoint = '/company/requester';
const slice = createEntitySlice(name, endpoint, api);
export const {
  merge,
  fetch,
  fetchById,
  addId,
  dropAll,
} = slice.actions;

export default slice.reducer;
