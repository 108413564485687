import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { remove } from '.';
import HardskillAreaForm from './HardskillAreaForm';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#171836',
    color: '#ffffff',
  },
  content: {
    color: '#ffffff',
  },
}));

const LabelHardskillArea = styled.h3`
  font-size: '26px';
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
`;

const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: 'black',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
  },
})(MenuItem);

const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#171836',
      },
    },
  },
});

export default function HardskillAreaLabelAndCrud({ hardskillAreaId: id, isExpanded, handleChangeExpandedHardskillArea }) {
  const { hardskillArea } = useSelector(({ entities: { hardskillAreas } }) => ({
    hardskillArea: hardskillAreas.byId[id],
  }));

  const [isEditing, setIsEditing] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showMoreOptionsMenu, setShowMoreOptionsMenu] = useState(null);

  const classes = useStyles();

  function handleMoreOptionsMenuClick(event) {
    if (!showMoreOptionsMenu) {
      setShowMoreOptionsMenu(event.currentTarget);
    }
  }

  function handleMoreOptionsMenuClose() {
    if (showMoreOptionsMenu) {
      setShowMoreOptionsMenu(null);
    }
  }

  function handleEditOptionClick() {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  function handleCancelEdit() {
    setShowMoreOptionsMenu(null);
    setIsEditing(false);
  }

  function handleConfirmEdit() {
    setShowMoreOptionsMenu(null);
    setIsEditing(false);
  }

  function handleRemoveOptionClick() {
    setIsRemoving(true);
  }

  function handleCancelRemove() {
    setIsRemoving(false);
    setShowMoreOptionsMenu(null);
  }

  const dispatch = useDispatch();

  function handleConfirmRemove() {
    dispatch(remove(id))
      .then(() => toast.info('Área de Hard Skill removida com sucesso'))
      .catch(() => toast.error('Erro ao remover Área de Hard skill'));
    setIsRemoving(false);
    setShowMoreOptionsMenu(null);
  }

  return (
    isEditing
      ? <HardskillAreaForm id={id} onCancel={handleCancelEdit} onConfirm={handleConfirmEdit} />
      : (
        <>
          <LabelHardskillArea>
            { hardskillArea.name }
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() => handleChangeExpandedHardskillArea(id)}
            >
              {!isExpanded ? <ExpandMoreIcon style={{ color: 'white' }} />
                : <ExpandLessIcon style={{ color: 'white' }} />}
            </IconButton>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleMoreOptionsMenuClick}
              style={{ color: 'white' }}
            >
              <MoreHorizIcon />
            </IconButton>
          </LabelHardskillArea>

          <MuiThemeProvider theme={MuiListThem}>
            <Menu
              id="simple-menu"
              anchorEl={showMoreOptionsMenu}
              keepMounted
              open={Boolean(showMoreOptionsMenu)}
              onClose={handleMoreOptionsMenuClose}
            >
              <ThemedMenuItem onClick={handleEditOptionClick}>Editar</ThemedMenuItem>
              <ThemedMenuItem onClick={handleRemoveOptionClick}>Remover</ThemedMenuItem>
            </Menu>
          </MuiThemeProvider>
          <Dialog
            open={isRemoving}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id="alert-dialog-title">Tem certeza que deseja remover?</DialogTitle>
            <DialogContent>
              <DialogContentText classes={{ root: classes.content }} id="alert-dialog-description">
                Todo o grupo será removido.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelRemove} color="inherit">
                Cancelar
              </Button>
              <Button onClick={handleConfirmRemove} color="inherit" autoFocus>
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
  );
}
