function FormatLevel(props) {
  let level = props;

  switch (level) {
    case 'Júnior':
      level = 'junior';
      break;
    case 'Sênior':
      level = 'senior';
      break;
    case 'Coordenador':
      level = 'coordinator';
      break;
    case 'Pleno':
      level = 'full';
      break;
    case 'Diretor':
      level = 'director';
      break;
    case 'Especialista':
      level = 'specialist';
      break;
    case 'Gerente':
      level = 'manager';
      break;
    case 'junior':
      level = 'Júnior';
      break;
    case 'senior':
      level = 'Sênior';
      break;
    case 'coordinator':
      level = 'Coordenador';
      break;
    case 'full':
      level = 'Pleno';
      break;
    case 'director':
      level = 'Diretor';
      break;
    case 'specialist':
      level = 'Especialista';
      break;
    case 'manager':
      level = 'Gerente';
      break;
    default:
      break;
  }
  return level;
}

export default FormatLevel;
