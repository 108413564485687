import api from 'app/axios';
import promiseLimit from 'promise-limit';
import { toast } from 'react-toastify';

import envConfig from 'app/envConfig';

const isProduction = process.env.REACT_APP_ENV === 'production';

const tables = [
  'areaofpractice',
  'areaofpracticecategory',
  'city',
  'company',
  'companylineofbusiness',
  'favorite',
  'hardskill',
  'hardskillarea',
  'kanban',
  'lineofbusiness',
  'reasontoclosevacancy',
  'region',
  'requester',
  'softskill',
  'softskilllevel',
  'specialist',
  'talent',
  'talentareaofpractice',
  'talenthardskill',
  'talenthiringformat',
  'talentlanguage',
  'talentlevel',
  'talentsoftskill',
  'talentuniversitylevel',
  'talentuniversityrank',
  'user',
  'vacancy',
  'vacancyareaofpractice',
  'vacancybenefit',
  'vacancyhardskill',
  'vacancyhiringformat',
  'vacancylanguage',
  'vacancylevel',
  'vacancysoftskill',
  'vacancytalent',
  'vacancyuniversitylevel',
  'vacancyuniversityrank',
];

const downloadDataCsv = () => {
  const limit = promiseLimit(5);

  return Promise.all(tables.map((table) => limit(() => api({
    url: `/micro-jobler-report-${isProduction ? 'prod' : 'dev'}/${table}`,
    baseURL: envConfig.gateway.url,
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${table}.csv`); // or any other extension
      document.body.appendChild(link);
      link.click();
    }))))
    .then(() => toast.info('Todos os arquivos foram gerados com sucesso'))
    .catch(() => toast.error('Houve um problema no download de um ou mais arquivos'));
};

export default downloadDataCsv;
