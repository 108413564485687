import React, { useEffect, useState } from 'react';
import {
  AccordionDetails, AccordionSummary, InputLabel, ListItemText, MuiThemeProvider,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import AutoCompleteRegion from 'components/AutoCompleteRegion';
import FilterModal from 'components/FilterModal';
import formatGender from 'components/FormatGender';
import formatLevel from 'components/FormatLevel';
import formatUniversityLevel from 'components/FormatUniversityLevel';

import {
  emptyFilter,
  ageMarks,
  salaryMarks,
  genderOptions,
  levelOptions,
  maritalStatusOptions,
  pcdOptions,
  universityLevelOptions,
} from './utils';

import {
  ThemedAccordion,
  TitleRegisterAccordion,
  ExpandDiv,
  ThemedButton,
  SelectDiv,
  ThemedTextField,
  MuiListThem,
  ThemedSelect,
  ThemedMenuItem,
  ThemedFormControl,
  SalaryDiv,
  ContainerHeader,
  SliderDiv,
  AgeDiv,
  ThemedSlider,
  ThemedCheckBox,
} from './styles';

function Filters({
  vacancy,
  onApplyFilter,
  filterValue,
  onClearFilter,
}) {
  const [filters, setFilters] = useState(emptyFilter);
  const [filterModalType, setFilterModalType] = useState(null);
  const [shouldFilterBySalary, setShouldFilterBySalary] = useState(false);
  const [shouldFilterByAge, setShouldFilterByAge] = useState(false);

  const handleFilterChange = (field, fieldValue) => setFilters({ ...filters, [field]: fieldValue });

  const openFilterModal = (type) => setFilterModalType(type);

  const normalizeFilterFromQuery = (values) => Object.keys(values).reduce((prev, key) => {
    switch (key) {
      case 'areaOfPractice':
      case 'hardskill':
      case 'softskill':
      case 'salary':
      case 'age':
        prev[key] = values[key].map((item) => Number(item));
        break;
      default:
        prev[key] = values[key];
        break;
    }
    return prev;
  }, {});

  useEffect(() => {
    if (vacancy && !Object.keys(filterValue).length) {
      setFilters({
        ...emptyFilter,
        areaOfPractice: vacancy.areasOfPractice.map((item) => item.id),
        level: vacancy.levels.map((item) => item.level),
        hardskill: vacancy.hardskills.map((item) => item.id),
        softskill: vacancy.softskills.map((item) => item.id),
        universityLevel: vacancy.universityLevels.map((item) => item.level),
        gender: vacancy.gender ? [vacancy.gender] : [],
        pcd: vacancy.PCD,
        salary: vacancy.minSalary && vacancy.maxSalary ? [vacancy.minSalary, vacancy.maxSalary] : emptyFilter.salary,
        age: vacancy.minAge && vacancy.maxAge ? [vacancy.minAge, vacancy.maxAge] : emptyFilter.age,
      });
      setShouldFilterBySalary(vacancy.minSalary && vacancy.maxSalary);
      setShouldFilterByAge(vacancy.minAge && vacancy.maxAge);
    }
    // eslint-disable-next-line
  }, [vacancy]);

  useEffect(() => {
    if (Object.keys(filterValue).length) {
      setFilters({
        ...filters,
        ...normalizeFilterFromQuery(filterValue),
      });
      if (filterValue.age) setShouldFilterByAge(true);
      if (filterValue.salary) setShouldFilterBySalary(true);
    }
    // eslint-disable-next-line
  }, [filterValue]);

  const applyFilter = () => onApplyFilter({
    ...filters,
    age: shouldFilterByAge ? filters.age : undefined,
    salary: shouldFilterBySalary ? filters.salary : undefined,
  });

  return (
    <>
      <ThemedAccordion style={{ marginTop: '32px' }}>
        <AccordionSummary
          expandIcon={<ExpandMore style={{ color: 'white' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TitleRegisterAccordion>
            {`Filtro customizado ${Object.keys(filterValue).length ? '(Filtros aplicados)' : ''}`}
          </TitleRegisterAccordion>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 8, marginBottom: 18 }}>
          <ExpandDiv>
            <ThemedButton
              onClick={applyFilter}
              style={{ marginLeft: '50%', marginRight: '5%' }}
            >
              Aplicar filtro
            </ThemedButton>
            <ThemedButton onClick={() => {
              onClearFilter();
              setFilters(emptyFilter);
              setShouldFilterBySalary(false);
              setShouldFilterByAge(false);
            }}
            >
              Limpar filtro
            </ThemedButton>
            <SelectDiv>
              <ThemedTextField
                id="areaofpractice-text"
                data-testid="areaofpractice-input"
                name="areaofpractice"
                autoComplete="off"
                variant="outlined"
                style={{ marginRight: '5%', color: '#ffffff' }}
                value={filters.areaOfPractice.length > 0
                  ? `${filters.areaOfPractice.length} areas de atuação selecionadas` : 'Área de atuação'}
                onClick={() => openFilterModal('areaOfPractice')}
                InputProps={{
                  readOnly: true,
                  endAdornment: <ExpandMore style={{ color: '#425AE0' }} />,
                }}
              />
              <MuiThemeProvider theme={MuiListThem}>
                <ThemedFormControl variant="outlined" style={{ marginRight: '5%' }}>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Nível
                  </InputLabel>
                  <ThemedSelect
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    label="Nivel"
                    variant="outlined"
                    value={filters.level}
                    onChange={(e) => handleFilterChange('level', e.target.value)}
                    renderValue={(selected) => selected.map(formatLevel).join(', ')}
                  >
                    {levelOptions.map((name) => (
                      <ThemedMenuItem key={name.id} value={name.id}>
                        <ListItemText primary={name.name} />
                      </ThemedMenuItem>
                    ))}
                  </ThemedSelect>
                </ThemedFormControl>
              </MuiThemeProvider>
              <ThemedTextField
                id="hardskill-text"
                data-testid="hardskill-input"
                name="hardskill"
                autoComplete="off"
                variant="outlined"
                style={{ marginRight: '5%' }}
                value={filters.hardskill.length > 0
                  ? `${filters.hardskill.length} hardskills selecionadas`
                  : 'Hardskills'}
                onClick={() => openFilterModal('hardskill')}
                InputProps={{
                  readOnly: true,
                  endAdornment: <ExpandMore style={{ color: '#425AE0' }} />,
                }}
              />
              <ThemedTextField
                id="softskill-text"
                data-testid="softskill-input"
                name="softskill"
                autoComplete="off"
                variant="outlined"
                style={{ marginRight: '5%' }}
                value={filters.softskill.length > 0
                  ? `${filters.softskill.length} softskills selecionadas`
                  : 'Softskills'}
                onClick={() => openFilterModal('softskill')}
                InputProps={{
                  readOnly: true,
                  endAdornment: <ExpandMore style={{ color: '#425AE0' }} />,
                }}
              />
            </SelectDiv>
            <MuiThemeProvider theme={MuiListThem}>
              <SelectDiv>
                <ThemedFormControl variant="outlined" style={{ marginRight: '5%' }}>
                  <InputLabel
                    id="university-level-mult-checkbox"
                  >
                    Formação
                  </InputLabel>
                  <ThemedSelect
                    labelId="university-level-mult-checkbox"
                    id="demo-mutiple-checkbox"
                    multiple
                    label="Formação"
                    variant="outlined"
                    value={filters.universityLevel}
                    onChange={(e) => handleFilterChange('universityLevel', e.target.value)}
                    renderValue={(selected) => selected.map(formatUniversityLevel).join(', ')}
                  >
                    {universityLevelOptions.map((name) => (
                      <ThemedMenuItem key={name.id} value={name.id}>
                        <ListItemText primary={name.name} />
                      </ThemedMenuItem>
                    ))}
                  </ThemedSelect>
                </ThemedFormControl>
                <AutoCompleteRegion
                  value={{ name: filters.region }}
                  onChange={(_, value) => handleFilterChange('region', value.name)}
                />
                <ThemedFormControl style={{ marginRight: '5%' }}>
                  <InputLabel
                    id="demo-mutiple-checkbox-label"
                    variant="outlined"
                  >
                    Estado Civil
                  </InputLabel>
                  <ThemedSelect
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    label="Estado Civil"
                    variant="outlined"
                    value={filters.maritalStatus}
                    onChange={(e) => handleFilterChange('maritalStatus', e.target.value)}
                    renderValue={(selected) => selected
                      .map((label) => maritalStatusOptions
                        .find((item) => item.id === label)?.name)
                      .join(', ')}
                  >
                    {maritalStatusOptions.map((name) => (
                      <ThemedMenuItem key={name.id} value={name.id}>
                        <ListItemText primary={name.name} />
                      </ThemedMenuItem>
                    ))}
                  </ThemedSelect>
                </ThemedFormControl>
                <ThemedFormControl style={{ marginRight: '5%' }}>
                  <InputLabel
                    id="gender-mutiple-checkbox-label"
                    variant="outlined"
                  >
                    Sexo
                  </InputLabel>
                  <ThemedSelect
                    labelId="gender-mutiple-checkbox-label"
                    id="gender-mutiple-checkbox"
                    multiple
                    label="Sexo"
                    variant="outlined"
                    value={filters.gender}
                    onChange={(e) => handleFilterChange('gender', e.target.value)}
                    renderValue={(selected) => selected.map(formatGender).join(', ')}
                  >
                    {genderOptions.map((name) => (
                      <ThemedMenuItem key={name.id} value={name.id}>
                        <ListItemText primary={name.name} />
                      </ThemedMenuItem>
                    ))}
                  </ThemedSelect>
                </ThemedFormControl>
              </SelectDiv>
            </MuiThemeProvider>
            <SelectDiv>
              <MuiThemeProvider theme={MuiListThem}>
                <ThemedFormControl style={{ marginRight: '5%' }}>
                  <InputLabel variant="outlined" id="pcd-mult-checkbox">
                    Talento PCD
                  </InputLabel>
                  <ThemedSelect
                    labelId="pcd-mult-checkbox"
                    id="demo-mutiple-checkbox"
                    label="Talento PCD"
                    style={{ height: '58px' }}
                    variant="outlined"
                    value={filters.pcd}
                    onChange={(e) => handleFilterChange('pcd', e.target.value)}
                    inputProps={{
                      classes: {
                        // icon: classes.icon,
                      },
                    }}
                  >
                    {pcdOptions.map((name) => (
                      <ThemedMenuItem key={name.id} value={name.id}>
                        <ListItemText primary={name.name} />
                      </ThemedMenuItem>
                    ))}
                  </ThemedSelect>
                </ThemedFormControl>
              </MuiThemeProvider>
              <SalaryDiv>
                <ContainerHeader style={{ justifyContent: 'flex-start' }}>
                  Faixa salarial
                  <ThemedCheckBox
                    checked={shouldFilterBySalary}
                    onChange={() => setShouldFilterBySalary(!shouldFilterBySalary)}
                  />
                </ContainerHeader>
                {shouldFilterBySalary ? (
                  <SliderDiv>
                    <ThemedSlider
                      value={filters.salary}
                      valueLabelDisplay="auto"
                      step={100}
                      max={30000}
                      min={1000}
                      marks={salaryMarks}
                      aria-labelledby="range-slider"
                      onChange={(_, newValue) => handleFilterChange('salary', newValue)}
                    />
                  </SliderDiv>
                ) : null}
              </SalaryDiv>
              <AgeDiv>
                <ContainerHeader style={{ justifyContent: 'flex-start' }}>
                  Faixa de idade
                  <ThemedCheckBox
                    checked={shouldFilterByAge}
                    onChange={() => setShouldFilterByAge(!shouldFilterByAge)}
                  />
                </ContainerHeader>
                {shouldFilterByAge ? (
                  <SliderDiv>
                    <ThemedSlider
                      value={filters.age}
                      valueLabelDisplay="auto"
                      step={1}
                      max={65}
                      min={18}
                      marks={ageMarks}
                      aria-labelledby="range-slider"
                      onChange={(_, newValue) => handleFilterChange('age', newValue)}
                    />
                  </SliderDiv>
                ) : null}
              </AgeDiv>
            </SelectDiv>
          </ExpandDiv>
        </AccordionDetails>
      </ThemedAccordion>
      <FilterModal
        onConfirm={(values) => handleFilterChange(filterModalType, values)}
        values={filters[filterModalType]}
        open={Boolean(filterModalType)}
        close={() => setFilterModalType(null)}
        type={filterModalType}
      />
    </>
  );
}

export default Filters;
