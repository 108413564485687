import { createEntitySlice } from 'utils/redux';
import api from 'app/axios';

const name = 'vacancies';
const endpoint = '/vacancy';
const slice = createEntitySlice(name, endpoint, api);
export const {
  merge,
  mergeFav,
  fetch,
  fetchById,
  addId,
  dropAll,
} = slice.actions;

export default slice.reducer;
