import { createSlice } from '@reduxjs/toolkit';
import { fetch } from 'entities/areaofpractice';

const initialState = {
  areaOfPracticeSel: [],
};
export const slice = createSlice({
  name: 'areaofpractice/category',
  initialState,
  reducers: {
    setAreaOfPracticeSelected: (state, { payload: areaOfPractice }) => {
      state.areaOfPracticeSel = !areaOfPractice ? initialState.areaOfPracticeSel : areaOfPractice;
    },
  },
});

const {
  setAreaOfPracticeSelected,
} = slice.actions;

export const selectAreaOfPractice = (areaOfPractice) => (dispatch) => {
  dispatch(setAreaOfPracticeSelected(areaOfPractice));
};

export const fetchAreaOfPracticeAvailables = () => (dispatch) => dispatch(
  fetch(),
);

export default slice.reducer;
