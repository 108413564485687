import axios from 'axios';

import {
  defaultConfig, defaultAuthFirebaseRequestHandler, defaultResponseHandlers,
} from 'utils/axios';

import { auth } from 'app/authConfig';
import envConfig from 'app/envConfig';

const axiosInstance = axios.create({
  ...defaultConfig,
  baseURL: envConfig.api.url,
});

axiosInstance.interceptors.request.use(
  (config) => defaultAuthFirebaseRequestHandler(config, auth),
);
axiosInstance.interceptors.response.use(
  defaultResponseHandlers.response, defaultResponseHandlers.error,
);

export function addGlobalRequestInterceptor(
  onFulfilled,
  onRejected,
) {
  const id = axiosInstance.interceptors.request.use(onFulfilled, onRejected);
  return id;
}

export function removeGlobalRequestInterceptor(id) {
  axiosInstance.interceptors.request.eject(id);
}

export function addGlobalResponseInterceptor(
  onFulfilled,
  onRejected,
) {
  const id = axiosInstance.interceptors.response.use(onFulfilled, onRejected);
  return id;
}

export function removeGlobalResponseInterceptor(id) {
  axiosInstance.interceptors.response.eject(id);
}

export default axiosInstance;
