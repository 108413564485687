import { createSlice } from '@reduxjs/toolkit';
import {
  fetch, fetchById, merge, dropAll,
} from 'entities/vacancies';
import { dropAll as dropFavorites } from 'entities/favorites';

import api from 'app/axios';

const initialState = {
  vacancyCreatedId: 0,
  pageRegister: 0,
  pageList: 0,
  totalList: 0,
  pagesAccessed: [],
  vacancyCreatedObj: null,
  favorites: [],
};
export const vacancySlice = createSlice({
  name: 'vacancy',
  initialState,
  reducers: {
    setVacancyCreatedId: (state, { payload: vacancyCreatedId }) => {
      state.vacancyCreatedId = !vacancyCreatedId ? initialState.vacancyCreatedId : vacancyCreatedId;
    },
    setPageRegister: (state, { payload: page }) => {
      state.pageRegister = !page ? initialState.pageRegister : page;
    },
    setPageList: (state, { payload: page }) => {
      state.pageList = !page ? initialState.pageList : page;
      if (!state.pagesAccessed.includes(page)) {
        state.pagesAccessed.push(page);
      }
    },
    setTotalList: (state, { payload: total }) => {
      state.totalList = !total ? initialState.totalList : total;
    },
    setFavorites: (state, { payload: favorites }) => {
      state.favorites = [];
      favorites.forEach((item) => {
        if (!state.favorites.includes(item.talentId)) {
          state.favorites.push(item.talentId);
        }
      });
    },
    setPagesAccessedEmpty: (state) => {
      state.pagesAccessed = [];
    },
    setVacancyObject: (state, { payload: vacancyObject }) => {
      state.vacancyCreatedObj = vacancyObject;
    },
  },
});

const {
  setVacancyCreatedId, setPageRegister, setTotalList, setPageList, setFavorites, setPagesAccessedEmpty,
} = vacancySlice.actions;

const vacancyEndpoint = '/vacancy';

const vacancyCompanyEndpoint = '/vacancy/company';

export const fetchVacancyByParams = (vacancyParams) => (dispatch) => dispatch(
  fetch(vacancyParams),
);

export const fetchEditVacancyId = (vacancyid) => (dispatch) => {
  dispatch(setVacancyCreatedId(vacancyid));
};

export const mergeNewVacancy = (vacancyObject) => (dispatch) => {
  dispatch(merge(vacancyObject));
};

export const fetchVacancyById = (vacancyId) => (dispatch) => dispatch(fetchById(vacancyId));

export const fetchFirstVacancyByCompany = (companyId, page) => (dispatch) => {
  dispatch(dropAll());
  return api
    .get(`${vacancyCompanyEndpoint}/${companyId}`, { params: { page, limit: 8 } }).then(({ data }) => {
      dispatch(setPagesAccessedEmpty());
      dispatch(merge(data.results));
      dispatch(setTotalList(data.total));
      dispatch(setPageList(page));
    });
};

export const fetchVacancyByCompany = (companyId, page) => (dispatch) => api
  .get(`${vacancyCompanyEndpoint}/${companyId}`, { params: { page, limit: 8 } }).then(({ data }) => {
    dispatch(merge(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(page));
  });

export const fetchVacancyByCompanyNonPaginated = (companyId) => (dispatch) => api
  .get(`${vacancyCompanyEndpoint}/${companyId}`, { params: { isActive: 1 } }).then(({ data }) => {
    dispatch(dropAll());
    dispatch(merge(data));
    if (Array.isArray(data) && data.length > 0) {
      dispatch(setVacancyCreatedId(data[0].id));
    } else {
      dispatch(setVacancyCreatedId(data.id));
    }
  });

export const fetchVacancyListPageBack = (page) => (dispatch) => {
  dispatch(setPageList(page));
};

export const fetchNextPage = (page) => (dispatch) => dispatch(setPageRegister(page));

export const addNewVacancy = (vacancy) => (dispatch) => api
  .post(`${vacancyEndpoint}`, vacancy).then((response) => {
    dispatch(fetchById(response.data.id));
    dispatch(setVacancyCreatedId(response.data.id));
  });

export const setVacancyIdChoosed = (vacancy) => (dispatch) => {
  dispatch(setVacancyCreatedId(vacancy));
};

export const sendTalent = (vacancy) => (dispatch) => api
  .post(`${vacancyEndpoint}/sendTalent`, vacancy).then(() => {
    dispatch(fetch());
  });

export const sendFavorites = (vacancyId) => (dispatch) => api
  .post(`${vacancyEndpoint}/${vacancyId}/sendTalent`).then(() => {
    dispatch(dropFavorites());
  });

export const fetchFavorites = (vacancyId) => (dispatch) => api
  .get(`${vacancyEndpoint}/${vacancyId}/favorites`).then(({ data }) => {
    dispatch(setFavorites(data.results));
  });

export const favoriteTalent = (data) => (dispatch) => api
  .post(`${vacancyEndpoint}/favorite`, data).then(() => dispatch(fetchFavorites(data.vacancyId)));

export const removeFavoriteTalent = (payload) => (dispatch) => api
  .delete(`${vacancyEndpoint}/${payload.vacancyId}/${payload.talentId}/favorite`)
  .then(() => dispatch(fetchFavorites(payload.vacancyId)));

export const updateVacancyData = (vacancyId, data) => (dispatch) => api
  .patch(`${vacancyEndpoint}/${vacancyId}`, data).then(() => dispatch(fetchById(vacancyId)));

export const updateVacancyPart2Data = (vacancyId, data) => (dispatch) => api
  .patch(`${vacancyEndpoint}/${vacancyId}`, { data }).then(() => dispatch(fetchById(vacancyId)));

export const updateTalentStatus = (vacancyId, type) => (dispatch) => api
  .patch(`${vacancyEndpoint}/${vacancyId}/${type}`).then(() => dispatch(fetchById(vacancyId)));

export const updateVacancyByPart = (vacancyId, data, part) => (dispatch) => api
  .patch(`${vacancyEndpoint}/${vacancyId}/part${part}`, data).then(() => dispatch(fetchById(vacancyId)));

export default vacancySlice.reducer;
