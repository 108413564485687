import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Formik, Form, Field,
} from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { add, update, remove } from '.';

const ThemedChip = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#ffffff',
    marginLeft: '5%',
    width: '100%',
    border: '1px solid #425AE0',
    '& .MuiChip-deleteIcon': {
      color: '#425AE0',
    },
    '& .MuiChip-label': {
      width: '100%',
    },
  },
})(Chip);

const ThemedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
  },
})(TextField);

export default function HardskillLabelInlineEdit({
  hardskill = null, hardskillAreaId = null,
}) {
  const isAddingNew = !hardskill;
  const { hardskillArea } = useSelector(({
    entities: { hardskillAreas },
  }) => ({
    hardskillArea: hardskillAreas.byId[!isAddingNew ? hardskill.hardskillAreaId : hardskillAreaId],
  }));

  const [isEditing, setIsEditing] = useState(isAddingNew);

  function handleClick() {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  const dispatch = useDispatch();

  function handleDelete() {
    dispatch(remove(hardskill));
    toast.info('Hardskill excluída com sucesso');
  }

  function handleSubmit(/* values: */{ name }, { resetForm } /* , actions */) {
    if (isAddingNew) {
      dispatch(add({ hardskillAreaId: hardskillArea.id, name })).then(() => {
        setIsEditing(false);
        toast.info('Hard Skill adicionada com sucesso');
        resetForm();
      })
        .catch(() => {
          toast.error('Erro ao adicionar Hard Skill');
        });
    } else {
      dispatch(update({ id: hardskill.id, hardskillAreaId: hardskill.hardskillAreaId, name })).then(() => {
        setIsEditing(false);
        toast.info('Hard Skill editada com sucesso');
        resetForm();
      })
        .catch(() => {
          toast.error('Erro ao editar Hard Skill');
        });
    }
  }

  function validateName(value) {
    let validation = null;

    // TODO: i18n('invalid')
    if (!value.length > 0) {
      validation = 'Obrigatório';
    } else if (hardskillArea.hardskills.find(({ name }) => name === value)) {
      validation = 'Já existente';
    }

    return validation;
  }

  function renderInlineForm() {
    return (
      <Formik
        initialValues={{
          name: isAddingNew ? '' : hardskill.name,
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, dirty }) => (
          <Form autoComplete="off">
            <Field
              name="name"
              type="text"
              autocomplete="off"
              as={ThemedTextField}
              placeholder={`${isAddingNew ? 'Nova' : 'Editar'} hardskill`}
              validate={validateName}
              style={{ maxWidth: '100%', width: '72%' }}
            />
            { dirty && errors.name && <div>{errors.name}</div>}
            <Button
              type="submit"
              style={{ color: 'white', padding: '6px 0px' }}
            >
              Salvar
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <ThemedChip
      variant="outlined"
      // REVIEW: Couldn't set inline form as Chip tag inner content
      label={!isEditing && hardskill ? hardskill.name : renderInlineForm()}
      onClick={handleClick}
      onDelete={!isAddingNew && !isEditing ? handleDelete : null}
    />
  );
}
