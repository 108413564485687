import { createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { auth, storage } from 'app/authConfig';

export const LoadingSteps = {
  INITIAL: 0,
  AUTH_LOADING: 1,
  AUTH_READY: 2,
};

const initialState = {
  loadingStep: LoadingSteps.INITIAL,
  user: null,
  type: null,
  menuType: '',
};
export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload: user }) => {
      state.user = !user ? initialState.user : user;
    },
    setLoadingStep: (state, { payload: loadingStep }) => {
      state.loadingStep = loadingStep;
    },
    setUserType: (state, { payload: type }) => {
      state.type = !type ? initialState.type : type;
    },
    setMenuType: (state, { payload: menuType }) => {
      state.menuType = !menuType ? initialState.menuType : menuType;
    },
  },
});

const {
  setUser, setLoadingStep, setUserType, setMenuType,
} = slice.actions;

function filterAuthUser(userResponse) {
  const {
    // https://firebase.google.com/docs/reference/js/firebase.User
    // email,
    // phoneNumber,
    // photoURL,
    uid: id,
    displayName,
  } = userResponse;

  return { id, displayName };
}

export const changeMenu = (type) => (dispatch) => {
  dispatch(setMenuType(type));
};

export const init = () => async (dispatch) => {
  dispatch(setLoadingStep(LoadingSteps.AUTH_LOADING));

  auth.onAuthStateChanged(async (userResponse) => {
    if (userResponse) {
      const response = await userResponse.getIdTokenResult();
      batch(() => {
        dispatch(setUserType(response.claims.auth));
        dispatch(setMenuType(response.claims.auth));
        dispatch(setUser(filterAuthUser(userResponse)));
      });
    } else {
      dispatch(setUser(null));
    }

    dispatch(setLoadingStep(LoadingSteps.AUTH_READY));
  });
};

export const createPassword = (email) => async () => auth
  .sendPasswordResetEmail(email).then(() => Promise.resolve());

export const uploadSpecialistProfilePic = (pic, path) => async () => storage
  .ref(`users/specialist/${path}/profile.jpg`).put(pic).then(() => {
    console.log('upload completo!');
    Promise.resolve();
  })
  .catch((error) => {
    console.log(error.message);
  });

export const uploadTalentProfilePic = (pic, path) => async () => storage
  .ref(`talent/picture/${path}/profile.jpg`).put(pic).then(() => {
    console.log('upload completo!');
    Promise.resolve();
  })
  .catch((error) => {
    console.log(error.message);
  });

export const uploadTalentCV = (cv, path) => async () => storage
  .ref(`talent/cv/${path}/curriculum.pdf`).put(cv).then(() => {
    console.log('upload completo!');
    Promise.resolve();
  })
  .catch((error) => {
    console.log(error.message);
  });

export const login = (email, password) => (dispatch) => auth
  .signInWithEmailAndPassword(email, password)
  .then(async (response) => {
    const userclaim = await auth.currentUser.getIdTokenResult();
    batch(() => {
      dispatch(setUserType(userclaim.claims.auth));
      dispatch(setMenuType(userclaim.claims.auth));
      dispatch(setUser(filterAuthUser(response.user)));
    });

    return Promise.resolve();
  });

export const logout = () => () => auth.signOut();

export const resetPassword = (actionCode, newPassword) => auth.confirmPasswordReset(actionCode, newPassword);

export const verifyResetCode = (actionCode) => auth.verifyPasswordResetCode(actionCode);

export default slice.reducer;
