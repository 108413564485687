import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    color: white;
`;

export const Header = styled.div`
    padding: 8px var(--horizontal-padding);
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr)) 100px 100px;
    text-align: center;
    background-color: #20265D;
`;

export const Cell = styled.div`
    padding: 8px 16px;
    text-align: center;
`;

export const Body = styled.div`
    margin: 0 var(--horizontal-padding);
`;

export const Row = styled.div`
    margin-top: 14px;
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 100px;
    align-items: center;
`;

export const Card = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr)) 100px ;
    background-color: #242448;
    padding: 8px 0;
    border-radius: 12px;
    align-items: center;
    div:first-child {
        text-align: justify;
    }
`;
