import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const StatusBall = styled.div`
  width: 21px;
  height:21px;
  border-radius: 50%;
  background-color: #D7D128;
  box-shadow: 0px 0px 10px #D7D1288A;
  margin-left: 15px;
  margin-top: 12px;
  display: inline-block;
`;

const HelpText = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  padding-bottom: 4px;
  text-align:center;
  color: #425AE0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const HelpDiv = styled.div`
margin-top: 20px;
`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#171836',
    border: '2px solid #000',
    width: '250px',
    height: '270px',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '130px',
  },
}));

export default function HelpModal({ open, close }) {
  const classes = useStyles();
  const handleClickAway = () => {
    close();
  };

  return (
    <div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={close}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Fade in={open}>
            <div className={classes.paper}>
              <HelpDiv>
                <StatusBall />
                {' '}
                <HelpText>Desativado</HelpText>
              </HelpDiv>
              <HelpDiv>
                <StatusBall style={{ backgroundColor: '#28BED7', boxShadow: '0px 0px 10px #48D2C98A' }} />
                {' '}
                <HelpText>Disponível</HelpText>
              </HelpDiv>
              <HelpDiv>
                <StatusBall style={{ backgroundColor: '#1ABC45', boxShadow: '0px 0px 10px #1ABC458A' }} />
                {' '}
                <HelpText>Em seleção</HelpText>
              </HelpDiv>
              <HelpDiv>
                <StatusBall style={{ backgroundColor: '#E63333', boxShadow: '0px 0px 10px #FF00008A' }} />
                {' '}
                <HelpText>Desativado e contratado</HelpText>
              </HelpDiv>
            </div>
          </Fade>
        </ClickAwayListener>
      </Modal>
    </div>
  );
}
