import {
  Accordion,
  Button,
  Checkbox,
  createMuiTheme,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  withStyles,
} from '@material-ui/core';
import styled from 'styled-components';

export const ThemedAccordion = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root': {
      padding: '0px',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
      height: '100%',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '4%',
    },
    '&$expanded': {
      margin: '0px',
    },
    boxShadow: 'none',
  },
  expanded: {},
})(Accordion);

export const TitleRegisterAccordion = styled.h3`
  font-size: "26px";
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

export const ExpandDiv = styled.div`
rows: 4;
-webkit-rows: 4;
-moz-rows: 4;
row-count: 4;
width:100%;
`;

export const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '20%',
    marginRight: '10px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export const SelectDiv = styled.div`
    margin-top: 32px;
    display: flex;
`;

export const ThemedCheckBox = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
    '&$disabled': {
    },
  },
  disabled: { },
  checked: {},
})(Checkbox);

export const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
    width: '20%',
  },
})(TextField);

export const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#425AE0',
        '&$selected': {
          backgroundColor: '#425AE0',
        },
      },
    },
  },
});

export const ThemedFormControlLabel = withStyles({
  root: {
    '& .MuiTypography-root': {
      color: '#FFFFFF',
    },
  },
})(FormControlLabel);

export const ThemedSelect = withStyles({
  root: {
    color: '#ffffff',
  },
  icon: {
    color: '#425AE0',
  },
})(Select);

export const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: '#777777 !important',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
    selected: {},
  },
})(MenuItem);

export const ThemedFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '20%',
  },
})(FormControl);

export const SalaryDiv = styled.div`
  width: 20%;
  margin-right: 5%;
  display:inline-block;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  color: #ffffff;
  align-items: center;
  justify-content: space-between;
`;

export const SliderDiv = styled.div`
    width: 90%;
    margin: auto;
`;

export const AgeDiv = styled.div`
  width: 20%;
  margin-right: 5%;
  display:inline-block;
`;

export const ThemedSlider = withStyles({
  root: {
    '& .MuiSlider-markLabel': {
      color: '#ffffff',
    },
  },
})(Slider);
