import styled from 'styled-components';
import {
  TextField, Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ThemedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '100%',
    height: '45px',
  },
})(TextField);

export const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '239px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

export const FormDiv = styled.div`
    width: 430px;
    max-width: 100%;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: '26px';
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
`;

export const FirstSpacing = styled.div`
    margin-top: 40px;
`;

export const Spacing = styled.div`
    margin-top: 35px;
`;

export const DivButton = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;
