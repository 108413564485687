import auth from 'features/auth';
import talent from 'features/talent';
import requester from 'features/requester';
import vacancy from 'features/vacancy';
import company from 'features/company';
import hardskill from 'features/hardskill';
import softskill from 'features/softskill';
import areaofpractice from 'features/areaofpractice';
import favorite from 'features/favorite';
import kanban from 'features/kanban/redux';

export default {
  auth,
  talent,
  requester,
  vacancy,
  company,
  hardskill,
  softskill,
  areaofpractice,
  favorite,
  kanban,
};
