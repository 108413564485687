import React from 'react';
import {
  ThemedCheckBox, ThemedFormControlLabel, ThemedFormGroup,
} from './styles';

const CheckboxGroupItem = React.memo(({
  item, checked, onChange, disabled,
}) => (
  <ThemedFormControlLabel
    disabled={disabled}
    control={<ThemedCheckBox checked={checked} onChange={onChange} value={item.id} />}
    label={item.name}
  />
));

const CheckboxGroup = ({
  data = [], value = [], onChange, disabled,
}) => (
  <ThemedFormGroup>
    {data.map((item) => (
      <CheckboxGroupItem
        disabled={disabled}
        key={item.id}
        item={item}
        checked={value.includes(item.id)}
        onChange={onChange}
      />
    ))}
  </ThemedFormGroup>
);

export default CheckboxGroup;
