import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Star from '@material-ui/icons/Star';
import StarOutlined from '@material-ui/icons/StarBorderOutlined';
import { IconButton } from '@material-ui/core';
import DefaultAvatar from 'assets/Person.svg';
import { formatToCurrency } from 'utils/numberFormat';

import {
  ListStyled,
  DivHeader,
  StatusBall,
  PictureDiv,
  SpanItemStyled,
  SpanLastItemStyled,
} from './styles';

function FavoriteItem({
  authType, talent, vacancyId, isFavorite, onFavoriteClick,
}) {
  function getStatusStyle() {
    switch (talent?.status) {
      case 'deactivated':
        return {};
      case 'available':
        return { backgroundColor: '#28BED7', boxShadow: '0px 0px 10px #48D2C98A' };
      case 'hired':
        return { backgroundColor: '#E63333', boxShadow: '0px 0px 10px #FF00008A' };
      case 'inSelection':
        return { backgroundColor: '#1ABC45', boxShadow: '0px 0px 10px #1ABC458A' };
      default:
        return {};
    }
  }

  const StarIcon = isFavorite ? Star : StarOutlined;

  return (
    <ListStyled key={talent?.id}>
      <div>
        <DivHeader>
          <StatusBall style={getStatusStyle()} />
          {vacancyId ? (
            <IconButton aria-label="remove favorite">
              <StarIcon
                onClick={() => onFavoriteClick()}
                style={{ fontSize: 35, color: isFavorite ? '#f3b824' : '#ffffff' }}
              />
            </IconButton>
          ) : <></>}
        </DivHeader>
        <PictureDiv src={talent?.pictureLink || DefaultAvatar} />
        <SpanItemStyled>{authType === 'requester' ? talent?.shortId : talent?.name}</SpanItemStyled>
        <SpanItemStyled>{talent?.mainAreaOfPractice?.name || 'Não informado'}</SpanItemStyled>
        <SpanItemStyled>
          {talent?.intendedSalary ? formatToCurrency(talent?.intendedSalary) : 'Não informado'}
        </SpanItemStyled>
        <SpanLastItemStyled>
          <Link
            style={{ color: '#425AE0' }}
            to={{ pathname: `/talents/edit/${talent?.id}`, state: { id: talent?.id } }}
          >
            Ver mais
          </Link>
        </SpanLastItemStyled>
      </div>
    </ListStyled>
  );
}

FavoriteItem.propTypes = {
  authType: PropTypes.string.isRequired,
  talent: PropTypes.shape({
    id: PropTypes.number,
    shortId: PropTypes.string,
    name: PropTypes.string,
    pictureLink: PropTypes.string,
    mainAreaOfPractice: PropTypes.shape({
      name: PropTypes.string,
    }),
    intendedSalary: PropTypes.number,
    status: PropTypes.string,
  }),
  isFavorite: PropTypes.bool,
  onFavoriteClick: PropTypes.func,
};

FavoriteItem.defaultProps = {
  talent: {
    id: undefined,
    shortId: undefined,
    name: '',
    pictureLink: '',
    mainAreaOfPractice: {
      name: '',
    },
    intendedSalary: undefined,
    status: undefined,
  },
  isFavorite: false,
  onFavoriteClick: () => {},
};

export default FavoriteItem;
