import { withStyles, Button as MaterialButton } from '@material-ui/core';

const Button = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '15px',
    letterSpacing: '0px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(MaterialButton);

export default Button;
