import React from 'react';
import { Button, Fade } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import joblerIcon from 'assets/Jobler.svg';
import userAddIcon from 'assets/UserPlus.svg';
import searchIcon from 'assets/Search.svg';
import logoutIcon from 'assets/Logout.svg';
import businessIcon from 'assets/BusinessCenter.svg';
import personIcon from 'assets/Person.svg';
import editIcon from 'assets/Edit.svg';
import favoriteIcon from 'assets/estrela.svg';
import changeMenuIcon from 'assets/Settings.svg';
import vacancyIcon from 'assets/Paste.svg';
import lockIcon from 'assets/Lock.svg';

import {
  Container,
  Active,
  ImgLogo,
  ListMenuIcons,
  useStyles,
} from './styles';

const defaultMenu = [
  {
    to: '/talents',
    alt: 'Ver Talentos',
    img: searchIcon,
    isActive: (route) => route !== '/talents/new' && route.startsWith('/talents'),
  },
  {
    to: '/favorites',
    alt: 'Ver Favoritos',
    img: favoriteIcon,
    isActive: (route) => route.startsWith('/favorites'),
  },
  {
    to: '/talents/new',
    alt: 'Cadastro do Talento',
    img: userAddIcon,
    isActive: (route) => route === '/talents/new',
  },
  {
    to: '/vacancies/company',
    alt: 'Ver Vagas',
    img: vacancyIcon,
    isActive: (route) => route.startsWith('/vacancies') || route.startsWith('/vacancy'),
  },
  {
    to: '/changepassword',
    alt: 'Alterar senha',
    img: lockIcon,
    isActive: (route) => route === '/changepassword',
  },
];

const adminMenu = [
  {
    to: '/listcompany',
    alt: 'Ver Empresas',
    img: businessIcon,
    isActive: (route) => route === '/listcompany' || route === '/company',
  },
  {
    to: '/specialist',
    alt: 'Especialistas',
    img: personIcon,
    isActive: (route) => route === '/specialist' || route === '/specialistregister',
  },
  {
    to: '/skill',
    alt: 'Skills',
    img: editIcon,
    isActive: (route) => route === '/skill',
  },
  {
    to: '/download',
    alt: 'Download de dados',
    img: vacancyIcon,
    isActive: (route) => route === '/download',
  },
];

function SideBar({ authType, handleLogout }) {
  const classes = useStyles();
  const { location: { pathname }, ...history } = useHistory();
  const menuType = adminMenu.some((item) => item.isActive(pathname)) ? 'admin' : 'default';

  function getMenuItems() {
    if (authType === 'admin' && menuType === 'admin') {
      return adminMenu;
    }
    if (authType === 'requester') return defaultMenu.filter((item) => item.to !== '/talents/new');
    return defaultMenu;
  }

  function handleMenu() {
    history.push(menuType === 'admin' ? '/talents' : '/listcompany');
    // setSelectedPage(menuType === 'admin' ? 'vacancyregister' : 'listcompany');
  }

  return (
    <Container>
      <Link to="/">
        <ImgLogo src={joblerIcon} alt="Logo Jobler" />
      </Link>
      <ListMenuIcons>
        {getMenuItems().map(({
          to, img, alt, isActive,
        }) => (
          <Fade key={to} in timeout={{ enter: 1000, exit: 1000 }}>
            <Link to={to}>
              <img
                src={img}
                alt={alt}
              />
              {isActive(pathname) && <Active />}
            </Link>
          </Fade>
        ))}

        {authType === 'admin' && (
          <Fade in timeout={{ enter: 1000, exit: 1000 }}>
            <Button onClick={handleMenu}>
              <img
                className={classes.rootImg}
                src={changeMenuIcon}
                alt="Alterar visão menu"
              />
            </Button>
          </Fade>
        )}
      </ListMenuIcons>
      <Button onClick={handleLogout}>
        <img className={classes.rootImg} src={logoutIcon} alt="Logout" />
      </Button>
    </Container>
  );
}

export default SideBar;
