import styled, { css } from 'styled-components';
import {
  withStyles, Button as MaterialButton, Switch,
} from '@material-ui/core';

import voltarImg from 'assets/Voltar.svg';

const commonTextCss = css`
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
  margin: 0 0 14px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  padding: 14px 0px;
`;

export const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '70px',
    backgroundPosition: 'center',
    marginRight: 14,
  },
})(MaterialButton);

export const Title = styled.h2`
  ${commonTextCss}
  font-size: 26px;
`;

export const AvailableVacancies = styled.h3`
  ${commonTextCss}
  font-size: 21px;
`;

export const Deadline = styled.span`
  ${commonTextCss};
  font-size: 16px;
  color: ${({ isVacancyClosed }) => (isVacancyClosed ? '#A11F0A' : '#425AE0')};
`;

export const Button = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '15px',
    letterSpacing: '0px',
    alignSelf: 'center',
    padding: '6px 14px',
    width: '140px',
    maxWidth: '100%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(MaterialButton);

export const SwitchShowActiveTalents = withStyles({
  root: {
    height: '50px',
    width: '76px',
    '& .MuiSwitch-thumb': {
      border: '2px solid #425AE0',
      borderColor: '#425AE0',
      color: '#242448',
      width: '32px',
      height: '32px',
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#425AE0',
      height: '27px',
      borderRadius: '16px',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#425AE0',
      height: '27px',
      borderRadius: '16px',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: '#425AE0',
    },
  },
  checked: {
    '& .MuiSwitch-thumb': {
      border: '1px solid #1732C8',
      borderColor: '#1732C8',
      color: '#425AE0',
      width: '32px',
      height: '32px',
    },
  },

})(Switch);

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled.div`
  height: 100%;
  padding: 0px var(--horizontal-padding);
  display: flex;
  flex-direction: column;
`;

export const SwitchContainer = styled.div`
  align-self: center;
  text-align: center;
  flex: 1;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  > div {
    margin-left: 28px;
    > button:first-child {
      margin-bottom: 18px;
    }
  }
`;
