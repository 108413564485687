import { createSlice } from '@reduxjs/toolkit';
import { fetch, dropId } from 'entities/softskills';
import axios from 'app/axios';

const initialState = {
  softskillSel: [],
};
export const slice = createSlice({
  name: 'softskill',
  initialState,
  reducers: {
    setSoftSkillSelected: (state, { payload: softskillSelected }) => {
      state.softskillSel = !softskillSelected ? initialState.softskillSel : softskillSelected;
    },
  },
});

const {
  setSoftSkillSelected,
} = slice.actions;

const softskillsEndpoint = '/softskill';

export const fetchSoftSkillsAvailable = () => (dispatch) => dispatch(
  fetch(),
);

export const add = ({ name }) => (dispatch) => axios
  .post(softskillsEndpoint, { name }).then(() => {
    dispatch(fetch());
  });

export const update = ({ id, name }) => (dispatch) => axios
  .patch(`${softskillsEndpoint}/${id}`, { name }).then(() => {
    dispatch(fetch());
  });

export const remove = (id) => (dispatch) => axios.delete(`${softskillsEndpoint}/${id}`).then(() => {
  dispatch(dropId(id));
});

export const selectSoftskill = (softskillSel) => (dispatch) => {
  dispatch(setSoftSkillSelected(softskillSel));
};

const softskillsLevelEndpoint = '/softskill/level';

export const addLevel = ({ level, softskillId }) => async (dispatch) => axios
  .post(softskillsLevelEndpoint, { level, softskillId }).then(() => {
    dispatch(fetch());
  });

export const updateLevel = ({ id, level }) => async (dispatch) => axios
  .patch(`${softskillsLevelEndpoint}/${id}`, { level }).then(() => {
    dispatch(fetch());
  });

export const removeLevel = ({ id }) => async (dispatch) => axios
  .delete(`${softskillsLevelEndpoint}/${id}`).then(() => {
    dispatch(fetch());
  });

export default slice.reducer;
