import * as React from 'react';
import { useEffect } from 'react';
import {
  addGlobalRequestInterceptor,
  addGlobalResponseInterceptor,
  removeGlobalRequestInterceptor,
  removeGlobalResponseInterceptor,
} from 'app/axios';
import LoadingContext from './LoadingContext';

export default function LoadingProvider({ children }) {
  const [loading, setLoading] = React.useState(0);

  useEffect(() => {
    let isSubscribed = true;
    const requestIds = addGlobalRequestInterceptor((config) => {
      if (isSubscribed) {
        setLoading(loading + 1);
      }
      return config;
    });
    const responseIds = addGlobalResponseInterceptor(
      (response) => {
        if (isSubscribed) {
          setLoading(loading - 1);
        }
        return response;
      },
      (error) => {
        if (isSubscribed) {
          setLoading(loading - 1);
        }
        return Promise.reject(error);
      },
    );
    return () => {
      isSubscribed = false;
      removeGlobalRequestInterceptor(requestIds);
      removeGlobalResponseInterceptor(responseIds);
    };
  }, []);

  return (
    <LoadingContext.Provider value={loading > 0}>
      {children}
    </LoadingContext.Provider>
  );
}
