import { withStyles } from '@material-ui/core';
import MaterialPagination from '@material-ui/lab/Pagination';

const Pagination = withStyles({
  root: {
    padding: '18px var(--horizontal-padding) 0',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
    '& .Mui-selected': {
      backgroundColor: '#242448',
    },
    '& .MuiPaginationItem-rounded': {
    },
  },
})(MaterialPagination);

export default Pagination;
