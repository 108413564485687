import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import FilterHardskill from './Filters/FilterHardskill';
import FilterSoftskill from './Filters/FilterSoftskill';
import FilterAreaOfPractice from './Filters/FilterAreaOfPractice';

const DivButton = styled.div`
  display: flex;
  margin-top: 3%;
  margin-left: 10%;
  justify-content: space-evenly;
  padding: 0px 20%;
`;

const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '239px',
    marginRight: '10px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#171836',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal({
  open, close, type, values, onConfirm,
}) {
  const [selection, setSelection] = useState([]);
  const classes = useStyles();

  const handleClick = () => {
    onConfirm(selection);
    close();
  };

  const handleClean = () => {
    onConfirm([]);
    close();
  };

  const handleSelectionChange = ({ target: { value } }) => {
    const id = Number(value);
    if (selection.includes(id)) setSelection(selection.filter((selected) => selected !== id));
    else setSelection([...selection, id]);
  };

  useEffect(() => {
    setSelection(values || []);
  }, [values]);

  let partToRender;
  if (type === 'hardskill') {
    partToRender = <FilterHardskill value={selection} onChange={handleSelectionChange} />;
  } else if (type === 'softskill') {
    partToRender = <FilterSoftskill value={selection} onChange={handleSelectionChange} />;
  } else {
    partToRender = <FilterAreaOfPractice value={selection} onChange={handleSelectionChange} />;
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              style={{
                backgroundColor: '#171836',
                height: '70vh',
                width: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {partToRender}
            </div>
            <DivButton>
              <ThemedButton onClick={handleClean} type="button">
                Limpar
              </ThemedButton>
              <ThemedButton onClick={handleClick} type="button">
                Salvar
              </ThemedButton>
            </DivButton>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
