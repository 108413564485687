import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { fetch as fetchHardskillAreas } from 'entities/hardskillAreas';

import HardskillLabelInlineEdit from 'features/hardskill/HardskillLabelInlineEdit';
import styled from 'styled-components';
import HardskillAreaLabelInlineEdit from './HardskillAreaLabelInlineEdit';
import HardskillForm from './HardskillAreaForm';

import './HardskillAreasList.module.scss';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const TitleRegister = styled.h2`
  font-size: '26px';
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const ThemedAccordion = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root': {
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionDetails-root': {
      margin: '20px',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '9%',
    },
    '&$expanded': {
      border: '1px solid #425AE0',
    },
    boxShadow: 'none',
  },
  expanded: {},
})(Accordion);

export default function HardskillAreasList() {
  const { hardskillAreas } = useSelector((
    { entities },
  ) => ({
    hardskillAreas: entities.hardskillAreas,
  }));
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const [expandedHardskillAreaId, setExpandedHardskillId] = useState(null);

  function handleChangeExpandedHardskillArea(hardskillId) {
    setExpandedHardskillId(hardskillId !== expandedHardskillAreaId ? hardskillId : null);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHardskillAreas()).then(() => {
      setIsInitialLoading(false);
    });
  }, [dispatch]);

  return (
    <Container style={{ marginTop: '26px' }}>
      { isInitialLoading
        ? <CircularProgress />
        : (
          <>
            <HeaderContainer>
              <TitleRegister>
                Hard skill
              </TitleRegister>
              <HardskillForm />
            </HeaderContainer>

            {hardskillAreas.allIds.map((hardskillAreaId) => {
              const hardskillArea = hardskillAreas.byId[hardskillAreaId];
              const isExpanded = expandedHardskillAreaId === hardskillAreaId;
              return (
                <Fragment key={hardskillAreaId}>
                  <ContentContainer>
                    <HardskillAreaLabelInlineEdit
                      hardskillAreaId={hardskillAreaId}
                      isExpanded={isExpanded}
                      handleChangeExpandedHardskillArea={handleChangeExpandedHardskillArea}
                    />
                  </ContentContainer>
                  {
                    isExpanded
                      ? (
                        <ThemedAccordion expanded={isExpanded}>
                          <AccordionDetails>
                            <Grid container spacing={3}>
                              { hardskillArea.hardskills.length > 0 && hardskillArea.hardskills.map((hardskill) => (
                                <Grid item xs={3}>
                                  <HardskillLabelInlineEdit key={hardskill.id} hardskill={hardskill} />
                                </Grid>
                              )) }
                              <Grid item xs={3}>
                                <HardskillLabelInlineEdit hardskillAreaId={hardskillAreaId} />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </ThemedAccordion>
                      )
                      : <></>
                    }

                </Fragment>
              );
            }) }
          </>
        )}
    </Container>
  );
}
