import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { name, version } = require('../../package.json');

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${name}@${version}`,
    environment: process.env.REACT_APP_ENV,
    debug: process.env.NODE_ENV === 'development',
    attachStacktrace: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}
