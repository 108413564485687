export const emptyFilter = {
  areaOfPractice: [],
  level: [],
  hardskill: [],
  softskill: [],
  universityLevel: [],
  region: '',
  maritalStatus: [],
  gender: [],
  pcd: '',
  salary: [1000, 30000],
  age: [18, 65],
};

export const ageMarks = [
  { value: 18, label: '18' },
  { value: 65, label: '65' },
];

export const salaryMarks = [
  { value: 1000, label: 'R$ 1.000' },
  { value: 30000, label: '+R$ 30.000' },
];

export const levelOptions = [
  { id: 'junior', name: 'Júnior' },
  { id: 'senior', name: 'Sênior' },
  { id: 'coordinator', name: 'Coordenador' },
  { id: 'full', name: 'Pleno' },
  { id: 'director', name: 'Diretor' },
  { id: 'specialist', name: 'Especialista' },
  { id: 'manager', name: 'Gerente' },
];

export const maritalStatusOptions = [
  { id: 'single', name: 'Solteiro' },
  { id: 'married', name: 'Casado' },
  { id: 'widow', name: 'Viúvo' },
  { id: 'separated', name: 'Separado' },
  { id: 'divorced', name: 'Divorciado' },
];

export const genderOptions = [
  { id: 'male', name: 'Masculino' },
  { id: 'female', name: 'Feminino' },
  { id: 'transmasc', name: 'Transexual Masculino' },
  { id: 'transfem', name: 'Transexual Feminino' },
  { id: 'notapplicable', name: 'N/A' },
  { id: 'other', name: 'Outro' },
];

export const universityLevelOptions = [
  { id: 'technic', name: 'Técnico' },
  { id: 'undergraduate', name: 'Pós graduação' },
  { id: 'masters', name: 'Mestrado' },
  { id: 'graduate', name: 'Graduação' },
  { id: 'doctorate', name: 'Doutorado' },
  { id: 'incomplete', name: 'Incompleto' },
];

export const pcdOptions = [
  { id: '1', name: 'Sim' },
  { id: '0', name: 'Não' },
  { id: 'indifferent', name: 'Indiferente' },
];
