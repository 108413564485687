import React from 'react';
import PropTypes from 'prop-types';
import ReactKanban from '@lourenci/react-kanban';

import KanbanColumnHeader from '../KanbanColumnHeader';

import '@lourenci/react-kanban/dist/styles.css';
import './overrideStyles.css';

function Board({
  data,
  onCardDragEnd,
  onColumnDragEnd,
  renderCard,
  onColumnRename,
  isRemoving,
  onColumnRemove,
  canDragColumn,
  canRenameColumn,
  canRemoveColumn,
  canDragCard,
  ...rest
}) {
  function renderColumnHeader(props) {
    const isFirstColumn = props.position === 1;
    const isLastColumn = props.position === data.columns.length;
    return (
      <KanbanColumnHeader
        canRemove={!isFirstColumn && !isLastColumn && canRemoveColumn}
        canRename={canRenameColumn}
        onSubmit={onColumnRename}
        onColumnRemove={onColumnRemove}
        column={props}
        isRemoving={isRemoving}
      />
    );
  }

  return (
    <ReactKanban
      onCardDragEnd={onCardDragEnd}
      onColumnDragEnd={onColumnDragEnd}
      renderCard={renderCard}
      renderColumnHeader={renderColumnHeader}
      disableCardDrag={!canDragCard}
      disableColumnDrag={!canDragColumn}
      {...rest}
    >
      {data}
    </ReactKanban>
  );
}

Board.propTypes = {
  data: PropTypes.shape({ columns: PropTypes.arrayOf(PropTypes.shape({})) }),
  onCardDragEnd: PropTypes.func.isRequired,
  onColumnDragEnd: PropTypes.func.isRequired,
  renderCard: PropTypes.func.isRequired,
  onColumnRename: PropTypes.func.isRequired,
  isRemoving: PropTypes.bool.isRequired,
  onColumnRemove: PropTypes.func.isRequired,
  canDragColumn: PropTypes.bool,
  canRenameColumn: PropTypes.bool,
  canRemoveColumn: PropTypes.bool,
  canDragCard: PropTypes.bool,
};

Board.defaultProps = {
  data: { columns: [] },
  canDragColumn: true,
  canRenameColumn: true,
  canRemoveColumn: true,
  canDragCard: true,
};

export default Board;
