import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Formik, Form, Field,
} from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { addLevel, updateLevel, removeLevel } from '.';

const ThemedChip = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#ffffff',
    marginLeft: '5%',
    width: '100%',
    border: '1px solid #425AE0',
    '& .MuiChip-deleteIcon': {
      color: '#425AE0',
    },
    '& .MuiChip-label': {
      width: '100%',
    },
  },
})(Chip);

const ThemedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
  },
})(TextField);

export default function HardskillLabelInlineEdit({
  softskill = null, softskillId = null,
}) {
  const isAddingNew = !softskill;
  const { softskillLevel } = useSelector(({
    entities,
  }) => ({
    softskillLevel: entities.softskill.byId[!isAddingNew ? softskill.softskillId : softskillId],
  }));

  const [isEditing, setIsEditing] = useState(isAddingNew);

  function handleClick() {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  const dispatch = useDispatch();

  function handleDelete() {
    dispatch(removeLevel(softskill))
      .then(() => toast.info('Level da Soft skill removido com sucesso'))
      .catch(() => toast.error('Erro ao remover Level da Soft skill'));
  }

  function handleSubmit(/* values: */{ name }, { resetForm }/* , actions */) {
    if (isAddingNew) {
      dispatch(addLevel({ softskillId: softskillLevel.id, level: name }))
        .then(() => {
          resetForm();
          toast.info('Level de Softskill criado com sucesso');
          setIsEditing(false);
        })
        .catch(() => {
          toast.error('Error ao criar Soft skill');
        });
    } else {
      dispatch(updateLevel({ id: softskill.id, softSkillId: softskillLevel.id, level: name }))
        .then(() => {
          resetForm();
          toast.info('Level da Soft skill atualizado com sucesso');
          setIsEditing(false);
        })
        .catch(() => {
          toast.error('Error ao atualizar Level Soft skill');
        });
    }
  }

  function validateName(value) {
    let validation = null;
    // TODO: i18n('invalid')
    if (!value.length > 0) {
      validation = 'Obrigatório';
    } else if (softskillLevel.softskillLevel.find(({ name }) => name === value)) {
      validation = 'Já existente';
    }
    return validation;
  }

  function renderInlineForm() {
    return (
      <Formik
        initialValues={{
          name: isAddingNew ? '' : softskill.level,
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, dirty }) => (
          <Form autoComplete="off">
            <Field
              name="name"
              type="text"
              autocomplete="off"
              as={ThemedTextField}
              placeholder={`${isAddingNew ? 'Nova' : 'Editar'} softskill`}
              validate={validateName}
              style={{ maxWidth: '100%', width: '78%' }}
            />
            { dirty && errors.name && <div>{errors.name}</div>}
            <Button
              type="submit"
              style={{ color: 'white', padding: '6px 0px' }}
            >
              Salvar
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <ThemedChip
      variant="outlined"
      label={!isEditing && softskill ? softskill.level : renderInlineForm()}
      onClick={handleClick}
      onDelete={!isAddingNew && !isEditing ? handleDelete : null}
    />

  );
}
