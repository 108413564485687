import { createSlice } from '@reduxjs/toolkit';
import api from 'app/axios';
import { merge as MergeVacancy } from 'entities/vacancies';

const initialState = {
  boardData: [],
};

function updateKanbanId(columns) {
  return columns.map((column) => ({
    ...column,
    cards: column.cards.map((card) => ({
      ...card,
      kanbanId: column.id,
    })),
  }));
}

export const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    setBoardData: (state, { payload: boardData }) => {
      state.boardData = updateKanbanId(boardData) || initialState.boardData;
    },
    clearBoard: (state) => { state.boardData = []; },
  },
});

const { setBoardData, clearBoard } = kanbanSlice.actions;

const vacancyKanbanColumnsEndpoint = '/kanban/columns';
const talentsByColumnEndpoint = '/kanban/talents';
const kanbanPlacement = '/kanban';
const vacancyKanbannote = '/vacancy';

export const placeVacancy = (vacancyId, talentId, placement) => async () => {
  api.patch(`${kanbanPlacement}/${vacancyId}/${talentId}/place`, placement);
};

export const closeVacancy = (vacancyId, close) => (dispatch) => api
  .patch(`${vacancyKanbannote}/${vacancyId}/close`, close)
  .then(({ data }) => dispatch(MergeVacancy(data)));

export const updateNote = (vacancyId, kanbanNote) => async (dispatch) => api
  .patch(`${vacancyKanbannote}/${vacancyId}`, kanbanNote)
  .then(({ data }) => {
    dispatch(MergeVacancy(data));
  });

export const fetchBoardData = (vacancyId, params) => async (dispatch) => {
  dispatch(clearBoard());
  const { data: columns } = await api.get(`${vacancyKanbanColumnsEndpoint}/${vacancyId}`);

  const allColumnsRequests = columns.map((column) => api.get(`${talentsByColumnEndpoint}/${column.id}`, { params }));

  const responses = await Promise.all(allColumnsRequests);

  const boardData = columns.map((column, index) => ({ ...column, cards: responses[index].data }));

  dispatch(setBoardData(boardData));
};

export const renameColumn = (columnId, name) => () => api.patch(`/kanban/${columnId}`, {
  name,
});

export const moveColumn = (columnId, newPosition) => () => api.patch(`/kanban/move/${columnId}`, {
  newPosition,
});

export const moveTalent = (vacancyId, talentId, newColumnId) => () => api
  .patch(`/kanban/${vacancyId}/${talentId}/move`, {
    kanbanId: newColumnId,
  });
export const createColumn = (data) => () => api.post('/kanban', data);

export const removeColumn = (columnId) => () => api.delete(`/kanban/${columnId}`);

export const disapproveTalent = (vacancyId, talentId, reason) => () => api
  .patch(`/kanban/${vacancyId}/${talentId}/disapprove`, { reasonToDisapprove: reason });

export const reapproveTalent = (vacancyId, talentId) => () => api
  .patch(`/kanban/${vacancyId}/${talentId}/reapprove`);

export default kanbanSlice.reducer;
