/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tab,
  Paper,
  Tabs,
  Button,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { fetchNextPage } from 'features/vacancy';
import { fetch as fetchHardSkillsAreaAvailable } from 'entities/hardskillAreas';
import { fetchAreaOfPracticeAvailables } from 'features/areaofpractice';
import { fetchSoftSkillsAvailable } from 'features/softskill';
import voltarImg from 'assets/Voltar.svg';
import { useHistory } from 'react-router-dom';
import VacancyRegisterPart1 from './VacancyRegisterPart1';
import VacancyRegisterPart2 from './VacancyRegisterPart2';
import VacancyRegisterPart3 from './VacancyRegisterPart3';

const Container = styled.div`
  padding: 0 var(--horizontal-padding);
`;

const ContainerHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(20),
      float: 'right',
      color: 'white',
    },
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const PaperStatusCompany = withStyles({
  root: {
    background: '#171836',
  },
})(Paper);

const TabsStatusCompany = withStyles({
  root: {
    textTransform: 'none',
    color: 'white',
    fontSize: '18px',
    '&$selected': {
      backgroundColor: 'blue',
    },
  },
})(Tab);

const TabsControlTypeRegister = withStyles({

})(Tabs);

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '70px',
    marginTop: '10px',
  },
})(Button);

function TalentPage() {
  const classes = useStyles();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const {
    vacancyId, pageRegister, authType,
  } = useSelector(
    ({ features, entities }) => ({
      requesterId: features.requester.requesterId,
      requesters: entities.requesters,
      vacancyId: features.vacancy.vacancyCreatedId,
      pageRegister: features.vacancy.pageRegister,
      authType: features.auth.type,
    }),
  );

  useEffect(() => {
    dispatch(fetchAreaOfPracticeAvailables());
    dispatch(fetchSoftSkillsAvailable());
    dispatch(fetchHardSkillsAreaAvailable());
  }, [dispatch, activeTab]);

  const handleChange = (_event, newValue) => {
    if (authType !== 'requester') {
      if (pageRegister !== 0) {
      // TODO: Criar um dialog padronizado definido pelo UI/UX para usar
      // no lugar dos confirms .
        if (confirm('Existem campos a serem salvos, deseja mesmo mudar de página sem salvar?')) {
          dispatch(fetchNextPage(0));
          setActiveTab(newValue);
        }
      } else if (vacancyId !== 0) {
        setActiveTab(newValue);
      } else {
        alert('Impossível mudar de aba enquanto não salvar');
      }
    } else {
      setActiveTab(newValue);
    }
  };

  const handleChildChange = (page) => {
    setActiveTab(page);
  };

  let partToRender;
  if (activeTab === 2) {
    partToRender = <VacancyRegisterPart3 changePage={handleChildChange} />;
  } else if (activeTab === 1) {
    partToRender = <VacancyRegisterPart2 changePage={handleChildChange} />;
  } else {
    partToRender = <VacancyRegisterPart1 changePage={handleChildChange} />;
  }

  return (
    <Container>
      <ContainerHeader>
        <BackButton onClick={history.goBack} />
        <PaperStatusCompany square>
          <TabsControlTypeRegister
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Mudar Etapa da Vaga"
          >
            <TabsStatusCompany
              disableRipple
              style={{ backgroundColor: activeTab === 0 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
              label="Parte 1"
            />
            <TabsStatusCompany
              style={{ backgroundColor: activeTab === 1 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
              label="Parte 2"
            />
            <TabsStatusCompany
              style={{ backgroundColor: activeTab === 2 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
              label="Parte 3"
            />
          </TabsControlTypeRegister>
        </PaperStatusCompany>
        <div style={{ width: 70 }} />
      </ContainerHeader>
      <div className={classes.root}>{ partToRender }</div>
    </Container>
  );
}

export default TalentPage;
