import { withStyles, Tab as MaterialTab } from '@material-ui/core';

const Tab = withStyles({
  root: {
    textTransform: 'none',
    color: 'white',
    fontSize: '18px',
  },
})(MaterialTab);

export default Tab;
