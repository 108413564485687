import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  InputAdornment,
  Paper,
  Tabs,
} from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Pagination from 'components/Pagination';
import Tab from 'components/Tab';
import Button from 'components/Button';
import SearchTextField from 'components/SearchTextField';

import Table from './components/SpecialistTable';

import { fetchSpecialistByParams, updateSpecialistStatus } from '.';
import {
  HeaderContainer, SearchContainer, TabsContainer,
} from './styles';

function SpecialistList() {
  const { specialistItem } = useSelector((
    { entities },
  ) => ({
    specialistItem: entities.specialists,
  }));

  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState(1);
  const [activeTab, setActiveTab] = useState(0);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [page, setPage] = useState(0);
  const [specialists, setSpecialists] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const data = (specialists || []).slice(page * 5, page * 5 + 5).map((item) => specialistItem.byId[item]);

  useEffect(() => {
    dispatch(fetchSpecialistByParams({ isActive: status, name: `%${searchText}%` }))
      .then((specialist) => {
        setSpecialists(specialist);
        if (page + 1 > Math.ceil(specialist.length / 5)) {
          setPage(page - 1);
        }
      });
  }, [dispatch, status, hasUpdated]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setStatus(newValue === 1 ? 0 : 1);
    setPage(0);
  };

  const handleSearchTextChange = (value) => {
    dispatch(fetchSpecialistByParams({ isActive: status, name: `%${value}%` }))
      .then((specialist) => {
        setSpecialists(specialist);
        setPage(0);
      });
  };

  async function handleSpecialistStatusChange(event) {
    if (event.target.checked === true) {
      await dispatch(updateSpecialistStatus(event.target.name, 'activate')).then(() => {
        toast.info('Especialista ativado');
      }).catch(() => {
        toast.error('Não foi possível ativar este especialista');
      });
    } else {
      await dispatch(updateSpecialistStatus(event.target.name, 'deactivate')).then(() => {
        toast.info('Especialista desativado');
      }).catch(() => {
        toast.error('Não foi possível desativar este especialista');
      });
    }

    setHasUpdated(hasUpdated === false);
  }

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  const handleAddSpecialistPage = () => {
    history.push('/specialistregister', { id: 0 });
  };
  return (
    <>
      <HeaderContainer>
        <div style={{ flex: 1 }}>
          <Button
            onClick={handleAddSpecialistPage}
            style={{
              width: '239px',
              heigth: '44px',
            }}
          >
            Adicionar especialista
          </Button>
        </div>
        <TabsContainer>

          <Paper square style={{ background: '#171836' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab
                disableRipple
                style={{ backgroundColor: activeTab === 0 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
                label="Ativadas"
              />
              <Tab
                style={{ backgroundColor: activeTab === 1 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
                label="Desativadas"
              />
            </Tabs>
          </Paper>
        </TabsContainer>
        <div style={{ flex: 1 }} />

      </HeaderContainer>
      <SearchContainer>
        <SearchTextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onDebounceValue={handleSearchTextChange}
          InputProps={{
            endAdornment: <InputAdornment position="start"><FaSearch size={20} /></InputAdornment>,
          }}
        />
      </SearchContainer>

      <Table data={data} showingActive={status} onSpecialistStatusChange={handleSpecialistStatusChange} />

      <Pagination
        count={specialists !== undefined ? Math.ceil(specialists.length / 5) : 1}
        page={page + 1}
        onChange={handlePageChange}
        shape="rounded"
      />
    </>
  );
}

export default SpecialistList;
