/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Button,
  MenuItem,
  createMuiTheme,
  MuiThemeProvider,
  Grid,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemText,
  RadioGroup,
} from '@material-ui/core';
import FormatGender from 'components/FormatGender';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import MainAreaOfPractice from 'features/talent/technique/TalentTechniqueOccupationAreaPrimary';
import SoftSkillLevelRadio from 'features/talent/technique/SoftSkillLevelRadioButton';
import FormikTalentCheckbox from 'features/talent/technique/FormikTalentCheckbox';
import TextNumberFormatCustom from 'components/TextFieldNumber';
import { toast } from 'react-toastify';
import NumberFormatCustom from 'components/TextFieldCurrencyMask';
import { updateVacancyByPart, fetchNextPage } from '.';

const TitleRegister = styled.h2`
  font-size: "26px";
  font-weight: bolder;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const TitleRegisterAccordion = styled.h3`
  font-size: "26px";
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  button {
    :first-child {
      margin-right: 8px
    }
  }
`;

const ContainerTextFields = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ContainerIdiomas = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
`;

const EmptyContainer10 = styled.div`
  width: 10%;
`;

const ButtonBox = styled.div`
  display: flex;
  margin-top: 3%;
  justify-content: space-evenly;
  padding: 0px 25%;
`;

const ThemedFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '20%',
  },
})(FormControl);

const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: '#777777 !important',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
    selected: {},
  },
})(MenuItem);

const ThemedSelect = withStyles({
  root: {
    color: '#ffffff',
  },
})(Select);

const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '197px',
    height: '44px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
  },
})(TextField);

const ThemedAccordion = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root': {
      padding: '0px',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
      height: '100%',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '9%',
    },
    '&$expanded': {
      margin: '0px',
    },
    boxShadow: 'none',
  },
  expanded: {},
})(Accordion);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#171836',
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  typeChoosed: {
    opacity: 1,
  },
}));

function equalMoreThan(ref, msg) {
  return this.test({
    name: 'equalMoreThan',
    exclusive: false,
    message: msg || 'erro',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value >= this.resolve(ref);
    },
  });
}

yup.addMethod(yup.number, 'equalMoreThan', equalMoreThan);

const vacancyPart2Schema = yup.object({
  biggestChallenge: yup.string(),
  nextSteps: yup.string(),
  technicalKnowledge: yup.string(),
  expertise: yup.string(),
  hiringFormats: yup.array().min(1, 'Escolha pelo menos um Modelo de Contratação'),
  mainAreaOfPractice: yup.string().required('Necessário informar a área principal de atuação'),
  areasOfPractice: yup.array(),
  languages: yup.array(),
  gender: yup.string().required('Necessário escolher o sexo para a vaga, se não houver, escolha a opção N/A'),
  benefits: yup.array().min(1, 'Escolha pelo menos um Benefício'),
  benefitsInfo: yup.string(),
  hardskills: yup.array().min(1, 'Escolha pelo menos uma Hardskill'),
  softskills: yup.array(),
  levels: yup.array().min(1, 'Escolha pelo menos um Level'),
  universityRanks: yup.string(),
  universityLevels: yup.array().min(1, 'Escolha pelo menos um tipo de Formação'),
  minSalary: yup.number().min(1, 'O Salário deve ser maior que 0')
    .required('Por favor, preencha o range inicial do salário'),
  maxSalary: yup.number().equalMoreThan(yup.ref('minSalary'), 'Salário máximo deve ser maior ou igual ao mínimo'),
  /* maxSalary: yup.number().moreThan(yup.ref('minSalary'), 'O salário final deve ser maior que o inicial')
    .required('Por favor, preencha o range final do salário'), */
  minAge: yup.number().min(18, 'A idade mínima deve ser 18 anos'),
  maxAge: yup.number().min(18, 'A idade mínima deve ser 18 anos')
    .equalMoreThan(yup.ref('minAge'), 'A idade final deve ser maior que a inicial'),
  outsider: yup.string(),
  PCD: yup.string(),
  PCDInfo: yup.string().nullable(),
  languageTypeOne: yup.string(),
  languageLevelOne: yup.string(),
  languageTypeTwo: yup.string(),
  languageLevelTwo: yup.string(),
  languageTypeThree: yup.string(),
  languageLevelThree: yup.string(),
  languageTypeFour: yup.string(),
  languageLevelFour: yup.string(),
  acceptsOtherLocation: yup.boolean(),
});

const vacancyPart2InitialValue = {
  biggestChallenge: '',
  nextSteps: '',
  technicalKnowledge: '',
  expertise: '',
  benefitsInfo: '',
  outsider: '',
  PCD: 'indifferent',
  PCDInfo: '',
  gender: 'N/A',
  hiringFormats: [],
  benefits: [],
  minAge: 18,
  maxAge: 18,
  minSalary: 0,
  maxSalary: 0,
  mainAreaOfPractice: '',
  areasOfPractice: [],
  languages: [],
  hardskills: [],
  softskills: [],
  levels: [],
  universityRanks: [],
  acceptsOtherLocation: false,
  universityLevels: [],
  languageTypeOne: 'ND',
  languageLevelOne: 'ND',
  languageTypeTwo: 'ND',
  languageLevelTwo: 'ND',
  languageTypeThree: 'ND',
  languageLevelThree: 'ND',
  languageTypeFour: 'ND',
  languageLevelFour: 'ND',
};

const universityRank = [
  { id: 'a', name: 'Rank A' },
  { id: 'b', name: 'Rank B' },
  { id: 'c', name: 'Rank C' },
];

const optionsGender = [
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Feminino', label: 'Feminino' },
  { value: 'Transexual Masculino', label: 'Transexual Masculino' },
  { value: 'Transexual Feminino', label: 'Transexual Feminino' },
  { value: 'N/A', label: 'N/A' },
  { value: 'Outro', label: 'Outro' },
];

const benefits = [
  { id: 'VR', name: 'VR' },
  { id: 'lifeInsurance', name: 'Seguro de Vida' },
  { id: 'healthInsurance', name: 'Plano de saúde' },
  { id: 'other', name: 'Outros' },
];

const universityLevel = [
  { id: 'technic', name: 'Técnico' },
  { id: 'undergraduate', name: 'Sem graduação' },
  { id: 'masters', name: 'Mestrado' },
  { id: 'graduate', name: 'Graduação' },
  { id: 'doctorate', name: 'Doutorado' },
  { id: 'incomplete', name: 'Incompleto' },
];

const hiringFormat = [
  { id: 'clt', name: 'CLT' },
  { id: 'freelancer', name: 'Freelancer' },
  { id: 'pj', name: 'PJ' },
  { id: 'other', name: 'Outro' },
];

const yesNoPCD = [
  { id: 'PCD', name: 'Sim' },
  { id: 'notPCD', name: 'Não' },
  { id: 'indifferent', name: 'Indiferente' },
];

const talentLevel = [
  { id: 'junior', name: 'Júnior' },
  { id: 'senior', name: 'Sênior' },
  { id: 'coordinator', name: 'Coordenador' },
  { id: 'full', name: 'Pleno' },
  { id: 'director', name: 'Diretor' },
  { id: 'specialist', name: 'Especialista' },
  { id: 'manager', name: 'Gerente' },
];

export default function Talenttechniqueform({ changePage }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [type, setType] = useState('primary');
  const [primaryColor, setPrimaryColor] = useState('#0e1958');
  const [secondaryColor, setSecondaryColor] = useState('#171836');
  const [changes, setChanges] = useState(false);
  // adicionar dispatch quando houver integração com redux const dispatch = useDispatch();
  const {
    areaOfPractice, softSkill, hardskillByArea, vacancyId, pageRegister, vacancyAdded, authType,
  } = useSelector(
    ({ entities, features }) => ({
      areaOfPractice: entities.areaofpractice,
      softSkill: entities.softskill,
      hardskillByArea: entities.hardskillAreas,
      talentRegister: entities.talents,
      vacancyAdded: entities.vacancies,
      vacancyId: features.vacancy.vacancyCreatedId,
      pageRegister: features.vacancy.pageRegister,
      authType: features.auth.type,
    }),
  );

  const [disableField, setDisableField] = useState(authType === 'requester');

  useEffect(() => {
  }, [changes]);

  useEffect(() => {
    if (authType === 'requester') {
      setDisableField(true);
    }
  }, [authType, disableField]);

  const handleTypeChange = (typeChoosed) => {
    if (typeChoosed === 'primary') {
      setPrimaryColor('#0e1958');
      setSecondaryColor('#171836');
    } else {
      setPrimaryColor('#171836');
      setSecondaryColor('#0e1958');
    }
    setType(typeChoosed);
  };

  const handleBackPage = () => {
    if (pageRegister === 0) {
      changePage(0);
      // TODO: Criar um dialog padronizado definido pelo UI/UX para usar
      // no lugar dos confirms
    } else if (confirm('Existem informações não salvas, deseja voltar para pagina anterior?')) {
      changePage(0);
    }
  };

  const getInitialValues = () => {
    const vacancy = vacancyAdded?.byId?.[vacancyId];
    if (!vacancy) return vacancyPart2InitialValue;
    return {
      outsider: vacancy.acceptsOtherLocation === 0 ? 'Não' : 'Sim',
      expertise: vacancy.expertise,
      nextSteps: vacancy.nextSteps,
      benefits: vacancy.benefits.map((item) => item.benefit.toString()),
      biggestChallenge: vacancy.biggestChallenge,
      technicalKnowledge: vacancy.technicalKnowledge,
      benefitsInfo: vacancy.benefitsInfo,
      minSalary: vacancy.minSalary,
      maxSalary: vacancy.maxSalary,
      minAge: vacancy.minAge ? vacancy.minAge : 18,
      maxAge: vacancy.maxAge ? vacancy.maxAge : 18,
      gender: (vacancy.gender ? FormatGender(vacancy.gender) : 'N/A'),
      PCD: vacancy.PCD ? vacancy.PCD : 'indifferent',
      PCDInfo: vacancy.PCDInfo,
      mainAreaOfPractice: vacancy.mainAreaOfPractice
        ? vacancy.mainAreaOfPractice.id.toString() : '',
      areasOfPractice: vacancy.areasOfPractice.map((item) => item.id),
      hardskills: vacancy.hardskills.map((item) => item.id),
      softskills: vacancy.softskills.map((item) => String(item.id)),
      levels: vacancy.levels.map((item) => item.level.toString()),
      universityRanks: vacancy.universityRanks.map((item) => item.rank.toString()),
      universityLevels: vacancy.universityLevels.map((item) => item.level.toString()),
      hiringFormats: vacancy.hiringFormats.map((item) => item.format.toString()),
      languages: [],
      languageLevelOne: vacancy.languages[0]?.level || 'ND',
      languageTypeOne: vacancy.languages[0]?.language || 'ND',
      languageLevelTwo: vacancy.languages[1]?.level || 'ND',
      languageTypeTwo: vacancy.languages[1]?.language || 'ND',
      languageLevelThree: vacancy.languages[2]?.level || 'ND',
      languageTypeThree: vacancy.languages[2]?.language || 'ND',
      languageLevelFour: vacancy.languages[3]?.level || 'ND',
      languageTypeFour: vacancy.languages[3]?.language || 'ND',
    };
  };

  const handleVacancyRegister = (values, { setFieldError, setSubmitting }) => {
    const gender = FormatGender(values.gender);
    const postObject = JSON.parse(JSON.stringify(values));
    postObject.gender = gender;
    postObject.acceptsOtherLocation = values.outsider === 'Sim';
    // const PCD = values.PCD;
    postObject.PCD = values.PCD;

    if (
      values.languageTypeOne !== 'ND'
      && values.languageTypeOne !== ''
      && values.languageLevelOne !== 'ND'
      && values.languageLevelOne !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeOne,
        level: values.languageLevelOne,
      });
    }
    if (
      values.languageTypeTwo !== 'ND'
      && values.languageTypeTwo !== ''
      && values.languageLevelTwo !== 'ND'
      && values.languageLevelTwo !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeTwo,
        level: values.languageLevelTwo,
      });
    }
    if (
      values.languageTypeThree !== 'ND'
      && values.languageTypeThree !== ''
      && values.languageLevelThree !== 'ND'
      && values.languageLevelThree !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeThree,
        level: values.languageLevelThree,
      });
    }
    if (
      values.languageTypeFour !== 'ND'
      && values.languageTypeFour !== ''
      && values.languageLevelFour !== 'ND'
      && values.languageLevelFour !== ''
    ) {
      postObject.languages.push({
        language: values.languageTypeFour,
        level: values.languageLevelFour,
      });
    }

    delete postObject.languageTypeOne;
    delete postObject.languageLevelOne;
    delete postObject.languageTypeTwo;
    delete postObject.languageLevelTwo;
    delete postObject.languageTypeThree;
    delete postObject.languageLevelThree;
    delete postObject.languageTypeFour;
    delete postObject.languageLevelFour;
    delete postObject.outsider;

    dispatch(updateVacancyByPart(vacancyId, postObject, '2'))
      .then(() => {
        toast.info('Vaga atualizada com sucesso');
        dispatch(fetchNextPage(0));
        changePage(2);
        setSubmitting(false);
      })
      .catch((rejection) => {
        const { data } = rejection;
        if (data) {
          if (data.data) {
            setFieldError(data.data, data.message);
          }
        }
        toast.error('Erro ao atualizar Vaga');
        setSubmitting(false);
      });
  };
  return (
    <div style={{ marginTop: '4%' }}>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={vacancyPart2Schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleVacancyRegister}
      >
        {(formik) => (
          <form key="id" onSubmit={formik.handleSubmit}>
            <Container className={classes.root}>
              <ContainerTextFields>
                <ThemedTextField
                  id="biggestChallenge-input"
                  data-testid="biggestChallenge-input"
                  label="Qual o maior desafio da posição?"
                  name="biggestChallenge"
                  style={{ width: '40%' }}
                  autoComplete="off"
                  multiline
                  rows={3}
                  variant="outlined"
                  helperText={
                    formik.errors.biggestChallenge
                      ? formik.errors.biggestChallenge
                      : ''
                  }
                  error={
                    formik.errors.biggestChallenge
                    && Boolean(formik.errors.biggestChallenge)
                  }
                  onBlur={(e) => formik.setFieldValue('biggestChallenge', e.currentTarget.value)}
                  defaultValue={formik.values.biggestChallenge}
                  InputProps={{
                    readOnly: disableField,
                  }}
                />
                <ThemedTextField
                  id="nextSteps-text"
                  data-testid="nextSteps-input"
                  label="Quais são os próximos passos na carreira?"
                  name="nextSteps"
                  style={{ width: '40%' }}
                  autoComplete="off"
                  multiline
                  rows={3}
                  variant="outlined"
                  helperText={
                    formik.errors.nextSteps
                      ? formik.errors.nextSteps
                      : ''
                  }
                  error={
                    formik.errors.nextSteps
                    && Boolean(formik.errors.nextSteps)
                  }
                  onBlur={(e) => formik.setFieldValue(
                    'nextSteps',
                    e.currentTarget.value,
                  )}
                  defaultValue={formik.values.nextSteps}
                  InputProps={{
                    readOnly: disableField,
                  }}
                />
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Área de Atuação</TitleRegister>
                <ContainerButtons>
                  <ThemedButton
                    style={{ backgroundColor: primaryColor }}
                    onClick={() => handleTypeChange('primary')}
                  >
                    Principal
                  </ThemedButton>
                  <ThemedButton
                    style={{ backgroundColor: secondaryColor }}
                    onClick={() => handleTypeChange('secondary')}
                  >
                    Secundária
                  </ThemedButton>
                </ContainerButtons>
              </ContainerTextFields>
              {formik.errors.mainAreaOfPractice && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.mainAreaOfPractice}
                </div>
              )}
              {
                areaOfPractice.allIds ? areaOfPractice.allIds.map((skill) => {
                  const areaOfPrcticeItem = areaOfPractice.byId[skill];
                  return (
                    <ThemedAccordion key={areaOfPrcticeItem.id}>
                      <AccordionSummary
                        expandIcon={(
                          <ExpandMoreIcon
                            style={{ color: 'white', marginLeft: '50px' }}
                          />
                    )}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <TitleRegisterAccordion>
                          {areaOfPrcticeItem.name}
                        </TitleRegisterAccordion>
                      </AccordionSummary>
                      <AccordionDetails style={{ height: '250px' }}>
                        {type === 'primary' ? (
                          <RadioGroup
                            aria-label="mainAreaOfPractice"
                            name="mainAreaOfPractice"
                            error={
                        formik.errors.mainAreaOfPractice
                        && Boolean(formik.errors.mainAreaOfPractice)
                      }
                            onChange={formik.handleChange('mainAreaOfPractice')}
                            value={formik.values.mainAreaOfPractice}
                          >
                            <Grid container>
                              {areaOfPrcticeItem ? (
                                areaOfPrcticeItem.areasOfPractice.map((item) => (
                                  <Grid item xs={3}>
                                    <MainAreaOfPractice disabled={disableField} key={item.id} areas={item} />
                                  </Grid>
                                ))
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </RadioGroup>
                        ) : (
                          <Grid container>
                            {areaOfPrcticeItem ? (
                              areaOfPrcticeItem.areasOfPractice.map((linha) => (
                                <Grid item xs={3}>
                                  <FormikTalentCheckbox
                                    key={linha.id}
                                    name={linha.id}
                                    label={linha.name}
                                    yupfield="areasOfPractice"
                                    disabled={disableField}
                                    fieldValue={formik.values.areasOfPractice}
                                  />
                                </Grid>
                              ))
                            ) : (
                              <></>
                            )}
                          </Grid>
                        )}
                      </AccordionDetails>
                    </ThemedAccordion>
                  );
                })
                  : <></>
              }
              <ContainerTextFields>
                <TitleRegister>Hard Skill</TitleRegister>
              </ContainerTextFields>
              {formik.errors.hardskills && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.hardskills}
                </div>
              )}
              {
                hardskillByArea.allIds ? hardskillByArea.allIds.map((skill) => {
                  const hardSkillItem = hardskillByArea.byId[skill];
                  return (
                    <ThemedAccordion key={hardSkillItem.id}>
                      <AccordionSummary
                        expandIcon={(
                          <ExpandMoreIcon
                            style={{ color: 'white', marginLeft: '50px' }}
                          />
                    )}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <TitleRegisterAccordion>
                          {hardSkillItem.name}
                        </TitleRegisterAccordion>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          {hardSkillItem ? (
                            hardSkillItem.hardskills.map((linha) => (
                              <Grid item xs={3}>
                                <FormikTalentCheckbox
                                  key={linha.id}
                                  name={linha.id}
                                  label={linha.name}
                                  yupfield="hardskills"
                                  disabled={disableField}
                                  fieldValue={formik.values.hardskills}
                                />
                              </Grid>
                            ))
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </ThemedAccordion>
                  );
                })
                  : <></>
              }
              <ThemedTextField
                id="technicalKnowledge-text"
                data-testid="technicalKnowledge-input"
                multiline
                fullWidth
                label="Quais são os conhecimentos técnicos necessários?"
                name="technicalKnowledge"
                autoComplete="off"
                variant="outlined"
                rows={7}
                helperText={
                  formik.errors.technicalKnowledge ? formik.errors.technicalKnowledge : ''
                }
                error={
                  formik.errors.technicalKnowledge && Boolean(formik.errors.technicalKnowledge)
                }
                onBlur={(e) => formik.setFieldValue('technicalKnowledge', e.currentTarget.value)}
                defaultValue={formik.values.technicalKnowledge}
                InputProps={{
                  readOnly: disableField,
                }}
              />
              <ContainerTextFields>
                <TitleRegister>Soft Skill</TitleRegister>
              </ContainerTextFields>
              {formik.errors.softskills && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.softskills}
                </div>
              )}
              <Grid container>
                {
                  softSkill.allIds ? softSkill.allIds.map((skill) => {
                    const softskillItem = softSkill.byId[skill];
                    return (
                      <Grid key={softskillItem.id} item xs={4}>
                        <ThemedAccordion>
                          <AccordionSummary
                            expandIcon={(
                              <ExpandMoreIcon
                                style={{ color: 'white', alignItems: 'right' }}
                              />
                      )}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <TitleRegisterAccordion>
                              {softskillItem.name}
                            </TitleRegisterAccordion>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RadioGroup
                              aria-label="softskill"
                              name={softskillItem.name}
                              onChange={(e) => {
                                softskillItem.softskillLevel.map((item) => {
                                  if (
                                    formik.values.softskills.includes(
                                      item.id.toString(),
                                    )
                                  ) {
                                    const idx = formik.values.softskills.indexOf(
                                      item.id.toString(),
                                    );
                                    formik.values.softskills.splice(idx, 1);
                                  }
                                  return true;
                                });
                                formik.values.softskills.push(e.currentTarget.value);
                                setChanges(changes !== true);
                              }}
                              value={formik.values.softskills.length > 0
                                ? (softskillItem.softskillLevel.filter(
                                  (element) => formik.values.softskills.includes(element.id.toString()),
                                )[0]
                                  ? softskillItem.softskillLevel
                                    .filter(
                                      (element) => formik.values.softskills.includes(element.id.toString()),
                                    )[0].id.toString()
                                  : ' ')
                                : ' '}
                            >
                              {softskillItem ? (
                                softskillItem.softskillLevel.map((item) => (
                                  <SoftSkillLevelRadio disabled={disableField} key={item.id} areas={item} />
                                ))
                              ) : (
                                <></>
                              )}
                            </RadioGroup>
                          </AccordionDetails>
                        </ThemedAccordion>
                      </Grid>
                    );
                  }) : <></>
                }
              </Grid>
              <ThemedTextField
                id="expertise-text"
                data-testid="expertise-input"
                multiline
                fullWidth
                label="Quais competências não podem faltar para o profissional?"
                name="expertise"
                autoComplete="off"
                variant="outlined"
                rows={7}
                helperText={
                  formik.errors.expertise ? formik.errors.expertise : ''
                }
                error={
                  formik.errors.expertise && Boolean(formik.errors.expertise)
                }
                onBlur={(e) => formik.setFieldValue('expertise', e.currentTarget.value)}
                defaultValue={formik.values.expertise}
                InputProps={{
                  readOnly: disableField,
                }}
              />
              <ContainerTextFields>
                <TitleRegister>Nível</TitleRegister>
              </ContainerTextFields>
              {formik.errors.levels && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.levels}
                </div>
              )}
              <ContainerTextFields>
                <Grid container>
                  {talentLevel ? (
                    talentLevel.map((linha) => (
                      <Grid item xs={3}>
                        <FormikTalentCheckbox
                          key={linha.id}
                          name={linha.id}
                          label={linha.name}
                          yupfield="levels"
                          disabled={disableField}
                          fieldValue={formik.values.levels}
                        />
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Modelo de contratação</TitleRegister>
              </ContainerTextFields>
              {formik.errors.hiringFormats && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.hiringFormats}
                </div>
              )}
              <ContainerTextFields>
                <Grid container>
                  {hiringFormat ? (
                    hiringFormat.map((linha) => (
                      <Grid item xs={3}>
                        <FormikTalentCheckbox
                          key={linha.id}
                          name={linha.id}
                          label={linha.name}
                          yupfield="hiringFormats"
                          disabled={disableField}
                          fieldValue={formik.values.hiringFormats}
                        />
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Formação</TitleRegister>
              </ContainerTextFields>
              {formik.errors.universityLevels && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.universityLevels}
                </div>
              )}
              <ContainerTextFields>
                <Grid container>
                  {universityLevel ? (
                    universityLevel.map((linha) => (
                      <Grid item xs={3}>
                        <FormikTalentCheckbox
                          key={linha.id}
                          name={linha.id}
                          label={linha.name}
                          yupfield="universityLevels"
                          disabled={disableField}
                          fieldValue={formik.values.universityLevels}
                        />
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Universidade</TitleRegister>
              </ContainerTextFields>
              {formik.errors.universityRanks && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.universityRanks}
                </div>
              )}
              <ContainerTextFields>
                <Grid container>
                  {universityRank ? (
                    universityRank.map((linha) => (
                      <Grid item xs={3}>
                        <FormikTalentCheckbox
                          key={linha.id}
                          name={linha.id}
                          label={linha.name}
                          yupfield="universityRanks"
                          disabled={disableField}
                          fieldValue={formik.values.universityRanks}
                        />
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Idiomas</TitleRegister>
              </ContainerTextFields>
              <ContainerTextFields>
                <ContainerIdiomas>
                  <MuiThemeProvider theme={MuiListThem}>
                    <ThemedTextField
                      id="languageTypeOne"
                      data-testid="languageTypeOne"
                      label="Idioma"
                      name="languageTypeOne"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      onChange={formik.handleChange('languageTypeOne')}
                      value={formik.values.languageTypeOne}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Português">
                        Português
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Inglês">Inglês</ThemedMenuItem>
                      <ThemedMenuItem value="Espanhol">Espanhol</ThemedMenuItem>
                      <ThemedMenuItem value="Francês">Francês</ThemedMenuItem>
                    </ThemedTextField>
                    <ThemedTextField
                      id="languageLevelOne"
                      data-testid="languageLevelOne"
                      label="Nível"
                      name="languageLevelOne"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      value={formik.values.languageLevelOne}
                      onChange={formik.handleChange('languageLevelOne')}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Fluente">Fluente</ThemedMenuItem>
                      <ThemedMenuItem value="Avancado">Avançado</ThemedMenuItem>
                      <ThemedMenuItem value="Intermediário">
                        Intermediário
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Iniciante">
                        Iniciante
                      </ThemedMenuItem>
                    </ThemedTextField>
                  </MuiThemeProvider>
                </ContainerIdiomas>
                <EmptyContainer10 />
                <ContainerIdiomas>
                  <MuiThemeProvider theme={MuiListThem}>
                    <ThemedTextField
                      id="languageTypeTwo"
                      data-testid="languageTypeTwo"
                      label="Idioma"
                      name="languageTypeTwo"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      onChange={formik.handleChange('languageTypeTwo')}
                      value={formik.values.languageTypeTwo}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Português">
                        Português
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Inglês">Inglês</ThemedMenuItem>
                      <ThemedMenuItem value="Espanhol">Espanhol</ThemedMenuItem>
                      <ThemedMenuItem value="Francês">Francês</ThemedMenuItem>
                    </ThemedTextField>
                    <ThemedTextField
                      id="languageLevelTwo"
                      data-testid="languageLevelTwo"
                      label="Nível"
                      name="languageLevelTwo"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      onChange={formik.handleChange('languageLevelTwo')}
                      value={formik.values.languageLevelTwo}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Fluente">Fluente</ThemedMenuItem>
                      <ThemedMenuItem value="Avancado">Avançado</ThemedMenuItem>
                      <ThemedMenuItem value="Intermediário">
                        Intermediário
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Iniciante">
                        Iniciante
                      </ThemedMenuItem>
                    </ThemedTextField>
                  </MuiThemeProvider>
                </ContainerIdiomas>
              </ContainerTextFields>
              <ContainerTextFields>
                <ContainerIdiomas>
                  <MuiThemeProvider theme={MuiListThem}>
                    <ThemedTextField
                      id="languageTypeThree"
                      data-testid="languageTypeThree"
                      label="Idioma"
                      name="languageTypeThree"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      onChange={formik.handleChange('languageTypeThree')}
                      value={formik.values.languageTypeThree}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Português">
                        Português
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Inglês">Inglês</ThemedMenuItem>
                      <ThemedMenuItem value="Espanhol">Espanhol</ThemedMenuItem>
                      <ThemedMenuItem value="Francês">Francês</ThemedMenuItem>
                    </ThemedTextField>
                    <ThemedTextField
                      id="languageLevelThree"
                      data-testid="languageLevelThree"
                      label="Nível"
                      name="languageLevelThree"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      onChange={formik.handleChange('languageLevelThree')}
                      value={formik.values.languageLevelThree}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Fluente">Fluente</ThemedMenuItem>
                      <ThemedMenuItem value="Avancado">Avançado</ThemedMenuItem>
                      <ThemedMenuItem value="Intermediário">
                        Intermediário
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Iniciante">
                        Iniciante
                      </ThemedMenuItem>
                    </ThemedTextField>
                  </MuiThemeProvider>
                </ContainerIdiomas>
                <EmptyContainer10 />
                <ContainerIdiomas>
                  <MuiThemeProvider theme={MuiListThem}>
                    <ThemedTextField
                      id="languageTypeFour"
                      data-testid="languageTypeFour"
                      label="Idioma"
                      name="languageTypeFour"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      onChange={formik.handleChange('languageTypeFour')}
                      value={formik.values.languageTypeFour}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Português">
                        Português
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Inglês">Inglês</ThemedMenuItem>
                      <ThemedMenuItem value="Espanhol">Espanhol</ThemedMenuItem>
                      <ThemedMenuItem value="Francês">Francês</ThemedMenuItem>
                    </ThemedTextField>
                    <ThemedTextField
                      id="languageLevelFour"
                      data-testid="languageLevelFour"
                      label="Nível"
                      name="languageLevelFour"
                      style={{ width: '45%' }}
                      autoComplete="off"
                      variant="outlined"
                      onChange={formik.handleChange('languageLevelFour')}
                      value={formik.values.languageLevelFour}
                      select
                      InputProps={{
                        readOnly: disableField,
                      }}
                    >
                      <ThemedMenuItem value="ND">Não definido</ThemedMenuItem>
                      <ThemedMenuItem value="Fluente">Fluente</ThemedMenuItem>
                      <ThemedMenuItem value="Avancado">Avançado</ThemedMenuItem>
                      <ThemedMenuItem value="Intermediário">
                        Intermediário
                      </ThemedMenuItem>
                      <ThemedMenuItem value="Iniciante">
                        Iniciante
                      </ThemedMenuItem>
                    </ThemedTextField>
                  </MuiThemeProvider>
                </ContainerIdiomas>
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Salário</TitleRegister>
              </ContainerTextFields>
              <ContainerTextFields>
                <ThemedTextField
                  id="minSalary-text"
                  data-testid="minSalary-input"
                  label="Salário inicial"
                  name="minSalary"
                  style={{ width: '462px' }}
                  autoComplete="off"
                  variant="outlined"
                  helperText={
                    formik.errors.minSalary
                      ? formik.errors.minSalary
                      : ''
                  }
                  error={
                    formik.errors.minSalary
                    && Boolean(formik.errors.minSalary)
                  }
                  onBlur={(e) => formik.setFieldValue('minSalary',
                    Number(e.currentTarget.value.replace('R$ ', '').replace('.', '').replace(',', '.')))}
                  defaultValue={formik.values.minSalary}
                  InputProps={{
                    readOnly: disableField,
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <span style={{ color: 'white', marginTop: '2%' }}>
                  Até
                </span>
                <ThemedTextField
                  id="maxSalary-text"
                  data-testid="maxSalary-input"
                  label="Salário final"
                  name="maxSalary"
                  style={{ width: '462px' }}
                  autoComplete="off"
                  variant="outlined"
                  helperText={
                    formik.errors.maxSalary
                      ? formik.errors.maxSalary
                      : ''
                  }
                  error={
                    formik.errors.maxSalary
                    && Boolean(formik.errors.maxSalary)
                  }
                  onBlur={(e) => formik.setFieldValue(
                    'maxSalary',
                    Number(e.currentTarget.value.replace('R$ ', '').replace('.', '').replace(',', '.')),
                  )}
                  defaultValue={formik.values.maxSalary}
                  InputProps={{
                    readOnly: disableField,
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Benefícios</TitleRegister>
              </ContainerTextFields>
              {formik.errors.benefits && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.benefits}
                </div>
              )}
              <ContainerTextFields>
                <Grid container style={{ width: '60%' }}>
                  {benefits ? (
                    benefits.map((linha) => (
                      <Grid item xs={2}>
                        <FormikTalentCheckbox
                          key={linha.id}
                          name={linha.id}
                          label={linha.name}
                          yupfield="benefits"
                          disabled={disableField}
                          fieldValue={formik.values.benefits}
                        />
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
                <ThemedTextField
                  id="benefitsInfo-text"
                  data-testid="benefitsInfo-input"
                  label="Mais informações sobre os benefícios"
                  name="benefitsInfo"
                  style={{ width: '462px' }}
                  autoComplete="off"
                  multiline
                  rows={3}
                  variant="outlined"
                  helperText={
                    formik.errors.benefitsInfo
                      ? formik.errors.benefitsInfo
                      : ''
                  }
                  error={
                    formik.errors.benefitsInfo
                    && Boolean(formik.errors.benefitsInfo)
                  }
                  onBlur={(e) => formik.setFieldValue('benefitsInfo', e.currentTarget.value)}
                  defaultValue={formik.values.benefitsInfo}
                  InputProps={{
                    readOnly: disableField,
                  }}
                />
              </ContainerTextFields>
              <ContainerTextFields>
                <TitleRegister>Informações sobre o candidato</TitleRegister>
              </ContainerTextFields>
              <ContainerTextFields>
                <MuiThemeProvider theme={MuiListThem}>
                  <ThemedTextField
                    id="gender-text"
                    data-testid="gender-text"
                    label="Sexo"
                    name="gender"
                    style={{ width: '462px' }}
                    autoComplete="off"
                    variant="outlined"
                    onChange={formik.handleChange('gender')}
                    value={formik.values.gender}
                    helperText={
                        formik.errors.gender
                          ? formik.errors.gender
                          : ''
                      }
                    error={
                        formik.errors.gender
                        && Boolean(formik.errors.gender)
                      }
                    select
                    InputProps={{
                      readOnly: disableField,
                    }}
                  >
                    {optionsGender.map((option) => (
                      <ThemedMenuItem key={option.value} value={option.value}>
                        {option.label}
                      </ThemedMenuItem>
                    ))}
                  </ThemedTextField>
                  <ThemedTextField
                    id="outsider-text"
                    data-testid="outsider-text"
                    label="Aceita candidato de outra cidade?"
                    name="outsider"
                    style={{ width: '462px' }}
                    autoComplete="off"
                    variant="outlined"
                    onChange={formik.handleChange('outsider')}
                    value={formik.values.outsider}
                    select
                    InputProps={{
                      readOnly: disableField,
                    }}
                  >
                    <ThemedMenuItem value="Sim">Sim</ThemedMenuItem>
                    <ThemedMenuItem value="Não">Não</ThemedMenuItem>
                  </ThemedTextField>
                </MuiThemeProvider>
              </ContainerTextFields>
              <ContainerTextFields>
                <ThemedTextField
                  id="minAge-text"
                  data-testid="minAge-input"
                  label="Idade inicial"
                  name="minAge"
                  style={{ width: '462px' }}
                  autoComplete="off"
                  variant="outlined"
                  helperText={
                    formik.errors.minAge
                      ? formik.errors.minAge
                      : ''
                  }
                  error={
                    formik.errors.minAge
                    && Boolean(formik.errors.minAge)
                  }
                  onBlur={(e) => formik.setFieldValue('minAge', e.currentTarget.value)}
                  defaultValue={formik.values.minAge}
                  InputProps={{
                    readOnly: disableField,
                    inputComponent: TextNumberFormatCustom,
                  }}
                />
                <span style={{ color: 'white', marginTop: '2%' }}>
                  Até
                </span>
                <ThemedTextField
                  id="maxAge-text"
                  data-testid="maxAge-input"
                  label="Idade final"
                  name="maxAge"
                  style={{ width: '462px' }}
                  autoComplete="off"
                  variant="outlined"
                  helperText={
                    formik.errors.maxAge
                      ? formik.errors.maxAge
                      : ''
                  }
                  error={
                    formik.errors.maxAge
                    && Boolean(formik.errors.maxAge)
                  }
                  onBlur={(e) => formik.setFieldValue(
                    'maxAge',
                    e.currentTarget.value,
                  )}
                  defaultValue={formik.values.maxAge}
                  InputProps={{
                    readOnly: disableField,
                    inputComponent: TextNumberFormatCustom,
                  }}
                />
              </ContainerTextFields>
              {formik.errors.PCD && (
                <div
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    fontFamily: "'Roboto', sans-serif'",
                  }}
                >
                  {formik.errors.PCD}
                </div>
              )}
              <ContainerTextFields>
                <MuiThemeProvider theme={MuiListThem}>
                  <ThemedFormControl variant="outlined" style={{ marginRight: '5%' }}>
                    <InputLabel
                      id="pcd-mult-checkbox"
                    >
                      Talento PCD
                    </InputLabel>
                    <ThemedSelect
                      labelId="pcd-mult-checkbox"
                      id="demo-mutiple-checkbox"
                      name="PCD"
                      error={
                      formik.errors.PCD
                      && Boolean(formik.errors.PCD)
                    }
                      style={{ width: '462px' }}
                      label="PCD"
                      variant="outlined"
                      value={formik.values.PCD || ''}
                      onChange={formik.handleChange('PCD')}
                      MenuProps={MenuProps}
                      disabled={disableField}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {yesNoPCD.map((name) => (
                        <ThemedMenuItem key={name.id} value={name.id}>
                          <ListItemText primary={name.name} />
                        </ThemedMenuItem>
                      ))}
                    </ThemedSelect>
                  </ThemedFormControl>
                </MuiThemeProvider>
                <ThemedTextField
                  id="PCDInfo-text"
                  data-testid="PCDInfo-input"
                  label="Mais informações PCD"
                  name="PCDInfo"
                  style={{ width: '462px' }}
                  autoComplete="off"
                  variant="outlined"
                  helperText={
                    formik.errors.PCDInfo
                      ? formik.errors.PCDInfo
                      : ''
                  }
                  error={
                    formik.errors.PCDInfo
                    && Boolean(formik.errors.PCDInfo)
                  }
                  onBlur={(e) => formik.setFieldValue('PCDInfo', e.currentTarget.value)}
                  defaultValue={formik.values.PCDInfo}
                  InputProps={{
                    readOnly: disableField,
                  }}
                />
              </ContainerTextFields>
              {
                  authType !== 'requester'
                    ? (
                      <Grid style={{ marginTop: '70px' }} item xs={12}>
                        <ButtonBox>
                          <ThemedButton
                            disabled={formik.isSubmitting}
                            type="button"
                            onClick={handleBackPage}
                          >
                            Voltar
                          </ThemedButton>
                          <ThemedButton disabled={formik.isSubmitting} type="submit">Próximo</ThemedButton>
                        </ButtonBox>
                      </Grid>
                    )
                    : <></>
                }

            </Container>
          </form>
        )}
      </Formik>
    </div>
  );
}
