import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.aside`
  background-color: #0A0B3A;
  width: 70px;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;

  a, button {
    position: relative;
    text-align: center;
    padding: 8px 16px;
    margin: 8px 0px;
    
    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }

    img {
      position: relative;
      z-index: 1;
    }
  }
`;

export const Active = styled.div`
  background-color: #425AE0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin-left: 6px;
  border-bottom-left-radius: 20px;
`;

export const ListMenuIcons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
`;

export const ImgLogo = styled.img`
  /* margin-top: 10%;
  margin-left: 25%; */
`;

export const ListMenuFirstIconItem = styled.div`
  /* margin-top: 50%; */
`;

export const ListMenuLastIconItem = styled.div`
  /* position:fixed;
  bottom: 0;
  margin-bottom: 1%; */
`;

export const ListMenuIconsItem = styled.div`
  /* margin-top: 30%; */
`;

export const useStyles = makeStyles(() => ({
  root: {
    // height: '50px',
    // width: '76px',
    // backgroundColor: '#425AE0',
    // borderRadius: '0px 0px 0px 20px',
    // boxShadow: '0px 3px 6px #00000047',
  },
  rootImg: {
    // marginLeft: '35%',
    // marginTop: '10%',
    cursor: 'pointer',
  },
  rootImgSelected: {
    // marginLeft: '35%',
    // marginTop: '14%',
    cursor: 'pointer',
  },
}));
