import React from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { createPassword as forgotPassword } from 'features/auth';

import { toast } from 'react-toastify';
import logo from 'assets/Jobler.svg';
import fundo from 'assets/J.svg';

import {
  Background,
  DivForm,
  LabelLogin,
  TitleLogin,
  DivLogin,
  LoginTextField,
  DivButton,
  ButtonLogin,
  DivLabel,
  LogoImage,
  SubtitleLogin,
} from './styles';

const BgImage = styled.div`
    background: url(${fundo});
    height: 50%;
    width: 100%;
    position: fixed;
    background-size: cover;
    
`;

const forgotValidationschema = yup.object({
  email: yup.string().email('Insira um email válido').required('Insira um email'),
});

const forgotInitialValue = {
  email: '',
};

function Forgot() {
  const dispatch = useDispatch();
  const history = useHistory();
  function handleForgot(values, { setFieldError, setSubmitting }) {
    setSubmitting(true);
    const { email } = values;
    dispatch(forgotPassword(email)).then(() => {
      toast.info('Email com instruções para redefinir senha enviado');
      setSubmitting(false);
      history.goBack();
    }).catch((rejection) => {
      const { code, message } = rejection;
      if (code === 'auth/user-not-found') {
        setFieldError('email', 'Email inválido');
        setSubmitting(false);
      } else {
        setFieldError('email', code === 'auth/invalid-email' ? 'Email inválido' : message);
        setSubmitting(false);
      }
    });
  }
  return (
    <Background>
      <BgImage />
      <DivForm>
        <Formik
          validationSchema={forgotValidationschema}
          onSubmit={handleForgot}
          initialValues={forgotInitialValue}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <LabelLogin>
                <TitleLogin>Esqueceu a senha?</TitleLogin>
                <SubtitleLogin>Insira o seu email abaixo para redefinir sua senha </SubtitleLogin>
              </LabelLogin>
              <DivLogin>
                <LoginTextField
                  id="email-text"
                  label="Email"
                  name="email"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  variant="outlined"
                  helperText={formik.errors.email ? formik.errors.email : ''}
                  error={formik.errors.email && Boolean(formik.errors.email)}
                />
              </DivLogin>
              <DivButton>
                <ButtonLogin type="submit" disabled={formik.isSubmitting}>Enviar email</ButtonLogin>
              </DivButton>
            </form>
          )}
        </Formik>
      </DivForm>
      <DivLabel>
        <LogoImage src={logo} alt="Jobler" />
      </DivLabel>

    </Background>
  );
}

export default Forgot;
