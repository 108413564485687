import { withStyles, Switch as MaterialSwitch } from '@material-ui/core';

const Switch = withStyles({
  root: {
    height: '50px',
    width: '76px',
    '& .MuiSwitch-thumb': {
      border: '2px solid #425AE0',
      borderColor: '#425AE0',
      color: '#242448',
      width: '32px',
      height: '32px',
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#425AE0',
      height: '27px',
      borderRadius: '16px',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#425AE0',
      height: '27px',
      borderRadius: '16px',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: '#425AE0',
    },
  },
  checked: {
    '& .MuiSwitch-thumb': {
      border: '1px solid #1732C8',
      borderColor: '#1732C8',
      color: '#425AE0',
      width: '32px',
      height: '32px',
    },
  },

})(MaterialSwitch);

export default Switch;
