import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
  const isAdmin = useSelector((state) => state.features.auth.type === 'admin');

  return (
    <Route {...rest} render={(props) => (isAdmin ? <Component {...props} /> : <Redirect to="/" />)} />
  );
};

export default AdminRoute;
