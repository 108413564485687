import React, { useState } from 'react';
import { Modal, Backdrop, Button } from '@material-ui/core';

import { useStyles, ThemedTextField } from './styles';

function DisapproveModal({ onConfirm, onClose, visible }) {
  const [reason, setReason] = useState('');
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={visible}
      onClose={onClose}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 className={classes.font}>Por qual motivo deseja reprovar o talento dessa vaga?</h2>
        <ThemedTextField
          id="reason-text"
          data-testid="reason-input"
          multiline
          fullWidth
          label="Feedback sobre talento"
          name="reason"
          autoComplete="off"
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows={7}
        />
        <div>
          <Button
            classes={{ root: classes.button }}
            onClick={() => onConfirm(reason)}
          >
            Reprovar
          </Button>
          <Button
            classes={{ root: classes.button }}
            onClick={onClose}
          >
            Voltar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DisapproveModal;
