import React, { useEffect, useState } from 'react';
import {
  Paper,
  Tabs,
} from '@material-ui/core';
import styled from 'styled-components';

import Tab from 'components/Tab';

import HardSkill from '../hardskillArea/HardskillAreasList';
import SoftSkill from '../softskill/SoftskillAreaList';

const ContainerHeader = styled.div`
  display: flex;
  justify-content: center;
`;

function SkillsMenu() {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <ContainerHeader>
        <Paper square style={{ backgroundColor: '#171836' }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Mudar Tipo de Cadastro"
          >
            <Tab
              style={{ backgroundColor: activeTab === 0 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
              disableRipple
              label="Hard Skill"
            />
            <Tab
              style={{ backgroundColor: activeTab === 1 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
              label="Soft Skill"
            />
          </Tabs>
        </Paper>
      </ContainerHeader>
      { activeTab === 0 ? <HardSkill /> : <SoftSkill /> }
    </>
  );
}

export default SkillsMenu;
