import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControlLabel,
} from '@material-ui/core';
import { Formik, FieldArray } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ModalPlacement from 'components/PlacementModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  FormContainer, ContainerHeader, ButtonContainer, LabelContainer,
  ContainerTitulo, TitleRegister, BackButton, ThemedButtonAddCompany,
  ThemedTextField, ThemedCheckBox, Container,
} from './styles';
import { closeVacancy } from './redux';

const closeVacancySchema = yup.object().shape({
  feedback: yup.string().when('reasonsToClose', {
    is: (value) => value.includes('other'),
    then: yup.string().required('É preciso preencher o feedback quando "outros" for um dos motivos.'),
  }),
  reasonsToClose: yup.array().of(yup.string()).min(1, 'Selecione pelo menos um motivo'),
});

const closeVacancyInitialValue = {
  feedback: '',
  reasonsToClose: [],
};

const closeReasons = [
  { id: 'filled', name: 'Vaga preenchida' },
  { id: 'expired', name: 'Vaga expirada' },
  { id: 'canceled', name: 'Vaga cancelada' },
  { id: 'other', name: 'Outros' },
];

function CloseVacancy() {
  const history = useHistory();
  const { id: vacancyId } = useParams();
  const dispatch = useDispatch();
  const vacancies = useSelector((state) => state.entities.vacancies);

  useEffect(() => {
    if (!vacancies.byId[vacancyId]) history.replace(`/vacancies/${vacancyId}/kanban`);
    // eslint-disable-next-line
  }, [vacancyId]);

  const handleCloseVacancy = (values) => {
    dispatch(closeVacancy(vacancyId, values))
      .then(() => {
        toast.info('Vaga fechada com sucesso');
        history.goBack();
      })
      .catch(() => {
        toast.error('Erro ao fechar vaga');
      });
  };

  return (
    <Container>
      <ModalPlacement open={false} />
      <ContainerHeader>
        <BackButton onClick={history.goBack} />
        <ContainerTitulo style={{ marginTop: '1%' }}>
          <TitleRegister>
            {vacancies.byId[vacancyId] ? vacancies.byId[vacancyId].name : ''}
          </TitleRegister>
        </ContainerTitulo>
      </ContainerHeader>
      <Formik
        onSubmit={handleCloseVacancy}
        validationSchema={closeVacancySchema}
        initialValues={closeVacancyInitialValue}
        validateOnChange={false}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <LabelContainer>
              <TitleRegister>
                Motivo do Fechamento
              </TitleRegister>
            </LabelContainer>
            <FormContainer>
              <FieldArray
                name="reasonsToClose"
                render={(arrayHelpers) => (
                  <div>
                    {closeReasons ? (
                      closeReasons.map((linha) => (
                        <div>
                          <FormControlLabel
                            value={linha.id}
                            style={{ color: 'white' }}
                            control={(
                              <ThemedCheckBox
                                checked={formik.values.reasonsToClose.includes(
                                  linha.id,
                                )}
                                onChange={(e) => {
                                  formik.setFieldError('reasonsToClose', undefined);
                                  if (e.target.checked) arrayHelpers.push(linha.id);
                                  else {
                                    const idx = formik.values.reasonsToClose.indexOf(
                                      linha.id,
                                    );
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                              />
                                    )}
                            label={linha.name}
                            labelPlacement="end"
                          />
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              />
              <FormHelperText style={{ textAlign: 'center', color: '#f44336' }}>
                {formik.errors.reasonsToClose}
              </FormHelperText>
            </FormContainer>
            <FormContainer>
              <ThemedTextField
                id="feedback"
                data-testid="feedback-input"
                label="Feedback sobre a vaga"
                name="feedback"
                value={formik.values.feedback}
                autoComplete="off"
                variant="outlined"
                onChange={formik.handleChange('feedback')}
                helperText={formik.errors.feedback ? formik.errors.feedback : ''}
                error={formik.errors.feedback && Boolean(formik.errors.feedback)}
                multiline
                rows={7}
              />
            </FormContainer>
            <ButtonContainer>
              <ThemedButtonAddCompany type="submit">
                Fechar Vaga
              </ThemedButtonAddCompany>
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </Container>
  );
}

export default CloseVacancy;
