/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  InputLabel,
  MenuItem,
  createMuiTheme,
  MuiThemeProvider,
  FormControl,
  Select,
  ListItemText,
  Avatar,
  FormHelperText,
  Slider,
  makeStyles,
} from '@material-ui/core';
// import DateFnsUtils from '@date-io/date-fns';
// import pt from 'date-fns/locale/pt-BR';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { storage } from 'app/authConfig';
import { Formik } from 'formik';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';
import * as yup from 'yup';
import TextFieldMask from 'components/TextFieldMaks';
import formatGender from 'components/FormatGender';
import formatExpertise from 'components/FormatExpertise';
import voltarImg from 'assets/Voltar.svg';
import uploadImg from 'assets/upload.svg';
import DefaultAvatar from 'assets/Person.svg';
import { toast } from 'react-toastify';
import moment from 'moment';
import { createPassword, uploadSpecialistProfilePic } from 'features/auth';
import { addNewSpecialist, fetchSpecialistById, updateSpecialistData } from '.';

const ComponentTheme = styled.div`
  background: #171836;
`;

const BackButtonDiv = styled.div`
    top:3.125%;
    left: 9%;
    height:6.64%;
    width:100%;
    position: fixed;
`;

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '100%',
    width: '6%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
})(Button);

const FormDiv = styled.div`
    position: relative;
    top: 5.72%;
    left: 34.38%;
    width:32.35%;
    height:90%;
`;

const PicDiv = styled.div`
    top: 4%;
  `;
const NameDiv = styled.div`

  padding-top: 8%;
`;

const EmailDiv = styled.div`

  padding-top: 35px;
`;

const PhoneDiv = styled.div`

  padding-top: 35px;
`;

const GenderDiv = styled.div`
  width:43.21%;
  padding-top: 35px;
  float: left;
  `;

const DateDiv = styled.div`
  width: 50%;
  padding-top: 35px;
  float: right;
`;

/* const PictureDiv = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 2px solid #425AE0;
  display: block;
  margin-left: auto;
  margin-right: auto;
`; */

const InputPic = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const ImgLabel = styled.img`
  display: block;
  margin-left: 60%;
  cursor: pointer;
`;

const ThemedAvatar = withStyles({
  root: {
    width: '110px',
    height: '110px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})(Avatar);

const ThemedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '100%',
  },
})(TextField);

const ThemedFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '100%',
    height: '45px',
  },
})(FormControl);

const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#425AE0',
        '&$selected': {
          backgroundColor: '#425AE0',
        },
      },
    },
  },
});

const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: 'black',
    },
    '&:hover': {
      backgroundColor: '#171836',
    },
  },
})(MenuItem);

const ThemedSelect = withStyles({
  root: {
    color: '#ffffff',
  },
})(Select);

const DivButton = styled.div`
  display: flex;
  margin-top: 45px;
  justify-content: center;
`;

const ThemedButtonAddSpecialist = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '239px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const RaisedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '100px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const useStyles = makeStyles(() => ({
  icon: {
    color: '#425AE0',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const specialistRegisterValidationschema = yup.object({
  name: yup.string().required('Por favor, preencha o nome do especialista'),
  email: yup.string().email('Por favor, preencha um e-mail válido').required('Por favor, preencha um e-mail válido'),
  phoneNumber: yup.string().required('Por favor, preencha um telefone'),
  birthday: yup.string().required('Por favor, preencha uma data'),
  gender: yup.string().required('Por favor, insira o gênero'),
  expertise: yup.array(),
});

const specialistRegisterInitialValue = {
  name: '',
  email: '',
  phoneNumber: '',
  birthday: '',
  gender: '',
  expertise: [],
};

const talentExpertise = [
  { id: 'tech', name: 'Tecnologia' },
  { id: 'digital', name: 'Digital' },
  { id: 'sales', name: 'Vendas' },
];

const options = [
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Feminino', label: 'Feminino' },
  { value: 'Transexual Masculino', label: 'Transexual Masculino' },
  { value: 'Transexual Feminino', label: 'Transexual Feminino' },
  { value: 'N/A', label: 'N/A' },
  { value: 'Outro', label: 'Outro' },
];
function SpecialistRegister({ location }) {
  const isAdd = location.state !== undefined ? location.state.id : 0;
  const { specialistInfo } = useSelector(({ entities }) => ({
    specialistInfo: entities.specialists,
  }
  ));

  // const [buttonText, setButtonText] = useState('Adicionar Especialista');
  const buttonText = `${isAdd !== 0 ? 'Atualizar' : 'Adicionar'} Especialista`;

  const [profilePic, setProfilePic] = useState();
  const [profileUpURL, setProfileUpURL] = useState();
  const [profileURL, setProfileURL] = useState();
  // const [pictureLink, setPictureLink] = useState();

  const [cropperOpen, setCropperOpen] = useState(false);
  const [zoom, setZoom] = useState(1.2);
  const [picEditor, setPicEditor] = useState();
  const [stepPic, setStepPic] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isAdd !== 0) {
      // setButtonText('Atualizar Especialista');
      dispatch(fetchSpecialistById(isAdd));
      setProfileURL(profileUpURL);
    }
  }, [dispatch, isAdd, profileUpURL]);

  function handleRegisterSpecialist(values, { setFieldError, setSubmitting }) {
    const submit = async () => {
      setSubmitting(true);
      const { name } = values;
      const { phoneNumber } = values;
      const { email } = values;
      const gender = formatGender(values.gender);
      const { birthday } = values;
      const expertise = [];
      if (values.expertise) {
        values.expertise.map((item) => (expertise.push(formatExpertise(item))));
      }
      const path = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      const dataInsert = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        gender,
        birthday: values.birthday,
        expertise,
      };
      if (profilePic || profileURL) {
        if (isAdd !== 0) {
          if (profilePic) {
            dispatch(uploadSpecialistProfilePic(profilePic, path)).then(async () => storage
              .ref(`users/specialist/${path}/profile.jpg`)
              .getDownloadURL().then((picLink) => dispatch(updateSpecialistData(
                isAdd, name, phoneNumber, email, gender, birthday, expertise, picLink,
              ))))
              .then(() => {
                setSubmitting(false);
                toast.info('Especialista atualizado com sucesso');
                history.goBack();
              })
              .catch(() => {
                setSubmitting(false);
                toast.error('Erro ao atualizar especialista');
              });
          } else {
            dispatch(updateSpecialistData(
              isAdd, name, phoneNumber, email, gender, birthday, expertise, profileURL,
            ))
              .then(() => {
                setSubmitting(false);
                toast.info('Especialista atualizado com sucesso');
                history.goBack();
              })
              .catch(() => {
                setSubmitting(false);
                toast.error('Erro ao atualizar especialista');
              });
          }
        } else {
          dispatch(uploadSpecialistProfilePic(profilePic, path)).then(async () => storage
            .ref(`users/specialist/${path}/profile.jpg`)
            .getDownloadURL().then((pictureLink) => dispatch(addNewSpecialist({ ...dataInsert, pictureLink }))
              .then(() => {
                dispatch(createPassword(dataInsert.email));
                setSubmitting(false);
                toast.info('Especialista criado com sucesso');
                history.goBack();
              }).catch((rejection) => {
                setSubmitting(false);
                const { data } = rejection;
                if (data.data) {
                  setFieldError(data.data, data.message);
                } else {
                  toast.error('Erro ao adicionar especialista');
                }
              })));
        }
      } else if (isAdd !== 0) {
        dispatch(updateSpecialistData(
          isAdd, name, phoneNumber, email, gender, birthday, expertise,
        ))
          .then(() => {
            setSubmitting(false);
            toast.info('Especialista atualizado com sucesso');
            history.goBack();
          })
          .catch(() => {
            setSubmitting(false);
            toast.error('Erro ao atualizar especialista');
          });
      } else {
        dispatch(addNewSpecialist({ ...dataInsert }))
          .then(() => {
            dispatch(createPassword(dataInsert.email));
            setSubmitting(false);
            toast.info('Especialista criado com sucesso');
            history.goBack();
          }).catch((rejection) => {
            setSubmitting(false);
            const { data } = rejection;
            if (data.data) {
              setFieldError(data.data, data.message);
            } else {
              toast.error('Erro ao adicionar especialista');
            }
          });
      }
    };
    submit();
  }

  function chooseFile(event) {
    setStepPic(URL.createObjectURL(event.target.files[0]));
    setCropperOpen(true);
  }

  const setEditorRef = (editor) => setPicEditor(editor);

  function handleCancel() {
    setCropperOpen(false);
  }

  function handleZoomSlider(event, value) {
    setZoom(value);
  }

  function handleSave() {
    if (picEditor) {
      const canvasScaled = picEditor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      canvasScaled.toBlob((blob) => {
        console.log(blob);
        setProfilePic(blob);
      });
      setCropperOpen(false);
      setProfileURL(croppedImg);
    }
  }

  return (
    <ComponentTheme>
      <BackButtonDiv>
        <BackButton onClick={history.goBack} />
      </BackButtonDiv>
      {cropperOpen && (
      <div
        className="cropper-wrapper"
        style={{
          position: 'absolute',
          top: '10%',
          left: '31%',
          width: '40%',
          height: '70%',
          background: 'rgba(200,200,200,.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '1',
        }}
      >
        <AvatarEditor
          ref={setEditorRef}
          image={stepPic}
          width={200}
          height={200}
          border={50}
          borderRadius={100}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={zoom}
          rotate={0}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label
            style={{
              fontSize: 12,
              marginRight: 10,
              paddingBottom: 22,
              fontWeight: 600,
            }}
          >
            Zoom
          </label>
          <Slider
            min={1}
            max={10}
            step={0.1}
            value={zoom}
            onChange={handleZoomSlider}
            style={{ width: 200 }}
          />
        </div>
        <div>
          <RaisedButton
            label="CANCEL"
            style={{
              marginRight: '20px',
            }}
            onClick={handleCancel}
          >
            Cancelar
          </RaisedButton>
          <RaisedButton
            label="SAVE"
            onClick={handleSave}
          >
            Salvar
          </RaisedButton>
        </div>
      </div>
      )}
      <FormDiv>
        <Formik
          initialValues={specialistRegisterInitialValue}
          validationSchema={specialistRegisterValidationschema}
          onSubmit={handleRegisterSpecialist}
          validateOnChange={false}
        >
          {(formik) => {
            if (isAdd !== 0) {
              if (specialistInfo !== undefined) {
                const specialistAtt = specialistInfo.byId[isAdd];
                if (specialistAtt !== undefined) {
                  if (specialistAtt.specialistAccount.pictureLink) {
                    setProfileUpURL(specialistAtt.specialistAccount.pictureLink);
                  }
                  formik.initialValues.name = specialistAtt.name;
                  formik.initialValues.phoneNumber = specialistAtt.phoneNumber;
                  if (specialistAtt.specialistAccount.expertise) {
                    const expertise = [];
                    specialistAtt.specialistAccount.expertise.map((item) => (expertise.push(formatExpertise(item.expertise))));
                    formik.initialValues.expertise = expertise;
                  }
                  formik.initialValues.birthday = specialistAtt.specialistAccount.birthday
                    ? moment(specialistAtt.specialistAccount.birthday).format('YYYY-MM-DD')
                    : '';
                  formik.initialValues.gender = formatGender(specialistAtt.specialistAccount.gender);
                  // Using values to not allowing changes in textfield
                  formik.values.email = specialistAtt.email;
                }
              }
            } else {
              specialistRegisterInitialValue.email = '';
              specialistRegisterInitialValue.name = '';
              specialistRegisterInitialValue.birthday = '';
              specialistRegisterInitialValue.phoneNumber = '';
              specialistRegisterInitialValue.gender = '';
              specialistRegisterInitialValue.expertise = [];
            }
            return (
              <form onSubmit={formik.handleSubmit}>
                <PicDiv>
                  <label htmlFor="file-upload">
                    <ThemedAvatar
                      style={{
                        cursor: 'pointer',
                      }}
                      src={profileURL || DefaultAvatar}
                      size={200}
                    />
                  </label>
                  <InputPic
                    id="file-upload"
                    type="file"
                    alt=""
                    // src={uploadImg}
                    accept="image/png, image/jpeg"
                    onChange={(event) => chooseFile(event)}
                  />
                  <label htmlFor="file-upload">
                    <ImgLabel src={uploadImg} alt="" />
                  </label>
                </PicDiv>
                <NameDiv>
                  <ThemedTextField
                    id="name"
                    data-testid="name-input"
                    label="Nome"
                    name="name"
                    autoComplete="off"
                    variant="outlined"
                    helperText={formik.errors.name ? formik.errors.name : ''}
                    error={formik.errors.name && Boolean(formik.errors.name)}
                    onChange={(e) => {
                      formik.setFieldValue('name', e.target.value); formik.setFieldError('name', undefined);
                    }}
                    value={formik.values.name}
                  />
                </NameDiv>
                <EmailDiv>
                  <ThemedTextField
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.setFieldValue('email', e.target.value);
                      formik.setFieldError('email', undefined);
                    }}
                    variant="outlined"
                    helperText={formik.errors.email ? formik.errors.email : ''}
                    error={formik.errors.email && Boolean(formik.errors.email)}
                  />
                </EmailDiv>
                <PhoneDiv>
                  <ThemedTextField
                    id="phoneNumber-text"
                    data-testid="phoneNumber-input"
                    label="Telefone"
                    name="phoneNumber"
                    autoComplete="off"
                    variant="outlined"
                    helperText={
                        formik.errors.phoneNumber
                          ? formik.errors.phoneNumber
                          : ''
                      }
                    error={
                        formik.errors.phoneNumber
                        && Boolean(formik.errors.phoneNumber)
                      }
                    onChange={(e) => {
                      formik.setFieldValue('phoneNumber', e.target.value);
                      formik.setFieldError('phoneNumber', undefined);
                    }}
                    value={formik.values.phoneNumber}
                    InputProps={{
                      inputComponent: TextFieldMask,
                    }}
                  />
                </PhoneDiv>
                <GenderDiv>
                  <MuiThemeProvider theme={MuiListThem}>
                    <ThemedFormControl
                      variant="outlined"
                    >
                      <InputLabel id="labelgender">Sexo</InputLabel>
                      <ThemedSelect
                        id="gender"
                        name="gender"
                        labelId="labelgender"
                        value={formik.values.gender}
                        MenuProps={MenuProps}
                        variant="outlined"
                        onChange={formik.handleChange('gender')}
                        error={
                          formik.errors.gender
                          && Boolean(formik.errors.gender)
                        }
                        label="Sexo"
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                      >
                        {options.map((option) => (
                          <ThemedMenuItem key={option.value} value={option.value}>
                            {option.label}
                          </ThemedMenuItem>
                        ))}
                      </ThemedSelect>
                      <FormHelperText style={{
                        color: '#f44336',
                        fontSize: '0.75rem',
                        fontFamily: "'Roboto', sans-serif'",
                      }}
                      >
                        {
                    formik.errors.gender
                      ? formik.errors.gender
                      : ''
                  }
                      </FormHelperText>
                    </ThemedFormControl>
                  </MuiThemeProvider>
                </GenderDiv>
                <DateDiv>
                  {/* <ThemedFormControl>
                    <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        mask="keyboardDate"
                        margin="normal"
                        id="date"
                        invalidDateMessage="Formato inválido"
                        label="Data de nascimento"
                        value={formik.values.date}
                        onChange={(value) => formik.setFieldValue('date', value)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                      </ThemedFormControl> */}
                  <ThemedTextField
                    id="birthday"
                    data-testid="birthday-input"
                    label="Data de nascimento"
                    name="birthday"
                    autoComplete="off"
                    variant="outlined"
                    type="date"
                    helperText={formik.errors.birthday ? formik.errors.birthday : ''}
                    error={formik.errors.birthday && Boolean(formik.errors.birthday)}
                    onChange={(e) => {
                      formik.setFieldValue('birthday', e.target.value);
                      formik.setFieldError('birthday', undefined);
                    }}
                    value={formik.values.birthday}
                    InputLabelProps={{ shrink: true }}
                  />
                </DateDiv>
                <MuiThemeProvider theme={MuiListThem}>
                  <ThemedFormControl variant="outlined" style={{ marginTop: '35px' }}>
                    <InputLabel
                      id="demo-mutiple-checkbox-label"
                    >
                      Especialidade
                    </InputLabel>
                    <ThemedSelect
                      labelId="expertise"
                      id="expertise"
                      multiple
                      name="expertise"
                      label="Especialidade"
                      variant="outlined"
                      value={formik.values.expertise}
                      onChange={formik.handleChange('expertise')}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {talentExpertise.map((name) => (
                        <ThemedMenuItem key={name.id} value={name.name}>
                          <ListItemText primary={name.name} />
                        </ThemedMenuItem>
                      ))}
                    </ThemedSelect>
                  </ThemedFormControl>
                </MuiThemeProvider>
                <DivButton>
                  <ThemedButtonAddSpecialist disabled={formik.isSubmitting} type="submit">
                    {buttonText}
                  </ThemedButtonAddSpecialist>
                </DivButton>
              </form>
            );
          }}
        </Formik>
      </FormDiv>
    </ComponentTheme>
  );
}

export default SpecialistRegister;
