import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { TextField, CircularProgress } from '@material-ui/core';
import { useDebounce } from 'use-debounce';

const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
  },
})(TextField);

const AsyncAutoComplete = ({
  fetchOptions, handleChange, formik, authType,
  errorField, helperText, formikValue, isUpdating,
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [debouncedSearchText] = useDebounce(searchText, 1000);
  const [preValue, setPreValue] = useState(null);
  const autocompleteProps = {
    open,
    options,
    loading,
    loadingText: 'Carregando...',
    noOptionsText: 'Nenhuma cidade encontrada',
    onOpen() {
      setOpen(true);
    },
    onClose() {
      setOpen(false);
    },
    onInputChange(event, value) {
      setSearchText(value);
    },
  };

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      setLoading(true);
      try {
        const data = await fetchOptions(debouncedSearchText);
        if (isSubscribed) {
          // console.log(data.map(({ name, id }) => ({ name, id })));
          setOptions(data.map(({ name, id }) => ({ name, id })));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [debouncedSearchText]);

  useEffect(() => {
    let isSubscribed = true;
    if (!formikValue) {
      setPreValue('');
    } else if (isUpdating) {
      (async () => {
        setLoading(true);
        try {
          const data = await fetchOptions(formikValue.name);
          if (isSubscribed) {
            setOptions(data.map(({ name, id }) => ({ name, id })));
            // handleChange(null, data.find((item) => item.id === formikValue.id), formik);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      })();
    }
    return () => {
      isSubscribed = false;
    };
  }, [formikValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (isUpdating) {
      setPreValue(formikValue);
    }
  }, [options]);

  return (
    <Autocomplete
      value={preValue}
      {...autocompleteProps}
      style={{ marginTop: '20px' }}
      onChange={(e, values) => { handleChange(e, values, formik); setPreValue(values); }}
      getOptionLabel={(option) => option.name || ''}
      renderInput={(params) => (
        <ThemedTextField
          {...params}
          id="cities-autocomplete"
          margin="normal"
          style={{ marginTop: '0px', marginBottom: '0px' }}
          name="city"
          label="Cidade"
          variant="outlined"
          error={errorField}
          helperText={helperText}
          value={formikValue}
          fullWidth
          InputProps={{
            ...params.InputProps,
            readOnly: authType === 'requester',
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            autoComplete: 'city-autocomplete-disabled',
          }}
        />
      )}
    />
  );
};

export default AsyncAutoComplete;
