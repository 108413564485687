import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line import/prefer-default-export
export const createEntitySlice = (sliceName, baseEndpoint, axios) => {
  const initialState = {
    byId: {},
    allIds: null,
  };
  const reducers = {
    setData: (state, { payload }) => {
      const currentAllIds = state.allIds ? [...state.allIds] : [];
      const set = (item) => {
        state.byId[item.id] = item;
        if (currentAllIds.indexOf(item.id) < 0) {
          currentAllIds.push(item.id);
        }
      };

      if (!Array.isArray(payload)) {
        set(payload);
      } else {
        payload.forEach((item) => {
          set(item);
        });
      }
      state.allIds = currentAllIds;
    },
    dropId: (state, { payload }) => {
      const currentAllIds = state.allIds ? [...state.allIds] : [];
      const drop = (id) => {
        const idIndex = currentAllIds.indexOf(id);
        if (idIndex >= 0) {
          currentAllIds.splice(idIndex, 1);
          delete state.byId[id];
        }
      };

      if (!Array.isArray(payload)) {
        drop(payload);
      } else {
        payload.forEach((item) => {
          drop(item);
        });
      }
      state.allIds = currentAllIds;
    },
    dropAll: (state) => {
      const currentAllIds = state.allIds ? [...state.allIds] : [];
      currentAllIds.forEach((id) => {
        delete state.byId[id];
      });
      state.allIds = [];
    },
  };
  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers,
  });
  const { setData } = slice.actions;

  slice.actions.merge = (data) => (dispatch) => {
    let promiseResult;
    if (Array.isArray(data)) {
      const incomingIds = [];
      data.forEach((item) => {
        incomingIds.push(item.id);
      });
      promiseResult = incomingIds;
    } else {
      promiseResult = data.id;
    }

    dispatch(setData(data));
    return Promise.resolve(promiseResult);
  };

  slice.actions.mergeFav = (data) => (dispatch) => {
    let promiseResult;

    if (Array.isArray(data)) {
      const incomingIds = [];
      data.forEach((item) => {
        incomingIds.push(item.talentId);
      });
      promiseResult = incomingIds;
    } else {
      promiseResult = data.talentId;
    }

    dispatch(setData(data));
    return Promise.resolve(promiseResult);
  };

  slice.actions.fetch = (params = null, axiosOptions = null) => async (dispatch) => axios
    .get(baseEndpoint, { params }, axiosOptions)
    .then(({ data }) => dispatch(slice.actions.merge(data)));

  slice.actions.fetchById = (id, axiosOptions = null) => async (dispatch) => {
    const hasManyIds = Array.isArray(id);
    const url = !hasManyIds ? `${baseEndpoint}/${id}` : baseEndpoint;
    const body = !hasManyIds ? null : { params: { id } };
    return axios
      .get(url, body, axiosOptions)
      .then(({ data }) => dispatch(slice.actions.merge(data)));
  };

  slice.actions.addId = (id, axiosOptions = null) => (dispatch, getState) => {
    let idToFetch;

    const { byId } = getState().entities[sliceName];
    const hasManyIds = Array.isArray(id);
    if (!hasManyIds) {
      if (!byId[id]) idToFetch = id;
    } else {
      const missingIds = [];
      id.forEach((idItem) => {
        if (!byId[idItem]) missingIds.push(idItem);
      });
      if (missingIds.length > 1) {
        idToFetch = missingIds;
      } else if (missingIds.length > 0) {
        [idToFetch] = missingIds;
      }
    }

    return !idToFetch ? Promise.resolve([])
      : dispatch(slice.actions.fetchById(idToFetch, axiosOptions));
  };

  return slice;
};
