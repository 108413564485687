import { fetch, fetchById } from 'entities/specialists';

import api from 'app/axios';

const specialistEndpoint = '/specialist';

export const fetchSpecialistByParams = (companyParams) => (dispatch) => dispatch(
  fetch(companyParams),
);

export const fetchSpecialistById = (companyId) => (dispatch) => dispatch(fetchById(companyId));

export const addNewSpecialist = (company) => (dispatch) => api
  .post(`${specialistEndpoint}`, company).then(() => dispatch(fetch()));

export const updateSpecialist = (specialistId, status) => (dispatch) => api
  .patch(`${specialistEndpoint}/${specialistId}`, { status }).then(() => dispatch(fetchById(specialistId)));

export const updateSpecialistStatus = (specialistId, type) => (dispatch) => api
  .patch(`${specialistEndpoint}/${specialistId}/${type}`).then(() => dispatch(fetchById(specialistId)));

export const updateSpecialistData = (companyId, name,
  phoneNumber, email, gender, birthday, expertise, pictureLink) => (dispatch) => api
  .patch(`${specialistEndpoint}/${companyId}`, {
    name, phoneNumber, email, gender, birthday, expertise, pictureLink,
  })
  .then(() => dispatch(fetchById(companyId)));
