import * as React from 'react';
import {
  Fade, LinearProgress,
} from '@material-ui/core';
import LoadingContext from 'components/Loading/LoadingContext';

const LoadingComponent = () => {
  const loading = React.useContext(LoadingContext);
  return (
    <Fade in={loading}>
      <LinearProgress
        color="primary"
        style={{
          backgroundColor: '#425AE0',
          position: 'fixed',
          width: '100%',
          zIndex: 9999,
        }}
      />
    </Fade>
  );
};

export default LoadingComponent;
