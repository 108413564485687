import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Tabs,
  Switch,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { fetch as fetchHardSkillsAreaAvailable } from 'entities/hardskillAreas';
import voltarImg from 'assets/Voltar.svg';
import { fetchAreaOfPracticeAvailables } from 'features/areaofpractice';
import { fetchSoftSkillsAvailable } from 'features/softskill';
import { toast } from 'react-toastify';
import Tab from 'components/Tab';
import { updateTalentStatus, fetchTalentById, setTalentId } from '.';
import TalentTechniqueForm from './technique/TalentTechniqueForm';
import TalentRegisterForm from './TalentRegister';

const Container = styled.div`
  padding: 0 var(--horizontal-padding);
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
`;

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '70px',
  },
})(Button);

const SwitchStatusCompany = withStyles({
  root: {
    marginLeft: '30px',
    height: '50px',
    width: '67px',
    '& .MuiSwitch-thumb': {
      border: '2px solid #425AE0',
      borderColor: '#425AE0',
      color: '#242448',
      width: '32px',
      height: '32px',
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#425AE0',
      height: '27px',
      borderRadius: '16px',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#425AE0',
      height: '27px',
      borderRadius: '16px',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: '#425AE0',
    },
  },
  checked: {
    '& .MuiSwitch-thumb': {
      border: '1px solid #1732C8',
      borderColor: '#1732C8',
      color: '#425AE0',
      width: '32px',
      height: '32px',
    },
  },

})(Switch);

function TalentPage() {
  const history = useHistory();
  const { id: talentId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [statusTalent, setTalentStatus] = useState(true);
  // Coloquei como block agora apenas para ser visualizado no code-review, mas na mecanica final, ele só será visível
  // quando for uma edição
  const { talentRegister, authType } = useSelector(
    ({ features, entities }) => ({
      talentRegister: entities.talents,
      authType: features.auth.type,
    }),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authType) return;
    if (authType === 'requester' && !talentId) history.replace('/');
    dispatch(fetchAreaOfPracticeAvailables());
    dispatch(fetchSoftSkillsAvailable());
    dispatch(fetchHardSkillsAreaAvailable());
    if (talentId) {
      dispatch(fetchTalentById(talentId))
        .catch(() => {
          toast.error('Talento não encontrado');
          history.replace('/talents');
        });
    }
  }, [dispatch, talentId, authType]);

  useEffect(() => {
    if (talentRegister.allIds && talentId !== 0 && talentRegister.byId[talentId]) {
      if (talentRegister.byId[talentId].isActive) {
        setTalentStatus(true);
      } else {
        setTalentStatus(false);
      }
    }
  }, [talentRegister]);

  useEffect(() => {
    setActiveTab(0);
  }, [history.location.pathname]);

  const handleChange = (event, newValue) => {
    if (talentId) {
      setActiveTab(newValue);
    } else {
      alert('Impossível mudar de aba enquanto não salvar');
    }
  };

  async function handleTalentStatusChange({ target: { name, checked } }) {
    setTalentStatus(checked);
    dispatch(updateTalentStatus(name, checked ? 'activate' : 'deactivate'))
      .then(() => toast.info(`Talento ${checked ? 'ativado' : 'desativado'} com sucesso`))
      .catch(() => {
        toast.info(`Erro ao ${checked ? 'ativar' : 'desativar'} talento`);
        setTalentStatus(!checked);
      });
  }

  const handleBackButton = () => {
    dispatch(setTalentId(0));
    history.goBack();
  };

  return (
    <Container>
      <ContainerHeader>
        <div style={{ flex: 1 }}>
          { talentId !== 0 ? <BackButton onClick={handleBackButton} /> : null }
        </div>
        <Paper square style={{ background: '#171836' }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Mudar Tipo de Cadastro"
          >
            <Tab
              disableRipple
              style={{ backgroundColor: activeTab === 0 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
              label="Info pessoal"
            />
            <Tab
              style={{ backgroundColor: activeTab === 1 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
              label="Info técnica"
            />
          </Tabs>
        </Paper>
        <div style={{ flex: 1 }}>
          { authType !== 'requester' && (
            <SwitchStatusCompany
              name={talentId || 'talentId'}
              onChange={handleTalentStatusChange}
              checked={statusTalent}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          )}
        </div>
      </ContainerHeader>

      <div>
        {activeTab === 0 ? <TalentRegisterForm talentId={talentId} /> : <TalentTechniqueForm talentId={talentId} />}
      </div>
    </Container>
  );
}

export default TalentPage;
