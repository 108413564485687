import { createEntitySlice } from 'utils/redux';

import api from 'app/axios';

const name = 'region';
const endpoint = '/location/region';
const slice = createEntitySlice(name, endpoint, api);
export const {
  merge,
  fetch,
  fetchById,
  addId,
} = slice.actions;

export default slice.reducer;
