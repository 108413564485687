import { createSlice } from '@reduxjs/toolkit';
import { fetch, fetchById } from 'entities/companies';

import api from 'app/axios';

const initialState = {
  companyId: 0,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyId: (state, { payload: companyId }) => {
      state.companyId = !companyId ? initialState.companyId : companyId;
    },
  },
});

const companyEndpoint = '/company';

const { setCompanyId } = companySlice.actions;

export const fetchCompanyByParams = (companyParams) => (dispatch) => dispatch(
  fetch(companyParams),
);

export const returnCompanies = (companyParams) => api.get(`${companyEndpoint}`, { params: companyParams })
  .then(({ data }) => data);

export const setCompanyIdChoosed = (companyId) => (dispatch) => dispatch(
  setCompanyId(companyId),
);

export const fetchCompanyById = (companyId) => (dispatch) => dispatch(fetchById(companyId));

export const addNewCompany = (company) => (dispatch) => api
  .post(`${companyEndpoint}`, company).then(() => dispatch(fetch()));

export const updateCompany = (companyId, status) => async () => api
  .patch(`${companyEndpoint}/${companyId}`, { status });

export const updateCompanyData = (companyId, data) => (dispatch) => api
  .put(`${companyEndpoint}/${companyId}`, data).then(() => dispatch(fetchById(companyId)));

export default companySlice.reducer;
