import React from 'react';
import downloadImg from 'assets/cvdownload.png';
import {
  ThemedButton, ContainerTitle, ContainerHeader, TitleRegister,
  ContainerDownload, ThemedAvatar,
} from './styles';
import downloadCsv from '.';

function VacancySending() {
  return (
    <ContainerDownload>
      <ContainerHeader>
        <ContainerTitle>
          <TitleRegister>
            Download de dados
          </TitleRegister>
        </ContainerTitle>
      </ContainerHeader>
      <ContainerTitle>
        <ThemedButton
          onClick={downloadCsv}
          endIcon={(
            <ThemedAvatar
              src={downloadImg}
            />
)}
        >
          Download
        </ThemedButton>
      </ContainerTitle>
    </ContainerDownload>
  );
}

export default VacancySending;
