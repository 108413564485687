/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormikContext, FastField } from 'formik';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const ThemedCheckBox = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
    '&$disabled': {
    },
  },
  disabled: { },
  checked: {},
})(MaterialCheckbox);

const ThemedFormControlLabel = withStyles({
  root: {
  },
  label: {
    color: 'white !important',
  },
})(FormControlLabel);

const Checkbox = ({
  error, label, value, name, ...props
}) => (
  <FormControl error={error}>
    <FormGroup>
      <ThemedFormControlLabel
        style={{ color: 'white' }}
        control={<ThemedCheckBox disabled defaultChecked={value?.includes(name)} {...props} />}
        label={label}
      />
      {error && (
      <div style={{ marginLeft: '16px' }}>
        <FormHelperText>{error}</FormHelperText>
      </div>
      )}
    </FormGroup>
  </FormControl>
);

const FormikField = ({
  fieldValue,
  checked,
  name,
  label,
  disabled,
  required = false,
  ...props
}) => {
  const { errors, touched } = useFormikContext();
  const hasError = touched[name] && errors[name];

  const handleChange = () => {
    if (!fieldValue.includes(name)) {
      fieldValue.push(name);
    } else {
      const idx = fieldValue.indexOf(name);
      fieldValue.splice(idx, 1);
    }
  };

  return (
    <div>
      <FastField
        type="checkbox"
        required={required}
        as={Checkbox}
        label={label}
        name={name}
        value={fieldValue}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
        error={hasError}
        color="primary"
        {...props}
      />
    </div>
  );
};

export default FormikField;
