import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { favoriteTalent, removeFavoriteTalent } from 'features/vacancy/index';

import TalentCard from 'components/TalentCard';

function TalentItem(props) {
  const { talents, authType } = props;
  const { vacancyId } = props;
  const { favorites } = useSelector((
    { features },
  ) => ({
    favorites: features.vacancy.favorites,
  }));
  const [isFavorite, setIsFavorite] = useState();
  const dispatch = useDispatch();
  useEffect(() => {

  }, [dispatch, vacancyId, favorites]);

  useEffect(() => {
    if (talents.favoriteVacancies?.map(({ id }) => id).includes(Number(vacancyId))) {
      setIsFavorite(1);
    } else {
      setIsFavorite(null);
    }
  }, [talents, vacancyId]);

  function handleClickIcon() {
    const talentId = talents.id;
    const data = {
      talentId,
      vacancyId,
    };

    if (!isFavorite) {
      dispatch(favoriteTalent(data)).then(() => {
        toast.info('Talento favoritado com sucesso');
      })
        .catch(() => {
          toast.error('Erro ao favoritar talento');
        });
      setIsFavorite(1);
    } else {
      dispatch(removeFavoriteTalent(data)).then(() => {
        toast.info('Talento removido dos favoritos com sucesso');
      })
        .catch(() => {
          toast.error('Erro ao remover talento dos favoritos');
        });
      setIsFavorite(null);
    }
  }

  return (
    <TalentCard
      authType={authType}
      talent={talents}
      vacancyId={vacancyId}
      onFavoriteClick={handleClickIcon}
      isFavorite={Boolean(isFavorite)}
    />
  );
}

export default TalentItem;
