/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  MuiThemeProvider,
  InputLabel,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import qs from 'qs';
import { fetchCompanyByParams, setCompanyIdChoosed } from 'features/company/index';
import { setTalentId } from 'features/talent/index';
import {
  fetchFavoritesPagination, resetTalentsFavoriteCompany,
} from 'features/favorite';
import { fetchRequesterById, setRequesterChoosedId } from 'features/requester';
import Pagination from 'components/Pagination';

import FavoriteItem from '../FavoriteItem';
import {
  fetchVacancyByCompanyNonPaginated, setVacancyIdChoosed, sendFavorites,
} from '../..';

import {
  Container,
  ContainerHeader,
  SelectDiv,
  DivButton,
  ListCards,
  DivNull,
  useStyles,
  ThemedButton,
  ThemedFormControl,
  MuiListThem,
  ThemedMenuItem,
  ThemedSelect,
} from './styles';

const qsStringToObject = (data) => qs.parse(data, { ignoreQueryPrefix: true });
const qsObjectToString = (data) => qs.stringify(data, { addQueryPrefix: true });

function ListarTalento() {
  const history = useHistory();
  const { companyId = '', vacancyId = '' } = useParams();

  const vacancies = useSelector((state) => state.entities.vacancies);
  const favorites = useSelector((state) => state.entities.favorites);
  const companies = useSelector((state) => state.entities.companies);
  const authType = useSelector((state) => state.features.auth.type);
  const totalList = useSelector((state) => state.features.favorite.totalList);
  const {
    user, requesters,
  } = useSelector(
    ({ features, entities }) => ({
      user: features.auth.user,
      requesters: entities.requesters,
    }),
  );

  const classes = useStyles();

  const dispatch = useDispatch();
  const status = 1;

  const changeSearchQuery = (overrideParams = {}) => {
    const currentQuery = qsStringToObject(history.location.search);
    const newQueryObject = { ...currentQuery, ...overrideParams };
    const newQuery = Object.keys(newQueryObject).reduce((prev, cur) => {
      if (newQueryObject[cur] === undefined || newQueryObject[cur] === null || newQueryObject[cur] === '') return prev;
      prev[cur] = newQueryObject[cur];
      return prev;
    }, {});
    history.push(`${history.location.pathname}${qsObjectToString(newQuery)}`);
  };

  const handlePageChange = (_, value) => {
    if (value === qsStringToObject(history.location.search).page) return;
    changeSearchQuery({ page: value });
  };

  const handleEditTalent = (talentId) => {
    dispatch(setTalentId(talentId));
  };

  function sendVacancy() {
    if (favorites) {
      dispatch(sendFavorites(vacancyId)).then(() => {
        toast.info('Talentos enviados para vaga com sucesso');
      })
        .catch(() => {
          toast.error('Erro ao enviar Talentos para a vaga');
        });
    } else {
      toast.info('Não há talentos a serem enviados');
    }
  }
  function handleChange(event) {
    history.push(`/favorites/${event.target.value}`);
    dispatch(resetTalentsFavoriteCompany());
    dispatch(setCompanyIdChoosed(event.target.value));
    dispatch(fetchVacancyByCompanyNonPaginated(event.target.value));
  }

  function handleVacancyChange(event) {
    history.push(`/favorites/${companyId}/${event.target.value}`);
  }

  const showFavorites = () => {
    let filters = qsStringToObject(history.location.search);
    dispatch(setVacancyIdChoosed(vacancyId));
    if (authType === 'requester') {
      filters = { ...filters, status: 'available' };
      dispatch(fetchFavoritesPagination(vacancyId, filters));
    } else {
      dispatch(fetchFavoritesPagination(vacancyId, filters));
    }
  };

  const getCompanyOptions = () => {
    const companyRequester = requesters.byId[user?.id]?.company;
    if (authType === 'requester' && companyRequester) return [companyRequester];
    return companies.allIds?.map((id) => companies.byId[id]) || [];
  };

  useEffect(() => {
    if (authType) {
      if (authType === 'requester') {
        dispatch(fetchRequesterById(user.id));
        dispatch(setRequesterChoosedId(user.id));
      } else {
        dispatch(fetchCompanyByParams({ isActive: status }));
      }
    }
  }, [authType]);

  useEffect(() => {
    if (authType === 'requester') {
      const requester = requesters.byId[user.id];
      if (!requester) return;
      if (!companyId) {
        history.replace(`/favorites/${requester.companyId}`);
        return;
      }
      if (companyId && Number(companyId) !== requester.companyId) {
        history.replace(`/favorites/${requester.companyId}`);
        return;
      }
      dispatch(setCompanyIdChoosed(companyId));
      dispatch(fetchVacancyByCompanyNonPaginated(companyId));
    } else {
      if (companies.allIds?.length && !companies.byId[companyId]) {
        history.replace('/favorites');
        return;
      }

      if (companyId && companies.byId[companyId]) {
        dispatch(setCompanyIdChoosed(companyId));
        dispatch(fetchVacancyByCompanyNonPaginated(companyId));
      }
    }
  }, [companyId, companies, requesters, dispatch]);

  useEffect(() => {
    if (vacancies.allIds?.length && !vacancies.byId[vacancyId]) {
      history.replace(`/favorites/${companyId}`);
      return;
    }
    if (vacancyId && vacancies.byId[vacancyId]) {
      dispatch(setVacancyIdChoosed(vacancyId));
      showFavorites();
    }
  }, [vacancyId, vacancies, history.location.search]);

  return (
    <Container>
      <ContainerHeader>
        O talento será enviado para a vaga:
      </ContainerHeader>
      <MuiThemeProvider theme={MuiListThem}>
        <SelectDiv>
          <ThemedFormControl
            variant="outlined"
            style={{ marginRight: '30px' }}
          >
            <InputLabel id="labelcompany">Empresa</InputLabel>
            <ThemedSelect
              id="company"
              labelId="labelcompany"
              label="Empresa"
              value={companyId}
              disabled={authType === 'requester'}
              onChange={(event) => handleChange(event)}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              { getCompanyOptions().map((company) => (
                <ThemedMenuItem key={company.id} value={company.id} name={company.name}>
                  {company.name}
                </ThemedMenuItem>
              ))}
            </ThemedSelect>
          </ThemedFormControl>
          <ThemedFormControl
            variant="outlined"
          >
            <InputLabel id="labelvacancy">Vaga</InputLabel>
            <ThemedSelect
              id="vacancy"
              labelId="labelvacancy"
              label="Vaga"
              disabled={!companyId}
              value={vacancyId}
              onChange={(event) => handleVacancyChange(event)}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              { vacancies.allIds ? vacancies.allIds.map((item) => {
                const vacancy = vacancies.byId[item];
                return (
                  <ThemedMenuItem key={vacancy.id} value={vacancy.id} name={vacancy.name}>
                    {vacancy.name}
                  </ThemedMenuItem>
                );
              })
                : (
                  <ThemedMenuItem key={1}>
                    Não há vagas
                  </ThemedMenuItem>
                )}
            </ThemedSelect>
          </ThemedFormControl>
        </SelectDiv>
      </MuiThemeProvider>

      {(companyId && vacancyId)
        ? (
          <div>
            <ListCards className={classes.root}>
              <Grid container spacing={2}>
                { favorites.allIds ? favorites.allIds.map((item) => {
                  const favorite = favorites.byId[item];
                  return (
                    <Grid key={favorite.id} item md={3} sm={4} xs={6}>
                      <FavoriteItem
                        edit={handleEditTalent}
                        favorites={favorite}
                        vacancyId={vacancyId}
                        totalList={totalList}
                        authType={authType}
                      />
                    </Grid>
                  );
                }) : <></>}
              </Grid>
            </ListCards>
            <Pagination
              style={{ paddingRight: 0 }}
              count={totalList !== 0 ? Math.ceil(totalList / 8) : 1}
              page={Number(qsStringToObject(history.location.search).page) || 1}
              onChange={handlePageChange}
              shape="rounded"
            />
            <Grid item xs={12}>
              <DivButton>
                <ThemedButton onClick={() => sendVacancy()} type="submit">
                  Enviar para vaga
                </ThemedButton>
              </DivButton>
            </Grid>
          </div>
        )
        : (
          <DivNull>
            É necessário adicionar uma empresa e vaga para visualizar os talentos.
          </DivNull>
        )}
    </Container>
  );
}

export default ListarTalento;
