import { merge, dropId } from 'entities/hardskillAreas';

import axios from 'app/axios';

const hardskillAreasEndpoint = '/hardskill/area';

export const add = ({ name }) => (dispatch) => axios
  .post(hardskillAreasEndpoint, { name }).then(({ data: hardskillArea }) => {
    dispatch(merge(hardskillArea));
  });

export const update = ({ id, name }) => (dispatch) => axios
  .patch(`${hardskillAreasEndpoint}/${id}`, { name }).then(({ data: hardskillArea }) => {
    dispatch(merge(hardskillArea));
  });

export const remove = (id) => (dispatch) => axios.delete(`${hardskillAreasEndpoint}/${id}`).then(() => {
  dispatch(dropId(id));
});
