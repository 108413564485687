import styled from 'styled-components';
import { withStyles, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const ListStyled = styled.div`
  font-size: large;
  list-style: none;
  background: #242448;
  display: flex;
  flex-direction: column;
  border-radius: 17px;
  box-shadow: 0px 4px 6px #00000029;
  color: #ffffff;
`;

export const DivHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  button {
    padding: 0;
  }
`;

export const StatusBall = styled.div`
  width: 21px;
  height:21px;
  border-radius: 50%;
  background-color: #D7D128;
  box-shadow: 0px 0px 10px #D7D1288A;
`;

export const PictureDiv = styled.img`
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -30px;
  display: block;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  background: #242448; 
`;

export const SpanItemStyled = styled.span`
  display: block;
  text-align:center;
  width: 100%;
  color: #ffffff;
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: auto;
  margin-right: auto;
`;

export const SpanLastItemStyled = styled.span`
  display: block;
  text-align:center;
  margin: 18px 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: auto;
  margin-right: auto;
`;

export const ThemedLink = withStyles({
  root: {
    color: '#425AE0',
    '& hover': {
      color: '#425AE0',
    },
    '& visited': {
      color: '#425AE0',
    },
    '& active': {
      color: '#425AE0',
    },
  },
})(Link);

export const useStyles = makeStyles(() => ({
  disabled: {
    background: '#000000',
    opacity: '0.5',
  },
}));
