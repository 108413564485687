/* eslint-disable import/prefer-default-export */
import { makeStyles, withStyles, TextField } from '@material-ui/core';

export const ThemedTextField = withStyles({
  root: {
    marginBottom: '24px',
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
  },
})(TextField);

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#171836',
    border: '1px solid #425AE0',
    borderRadius: 24,
    boxShadow: theme.shadows[5],
    padding: '24px 48px',
    color: 'white',
    outline: 0,
    textAlign: 'center',
  },
  button: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    letterSpacing: '0px',
    alignSelf: 'center',
    padding: '6px 14px',
    width: '140px',
    maxWidth: '100%',
    textTransform: 'capitalize',
    margin: '0px 12px',
  },
  font: { fontWeight: 'normal' },
}));

/* eslint-enable import/prefer-default-export */
