import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '@material-ui/icons/HighlightOff';

import ConfirmModal from '../ConfirmRemoveColumn';
import { Container } from './styles';

function KanbanColumnHeader({
  column, onSubmit, isRemoving, onColumnRemove, canRemove, canRename,
}) {
  const inputRef = useRef();
  const [newName, setNewName] = useState(column.name);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (column.name !== newName) setNewName(column.name);
  }, [column.name]); // eslint-disable-line

  const handleSubmit = () => {
    const normalizedNewName = newName.trim();

    if (normalizedNewName.length && normalizedNewName !== column.name) {
      onSubmit(column, normalizedNewName);
    } else {
      setNewName(column.name);
    }
  };

  const handleBlur = (e) => {
    e.preventDefault();
    inputRef.current.blur();
  };

  useEffect(() => {
    const immutableRef = inputRef.current;
    immutableRef.addEventListener('blur', handleSubmit);
    return () => {
      immutableRef.removeEventListener('blur', handleSubmit);
    };
  }, [newName, column.name]); // eslint-disable-line

  return (
    <>
      <Container>
        <form onSubmit={handleBlur}>
          <input
            ref={inputRef}
            value={newName}
            readOnly={!canRename}
            onChange={(e) => setNewName(e.target.value)}
          />
        </form>
        {canRemove && isRemoving && (
          <button type="button" aria-label="Remover Coluna" onClick={() => setShowConfirmModal(true)}>
            <RemoveIcon />
          </button>
        )}
      </Container>
      <ConfirmModal
        visible={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          onColumnRemove(column);
        }}
      />
    </>
  );
}

KanbanColumnHeader.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NEW_COLUMN'])]),
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func,
  isRemoving: PropTypes.bool,
  onColumnRemove: PropTypes.func,
  canRename: PropTypes.bool,
};

KanbanColumnHeader.defaultProps = {
  onSubmit: () => {},
  isRemoving: false,
  onColumnRemove: () => {},
  canRename: true,
};

export default KanbanColumnHeader;
