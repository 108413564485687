function FormatStatus(props) {
  let status = props;

  switch (status) {
    case 'Solteiro':
      status = 'single';
      break;
    case 'Casado':
      status = 'married';
      break;
    case 'Viúvo':
      status = 'widow';
      break;
    case 'Separado':
      status = 'separated';
      break;
    case 'Divorciado':
      status = 'divorced';
      break;
    default:
      break;
  }
  return status;
}

export default FormatStatus;
