/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    background-color: #2A2F66;
    padding: 16px 8px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    
    input {
        color: white;
        text-align: center;
        font-size: 16px;
        width: 100%;
        background-color: transparent;
        border: 0;
        padding: 0;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
        border-bottom: dashed 1px transparent;
        &:focus {
            border-color: rgba(255, 255, 255, 0.2);
        }
        &:read-only {
            border-color: transparent;
        }
    }

    button {
        display: flex;
        position: absolute;
        right: 14px;
        top: 12px;
        background-color: transparent;
        border: 0;
        border-radius: 14px;
        padding: 2px;
        color: white;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
`;

/* eslint-enable import/prefer-default-export */
