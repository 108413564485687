import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { TextField, Button, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import voltarImg from 'assets/Voltar.svg';
import { toast } from 'react-toastify';
import { updateNote } from './redux';

const PageContainer = styled.div`
  padding: 0px var(--horizontal-padding);
  flex: 1;
  display: flex;
  flex-direction: column;  
`;

const ContainerHeader = styled.div`
  display: inline-flex;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: center;
`;

const FormContainer = styled.div`
  margin-top: 2%;
  display: flex;
  justify-content: center;
`;

const ContainerTitulo = styled.div`
  flex: 100%;
  width: 60%;
  display: inline;
`;

const TitleRegister = styled.span`
  font-size: 26px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const SubTitleRegister = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-top: 9%;
  display: flex;
  justify-content: center;
`;

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '85px',
    marginTop: '10px',
  },
})(Button);

const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '200px',
    height: '50%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const ThemedTextField = withStyles({
  root: {
    width: '60%',
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
  },
})(TextField);

function KanbanNotes() {
  const dispatch = useDispatch();
  const { id: vacancyId } = useParams();
  const history = useHistory();

  const [note, setNote] = useState('');

  const vacancyInfo = useSelector((state) => state.entities.vacancies.byId[vacancyId]);
  const requesterInfo = useSelector((state) => Object.values(state.entities.requesters.byId || {})
    .find((item) => item.companyId === vacancyInfo?.companyId));

  const isVacancyActive = Boolean(vacancyInfo?.isActive);

  useEffect(() => {
    if (vacancyInfo) {
      setNote(vacancyInfo.kanbanNotes);
    } else {
      history.replace(`/vacancies/${vacancyId}/kanban`);
    }
    // eslint-disable-next-line
  }, [dispatch, vacancyInfo]);

  const handleSaveNote = () => {
    dispatch(updateNote(vacancyId, { kanbanNotes: note })).then(() => {
      toast.info('Nota salva com sucesso');
      history.goBack();
    })
      .catch(() => {
        toast.error('Erro ao salvar nota');
      });
  };

  return (
    <PageContainer>
      <ContainerHeader>
        <BackButton onClick={history.goBack} />
        {
                requesterInfo && vacancyInfo
                  ? (
                    <div style={{ display: 'flex', width: '100%' }}>
                      <ContainerTitulo style={{ marginLeft: '5%' }}>
                        <div>
                          <TitleRegister>
                            Nome da empresa
                          </TitleRegister>
                          <p />
                          <SubTitleRegister>
                            {requesterInfo.company.name}
                          </SubTitleRegister>
                          <br />
                          <SubTitleRegister>
                            {requesterInfo.user.name}
                          </SubTitleRegister>
                        </div>
                      </ContainerTitulo>
                      <ContainerTitulo style={{ marginLeft: '5%' }}>
                        <div>
                          <TitleRegister>
                            Nome da Vaga
                          </TitleRegister>
                          <p />
                          <SubTitleRegister>
                            {vacancyInfo.name || ''}
                          </SubTitleRegister>
                          <br />
                          <SubTitleRegister>
                            {vacancyInfo.openSince || ''}
                          </SubTitleRegister>
                        </div>
                      </ContainerTitulo>
                      <ContainerTitulo style={{ marginLeft: '5%' }}>
                        <div>
                          <TitleRegister>
                            Especialista
                          </TitleRegister>
                          <p />
                          <SubTitleRegister>
                            {requesterInfo.user.name}
                          </SubTitleRegister>
                        </div>
                      </ContainerTitulo>
                    </div>
                  )
                  : <></>
        }
      </ContainerHeader>
      <Container>
        <LabelContainer>
          <TitleRegister>
            Adicione uma nota sobre o kanban selecionado
          </TitleRegister>
        </LabelContainer>
        <FormContainer>
          <ThemedTextField
            InputProps={{
              readOnly: !isVacancyActive,
            }}
            id="note-text"
            data-testid="note-input"
            multiline
            fullWidth
            label="Notas Kanban"
            name="note"
            autoComplete="off"
            variant="outlined"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            rows={7}
          />
        </FormContainer>
        <ButtonContainer>
          {isVacancyActive && (
            <ThemedButton type="button" onClick={handleSaveNote}>
              Salvar Nota
            </ThemedButton>
          )}
        </ButtonContainer>
      </Container>
    </PageContainer>
  );
}

export default KanbanNotes;
