import { createSlice } from '@reduxjs/toolkit';
import { fetchById, merge, dropAll } from 'entities/talents';
import qs from 'qs';
import api from 'app/axios';

const initialState = {
  talentCreatedId: 0,
  pageRegister: 0,
  pageList: 0,
  totalList: 0,
  pagesAccessed: [],
};
export const talentSlice = createSlice({
  name: 'talent',
  initialState,
  reducers: {
    setTalentCreatedId: (state, { payload: talentCreatedId }) => {
      state.talentCreatedId = !talentCreatedId ? initialState.talentCreatedId : talentCreatedId;
    },
    setPageRegister: (state, { payload: page }) => {
      state.pageRegister = !page ? initialState.pageRegister : page;
    },
    setPageList: (state, { payload: page }) => {
      state.pageList = !page ? initialState.pageList : page;
      if (!state.pagesAccessed.includes(page)) {
        state.pagesAccessed.push(page);
      }
    },
    setTotalList: (state, { payload: total }) => {
      state.totalList = !total ? initialState.totalList : total;
    },
    resetPagesAccessed: (state) => {
      state.pagesAccessed = [];
    },
  },
});

const {
  setTalentCreatedId, setPageRegister, setTotalList, setPageList, resetPagesAccessed,
} = talentSlice.actions;

const talentEndpoint = '/talent';

// export const fetchTalent = (page) => (dispatch) => api
//  .get(`${talentEndpoint}/?page=${page}`).then(({ data }) => dispatch(merge(data.results)));

export const fetchTalent = (page) => (dispatch) => api
  .get(`${talentEndpoint}`, { params: { page } }).then(({ data }) => {
    dispatch(merge(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(page));
  });

export const fetchTalentRequester = (companyId, vacancyId, page, textFilter) => (dispatch) => api
  .get(`${talentEndpoint}/${companyId}/${vacancyId}/filter/`, { params: { page, ...textFilter } }).then(({ data }) => {
    if (page === 0) {
      dispatch(dropAll());
      dispatch(resetPagesAccessed());
    }
    dispatch(merge(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(page));
  });

export const fetchFilterTalent = (companyId, vacancyId, universityLevel, level, maritalStatus,
  gender, salary, age, hardskill, softskill, areaOfPractice,
  PCD, page, textFilter, locality) => (dispatch) => api
  .get(`${talentEndpoint}/${companyId}/${vacancyId}/filter/`, {
    params: {
      universityLevel,
      'levels.level': level,
      maritalStatus,
      gender,
      'intendedSalary(min)': salary[0],
      'intendedSalary(max)': salary[1],
      'age(min)': age[0],
      'age(max)': age[1],
      'hardskills.id': hardskill,
      'softskills.id': softskill,
      'allAreasOfPractice.id': areaOfPractice,
      'region.id': locality ? locality.id : undefined,
      PCD,
      page,
      ...textFilter,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }).then(({ data }) => {
    if (page === 0) {
      dispatch(dropAll());
      dispatch(resetPagesAccessed());
    }
    dispatch(merge(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(page));
  });

const normalizeFilters = ({
  page = 1,
  shortId,
  name,
  areaOfPractice,
  level,
  hardskill,
  softskill,
  universityLevel,
  region,
  maritalStatus,
  gender,
  pcd,
  salary,
  age,
  status,
}) => ({
  'allAreasOfPractice.id': areaOfPractice,
  'levels.level': level,
  'hardskills.id': hardskill,
  'softskills.id': softskill,
  universityLevel,
  maritalStatus,
  gender,
  isPCD: pcd === 'indifferent' ? undefined : pcd,
  'intendedSalary(min)': salary?.[0],
  'intendedSalary(max)': !salary?.[1] || Number(salary?.[1]) >= 30000 ? undefined : salary[1],
  'age(min)': age?.[0],
  'age(max)': age?.[1],
  'region.name': region,
  page: page - 1,
  shortId: shortId ? `%${shortId}%` : undefined,
  name: name ? `%${name}%` : undefined,
  status,
});

export const fetchFilterTalentRefactored = (companyId, vacancyId, filter) => (dispatch) => api
  .get(`${talentEndpoint}/${companyId}/${vacancyId}/filter/`, {
    params: normalizeFilters(filter),
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }).then(({ data }) => {
    dispatch(dropAll());
    dispatch(resetPagesAccessed());
    dispatch(merge(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(filter.page - 1));
  });

export const fetchAllFilterTalentRefactored = (filter) => (dispatch) => api
  .get(`${talentEndpoint}/filter/`, {
    params: normalizeFilters(filter),
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }).then(({ data }) => {
    dispatch(dropAll());
    dispatch(resetPagesAccessed());
    dispatch(merge(data.results));
    dispatch(setTotalList(data.total));
    dispatch(setPageList(filter.page - 1));
  });

export const fetchTalentListPageBack = (page) => (dispatch) => {
  dispatch(setPageList(page));
};

export const fetchNextPage = (page) => (dispatch) => dispatch(setPageRegister(page));

export const setTalentId = (talentid) => (dispatch) => dispatch(setTalentCreatedId(talentid));

export const fetchTalentById = (talentId) => (dispatch) => dispatch(fetchById(talentId));

export const addNewTalent = (talent) => () => api.post(`${talentEndpoint}`, talent);

export const resetTalentCompany = () => (dispatch) => {
  dispatch(dropAll());
};

export const resetTalentPagesAccessed = () => (dispatch) => {
  dispatch(resetPagesAccessed());
};

export const updateTalentData = (talentId, data) => (dispatch) => api
  .patch(`${talentEndpoint}/${talentId}`, data).then(() => dispatch(fetchById(talentId)));

export const updateTalentStatus = (talentId, type) => (dispatch) => api
  .patch(`${talentEndpoint}/${talentId}/${type}`).then(() => dispatch(fetchById(talentId)));

export const updateTalentTechInfo = (talentId, data) => (dispatch) => api
  .patch(`${talentEndpoint}/${talentId}/technical-info`, data).then(() => dispatch(fetchById(talentId)));

export default talentSlice.reducer;
