import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CardContent = styled.div`
  padding: 8px 14px;
  display: flex;
  flex-direction: column;
  background-color: ${({ isActive }) => (isActive ? '#181936' : '#5C1221')};
  border-radius: 4px;
  width: 192px;
`;

export const Card = styled(Link)`
  display: inherit;
  border-radius: 4px;
  margin-bottom: 14px;
  color: white;
  text-decoration: none;
  &:visited, &:focus {
    color: white;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 12px;
`;

export const Name = styled.div`
  font-size: 14px;
  word-break: break-word;
`;

export const Salary = styled.div`
  font-size: 14px;
  background-color: #242448;
  padding: 2px 12px;
  border-radius: 4px;
`;

export const ActionButton = styled.button`
  border: 0;
  padding: 0px 4px;
  color: white;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
`;
