import { createSlice } from '@reduxjs/toolkit';
import { fetch, fetchById } from 'entities/regions';
import api from 'app/axios';

const initialState = {
  requesterId: 0,
};

export const requesterSlice = createSlice({
  name: 'requester',
  initialState,
  reducers: {
    setRequesterId: (state, { payload: requesterId }) => {
      state.requesterId = !requesterId ? initialState.requesterId : requesterId;
    },
  },
});

const cityEndpoint = '/location/region';

export const returnRegions = (cityParams) => api.get(`${cityEndpoint}`, { params: cityParams })
  .then(({ data }) => data);

export const fetchRegionById = (requesterId) => (dispatch) => dispatch(fetchById(requesterId));

export const fetchRegions = () => (dispatch) => dispatch(fetch());

export default requesterSlice.reducer;
