import React from 'react';
import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import { IconButton } from '@material-ui/core';
import moment from 'moment';

const ListStyled = styled.div`
  font-size: large;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 14px;
  list-style: none;
`;

const ListItemStyled = styled.div`
  background: #242448;
  padding: 8px 16px;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 17px;
  position: relative;
  display: table;
  box-shadow: 0px 4px 6px #00000029;
  cursor: pointer;
`;

const DivIcon = styled.div`
  text-align:right;
  position: absolute;
  top: 0;
  right: 0;
`;

const PictureDiv = styled.img`
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: 4px;;
  display: block;
  margin-bottom: 8px;;
  margin-left: auto;
  margin-right: auto;
  background: #242448; 
`;

const SpanItemStyled = styled.span`
  display: block;
  vertical-align: middle;
  text-align:center;
  width: 100%;
  color: #ffffff;
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: auto;
  margin-right: auto;
`;

const SpanItemVacancyStyled = styled.span`
  display: block;
  text-align:center;
  width: 100%;
  color: #ffffff;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: auto;
  margin-right: auto;
`;

const HeaderCard = styled.div`
  display: flex;
  width: 100%;
`;

const SpanItemHeaderCard = styled.span`
  display: block;
  text-align:center;
  width: 100%;
  font-size: 23px;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 0 14px;
`;

const SpanItemLeftStyled = styled.span`
  display: block;
  text-align:center;
  color: #ffffff;
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: auto;
  margin-right: auto;
`;

const SpanLastItemStyled = styled.span`
  display: block;
  text-align:center;
  margin: 16px 0px 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    margin-left: auto;
  margin-right: auto;
`;

function TalentItem(props) {
  const { vacancies, edit, onClick } = props;
  return (
    <ListStyled>
      {vacancies ? (
        <ListItemStyled onClick={() => {
          if (onClick) onClick(vacancies.id);
        }}
        >
          <HeaderCard>
            <SpanItemHeaderCard style={{ color: vacancies.isActive === 1 ? '#425AE0' : '#A11F0A' }}>
              {vacancies.name}
            </SpanItemHeaderCard>
            <DivIcon>
              <IconButton
                aria-label="add to favorite"
                onClick={(e) => {
                  e.stopPropagation();
                  edit(vacancies.id);
                }}
              >
                <FiEdit color="white" />
              </IconButton>
            </DivIcon>
          </HeaderCard>
          <SpanItemVacancyStyled>
            Vagas Disponíveis:
            {vacancies.nVacancies}
          </SpanItemVacancyStyled>
          <SpanItemLeftStyled>{`Total de talentos: ${vacancies.talentsInVacancy}`}</SpanItemLeftStyled>
          <SpanItemLeftStyled>{`Talentos em processo: ${vacancies.talentsInSelection}`}</SpanItemLeftStyled>
          <SpanItemLeftStyled>{`Talentos reprovados: ${vacancies.talentsNotInSelection}`}</SpanItemLeftStyled>
          <SpanLastItemStyled style={{ color: vacancies.isActive === 1 ? '#425AE0' : '#A11F0A' }}>
            {moment(vacancies.openSince).format('DD/MM/YYYY')}
          </SpanLastItemStyled>
        </ListItemStyled>
        // FIXME parece hardcode, verificar
      ) : (
        <ListItemStyled>
          <PictureDiv />
          <SpanItemStyled>Nome</SpanItemStyled>
          <SpanItemStyled>Area</SpanItemStyled>
          <SpanItemStyled>Salario</SpanItemStyled>
          <SpanLastItemStyled>Ver Mais</SpanLastItemStyled>
        </ListItemStyled>
      )}
    </ListStyled>
  );
}

export default TalentItem;
