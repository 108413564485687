import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Formik, Form, Field,
} from 'formik';
import styled from 'styled-components';
import voltarImg from 'assets/Plus.svg';
import { toast } from 'react-toastify';
import { add, update } from '.';

const ContainerForm = styled.div`
  display: flex;
`;

const ThemedTextField = withStyles({
  root: {
    marginLeft: '10%',
    width: '40ch',
    heigth: '49px',
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
        width: '40ch',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
  },
})(TextField);

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    backgroundSize: 'contain',
    height: '49px',
    width: '85px',
    marginLeft: '50px',
    marginTop: '5px',
  },
})(Button);

const ThemedButtonAddCompany = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '14px',
    width: '90px',
    marginLeft: '50px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export default function HardskillAreaForm({ id = null, onCancel = null, onConfirm = null }) {
  const isAddingNew = !id;
  const { hardskillArea, hardskillAreas } = useSelector((
    { entities },
  ) => ({
    hardskillAreas: entities.softskill,
    hardskillArea: !isAddingNew && entities.softskill.byId[id],
  }));
  const dispatch = useDispatch();

  function validateName(value) {
    let validation = null;

    // TODO: i18n('invalid')
    if (!value.length > 0) {
      validation = 'Obrigatório';
    } else if (hardskillAreas.allIds.find(
      (hardskillAreaId) => hardskillAreaId !== id && hardskillAreas.byId[hardskillAreaId].name === value,
    )) {
      validation = 'Já existente';
    }

    return validation;
  }

  function handleCancel(event) {
    if (onCancel) {
      onCancel(event);
    }
  }

  function handleSubmit(/* values: */{ name }, { resetForm }/* , actions */) {
    if (isAddingNew) {
      dispatch(add({ name }))
        .then(() => {
          toast.info('Área de Soft skill criada com sucesso');
          resetForm();
        })
        .catch(() => {
          toast.error('Erro ao criar área de Soft skill');
        });
    } else {
      dispatch(update({ id, name }))
        .then(() => {
          toast.info('Área de Soft skill atualizada com sucesso');
          resetForm();
        })
        .catch(() => {
          toast.error('Erro ao criar área de Soft skill');
        });
    }

    onConfirm();
  }

  return (
    <Formik
      initialValues={{ name: isAddingNew ? '' : hardskillArea.name }}
      onSubmit={handleSubmit}
      onReset={handleCancel}
    >
      {({ errors, dirty }) => (
        <Form autoComplete="off">
          <ContainerForm>
            <Field
              name="name"
              variant="outlined"
              style={{ marginRight: '20px' }}
              as={ThemedTextField}
              type="text"
              placeholder={isAddingNew ? 'Nome da competência' : 'Editar área'}
              validate={validateName}
            />
            {errors.label && dirty ? <div>{errors.label}</div> : null}
            {/* (dirty || !isAddingNew)
              ? <Button style={{ color: '#FFFFFF', marginTop: '2%' }} onClick={resetForm}>Cancelar</Button> : <></> */}
            {
              !isAddingNew ? (
                <ThemedButtonAddCompany
                  type="submit"
                >
                  Salvar
                </ThemedButtonAddCompany>
              )
                : <BackButton type="submit" />
            }
          </ContainerForm>
        </Form>
      )}
    </Formik>
  );
}
