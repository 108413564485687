import { createSlice } from '@reduxjs/toolkit';

import { fetch, fetchById, dropAll } from 'entities/requesters';

const initialState = {
  requesterId: 0,
  totalList: 0,
};

export const requesterSlice = createSlice({
  name: 'requester',
  initialState,
  reducers: {
    setRequesterId: (state, { payload: requesterId }) => {
      state.requesterId = !requesterId ? initialState.requesterId : requesterId;
    },
    setTotalList: (state, { payload: total }) => {
      state.totalList = !total ? initialState.totalList : total;
    },
  },
});

const { setRequesterId, setTotalList } = requesterSlice.actions;

export const fetchRequesterById = (requesterId) => (dispatch) => dispatch(fetchById(requesterId));

export const fetchRequesters = () => (dispatch) => dispatch(fetch());

export const setRequesterChoosedId = (requesterId) => (dispatch) => {
  dispatch(setRequesterId(requesterId));
};

export const fetchRequesterByParams = (requesterParams) => (dispatch) => {
  dispatch(dropAll());
  return dispatch(fetch(requesterParams)).then((res) => {
    dispatch(setTotalList(res.length));
  });
};

export const fetchRequesterByCompanyId = (companyId) => (dispatch) => dispatch(fetch({ 'company.id': companyId }));

export default requesterSlice.reducer;
