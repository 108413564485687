import users from 'entities/users';
import companies from 'entities/companies';
import hardskillAreas from 'entities/hardskillAreas';
import linesofbusiness from 'entities/linesofbusiness';
import specialists from 'entities/specialists';
import requesters from 'entities/requesters';
import areaofpractice from 'entities/areaofpractice';
import softskill from 'entities/softskills';
import talents from 'entities/talents';
import vacancies from 'entities/vacancies';
import favorites from 'entities/favorites';
import cities from 'entities/cities';
import regions from 'entities/regions';

export default {
  users,
  companies,
  hardskillAreas,
  linesofbusiness,
  specialists,
  requesters,
  areaofpractice,
  softskill,
  talents,
  vacancies,
  favorites,
  cities,
  regions,
};
