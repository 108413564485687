function FormatGender(props) {
  let gender = props;

  switch (gender) {
    case 'Masculino':
      gender = 'male';
      break;
    case 'Feminino':
      gender = 'female';
      break;
    case 'Transexual Masculino':
      gender = 'transmasc';
      break;
    case 'Transexual Feminino':
      gender = 'transfem';
      break;
    case 'N/A':
      gender = 'notapplicable';
      break;
    case 'Outro':
      gender = 'other';
      break;
    case 'male':
      gender = 'Masculino';
      break;
    case 'female':
      gender = 'Feminino';
      break;
    case 'transmasc':
      gender = 'Transexual Masculino';
      break;
    case 'transfem':
      gender = 'Transexual Feminino';
      break;
    case 'notapplicable':
      gender = 'N/A';
      break;
    case 'other':
      gender = 'Outro';
      break;
    default:
      break;
  }
  return gender;
}

export default FormatGender;
