import styled from 'styled-components';
import {
  withStyles, Button as MaterialButton, TextField, Checkbox,
} from '@material-ui/core';

import voltarImg from 'assets/Voltar.svg';

export const Container = styled.div`
  padding: 0px var(--horizontal-padding);
  display: flex;
  flex: 1;
  flex-direction: column;
  form {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }
`;

export const ContainerHeader = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

export const ContainerTitulo = styled.div`
    display: flex;
    justify-content: center;
`;

export const TitleRegister = styled.span`
  font-size: 26px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: center;
`;

export const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '85px',
    marginTop: '10px',
  },
})(MaterialButton);

export const ThemedButtonAddCompany = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '200px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(MaterialButton);

export const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
    width: '442px',
  },
})(TextField);

export const ThemedCheckBox = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
  },
  checked: {},
})(Checkbox);
