import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { useStyles } from './styles';

function EditMenu({ onSelect }) {
  const [anchorEl, setAnchorEl] = useState();
  const classes = useStyles();

  function onItemSelect(key) {
    setAnchorEl();
    onSelect(key);
  }

  return (
    <>
      <Button
        classes={{ root: classes.button }}
        endIcon={<Edit />}
        style={{ justifyContent: 'space-between' }}
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Editar
      </Button>
      <Menu
        classes={{
          list: classes.list,
          paper: classes.paper,
        }}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl()}
      >
        <MenuItem classes={{ root: classes.menuItem }} onClick={() => { onItemSelect('ADD'); }}>Adicionar</MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={() => { onItemSelect('DELETE'); }}>Excluir</MenuItem>
      </Menu>
    </>
  );
}

EditMenu.propTypes = {
  onSelect: PropTypes.func,
};

EditMenu.defaultProps = {
  onSelect: () => {},
};

export default EditMenu;
