import React from 'react';

import SetaEmpresas from 'assets/SetaEmpresas.svg';

import {
  Container, Header, Cell, Body, Row, Card,
} from './styles';

function Table({ data, onItemClick }) {
  return (
    <Container>
      <Header>
        <Cell>Nome</Cell>
        <Cell>Vagas abertas</Cell>
        <Cell>Requisitante</Cell>
        <Cell />
      </Header>
      <Body>
        {data.map((item) => (
          <Row key={item.id}>
            <Card>
              <Cell>{item.company.name}</Cell>
              <Cell>{item.company.openVacancies}</Cell>
              <Cell>{item.user.name}</Cell>
              <Cell>
                <button type="button" onClick={() => onItemClick(item.id)}>
                  <img
                    width="16px"
                    height="24px"
                    src={SetaEmpresas}
                    alt="Acessar Empresa"
                  />
                </button>
              </Cell>
            </Card>
          </Row>
        ))}
      </Body>

    </Container>
  );
}

export default Table;
