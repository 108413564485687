/* eslint-disable no-restricted-globals */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import TextNumberFormatCustom from 'components/TextFieldNumber';
import { updateVacancyByPart, fetchNextPage } from '.';

const ComponentTheme = styled.div`
  background: #171836;
`;

const FormDiv = styled.div`
  margin-top: 64px;
`;

const DivButton = styled.div`
  width: 100%;
  text-align: center;
`;

const ThemedTextField = withStyles({
  root: {
    // large labels handling  (https://github.com/mui-org/material-ui/issues/12255#issuecomment-558645344)
    '& label.Mui-focused': {
      color: '#ffffff',
      right: 'unset',
      whiteSpace: 'nowrap',
    },
    '& label': {
      color: '#ffffff',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      right: '22px', // do not overlap icon
      bottom: '0px', // maximize container height so label wont be cut horizontaly
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '100%',
  },
})(TextField);

const useStyles = makeStyles(() => ({
  column: {
    flexDirection: 'column',
    '& > *': {
      margin: '8px 0px',
    },
  },
  icon: {
    color: '#425AE0',
  },
}));

const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '239px',
    margin: '0px 16px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const vacanciesRegisterValidationschema = yup.object().shape({
  shouldNotApproach: yup.string().required('Por favor, insira os concorrentes'),
  whyWorkHere: yup.string(),
  deadline: yup.string().required('Por favor, insira um prazo'),
  shouldApproach: yup.string(),
  additionalQuestions: yup.string(),
  nInterviews: yup.number().min(1, 'Informe um número de entrevistas maior que 0'),
});

const vacanciesRegisterInitialValue = {
  shouldNotApproach: '',
  whyWorkHere: '',
  deadline: '',
  shouldApproach: '',
  additionalQuestions: '',
  nInterviews: '',
};

function VacanciesRegisterPart3({ changePage }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    vacancyId, pageRegister, vacancyAdded, authType,
  } = useSelector(
    ({ features, entities }) => ({
      vacancyId: features.vacancy.vacancyCreatedId,
      pageRegister: features.vacancy.pageRegister,
      vacancyAdded: entities.vacancies,
      authType: features.auth.type,
    }),
  );

  const handleBackPage = () => {
    if (pageRegister === 0) {
      changePage(0);
      // TODO: Criar um dialog padronizado definido pelo UI/UX para usar
      // no lugar dos confirms. Foi necessário inclur eslint disable
    } else if (confirm('Existem informações não salvas, deseja voltar para pagina anterior?')) {
      changePage(0);
    }
  };

  function handleRegisterVacancies(values, { setFieldError, setSubmitting }) {
    dispatch(updateVacancyByPart(vacancyId, values, '3'))
      .then(() => {
        toast.info('Vaga atualizada com sucesso');
        dispatch(fetchNextPage(0));
        changePage(0);
      })
      .catch((rejection) => {
        const { data } = rejection;
        if (data.data) {
          setFieldError(data.data, data.message);
        }
        setSubmitting(false);
        toast.error('Erro ao atualizar Vaga');
      });
  }
  return (
    <ComponentTheme>
      <FormDiv>
        <Formik
          initialValues={vacanciesRegisterInitialValue}
          validationSchema={vacanciesRegisterValidationschema}
          onSubmit={handleRegisterVacancies}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => {
            if (formik.dirty && pageRegister === 0) {
              dispatch(fetchNextPage(1));
            }

            const vacancyUp = vacancyAdded.byId[vacancyId];
            if (vacancyUp) {
              formik.initialValues.shouldNotApproach = vacancyUp.shouldNotApproach;
              formik.initialValues.whyWorkHere = vacancyUp.whyWorkHere;
              formik.initialValues.additionalQuestions = vacancyUp.additionalQuestions;
              formik.initialValues.nInterviews = vacancyUp.nInterviews;
              formik.initialValues.deadline = vacancyUp.deadline ? moment(vacancyUp.deadline).format('YYYY-MM-DD') : '';
              formik.initialValues.shouldApproach = vacancyUp.shouldApproach;
            } else {
              formik.initialValues.shouldNotApproach = '';
              formik.initialValues.whyWorkHere = '';
              formik.initialValues.additionalQuestions = '';
              formik.initialValues.nInterviews = '';
              formik.initialValues.deadline = '';
              formik.initialValues.shouldApproach = '';
            }
            return (
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} justify="center">
                  <Grid className={classes.column} item md={5}>
                    <ThemedTextField
                      id="shouldNotApproach"
                      data-testid="shouldNotApproach-input"
                      label="Concorrentes que não podemos abordar"
                      name="noCompetitors"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.shouldNotApproach ? formik.errors.shouldNotApproach : ''}
                      error={formik.errors.shouldNotApproach && Boolean(formik.errors.shouldNotApproach)}
                      onChange={(e) => {
                        formik.setFieldValue('shouldNotApproach', e.target.value);
                        formik.setFieldError('shouldNotApproach', undefined);
                      }}
                      value={formik.values.shouldNotApproach}
                      multiline
                      rows={3}
                      InputProps={{
                        readOnly: authType === 'requester',
                      }}
                    />
                    <ThemedTextField
                      id="whyWorkHere"
                      data-testid="whyWorkHere-input"
                      label="Por quê um profissional sairia de onde está para trabalhar na empresa?"
                      name="whyWorkHere"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.whyWorkHere ? formik.errors.whyWorkHere : ''}
                      error={formik.errors.whyWorkHere && Boolean(formik.errors.whyWorkHere)}
                      onChange={(e) => {
                        formik.setFieldValue('whyWorkHere', e.target.value);
                        formik.setFieldError('whyWorkHere', undefined);
                      }}
                      value={formik.values.whyWorkHere}
                      multiline
                      rows={3}
                      InputProps={{
                        readOnly: authType === 'requester',
                      }}
                    />
                    <ThemedTextField
                      id="deadline"
                      data-testid="deadline-input"
                      label="Qual o prazo esperado para finalizar o processo?"
                      name="deadline"
                      autoComplete="off"
                      variant="outlined"
                      type="date"
                      helperText={formik.errors.deadline ? formik.errors.deadline : ''}
                      error={formik.errors.deadline && Boolean(formik.errors.deadline)}
                      onChange={(e) => {
                        formik.setFieldValue('deadline', e.target.value);
                        formik.setFieldError('deadline', undefined);
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.deadline}
                      InputProps={{
                        readOnly: authType === 'requester',
                      }}
                    />
                  </Grid>
                  <Grid className={classes.column} item md={5}>
                    <ThemedTextField
                      id="shouldApproach"
                      data-testid="shouldApproach-input"
                      label="Concorrentes que devemos abordar"
                      name="shouldApproach"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.shouldApproach ? formik.errors.shouldApproach : ''}
                      error={formik.errors.shouldApproach && Boolean(formik.errors.shouldApproach)}
                      onChange={(e) => {
                        formik.setFieldValue('shouldApproach', e.target.value);
                        formik.setFieldError('shouldApproach', undefined);
                      }}
                      value={formik.values.shouldApproach}
                      multiline
                      rows={3}
                      InputProps={{
                        readOnly: authType === 'requester',
                      }}
                    />
                    <ThemedTextField
                      id="additionalQuestions"
                      data-testid="additionalQuestions-input"
                      label="Tem alguma pergunta que não fiz e você acha relevante perguntar?"
                      name="additionalQuestions"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.additionalQuestions ? formik.errors.additionalQuestions : ''}
                      error={formik.errors.additionalQuestions && Boolean(formik.errors.additionalQuestions)}
                      onChange={(e) => {
                        formik.setFieldValue('additionalQuestions', e.target.value);
                        formik.setFieldError('additionalQuestions', undefined);
                      }}
                      value={formik.values.additionalQuestions}
                      multiline
                      rows={3}
                      InputProps={{
                        readOnly: authType === 'requester',
                      }}
                    />
                    <ThemedTextField
                      id="nInterviews"
                      data-testid="nInterviews-input"
                      label="Quantos candidatos você espera entrevistar?"
                      name="nInterviews"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.nInterviews ? formik.errors.nInterviews : ''}
                      error={formik.errors.nInterviews && Boolean(formik.errors.nInterviews)}
                      onChange={(e) => {
                        formik.setFieldValue('nInterviews', e.target.value.replace('.', ''));
                        formik.setFieldError('nInterviews', undefined);
                      }}
                      value={formik.values.nInterviews}
                      InputProps={{
                        readOnly: authType === 'requester',
                        inputComponent: TextNumberFormatCustom,
                      }}
                    />
                  </Grid>
                  {
                    authType !== 'requester'
                      ? (
                        <Grid item xs={12}>
                          <DivButton>
                            <ThemedButton disabled={formik.isSubmitting} type="button" onClick={handleBackPage}>
                              Voltar
                            </ThemedButton>
                            <ThemedButton disabled={formik.isSubmitting} type="submit">
                              Salvar
                            </ThemedButton>
                          </DivButton>
                        </Grid>
                      )
                      : <></>
                  }

                </Grid>
              </form>
            );
          }}
        </Formik>
      </FormDiv>
    </ComponentTheme>
  );
}

export default VacanciesRegisterPart3;
