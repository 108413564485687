import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  FormControl,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import TextNumberFormatCustom from 'components/TextFieldNumber';
import * as moment from 'moment';
import * as yup from 'yup';

import {
  addNewVacancy, updateVacancyData, fetchNextPage,
} from '.';

const ComponentTheme = styled.div`
  background: #171836;
`;

const FormDiv = styled.div`
    margin-top: 10%;
    margin-left: 5%;
`;

const DivBox = styled.div`
    padding-top: 32px;
`;

const Title = styled.h2`
  font-size: '26px';
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const DivButton = styled.div`
  width: 100%;
  text-align: center;
`;

const ThemedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.75)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '442px',
    height: '45px',
  },
})(TextField);

const ThemedRadio = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
  },
  checked: {},
})(Radio);

const ThemedFormControlLabel = withStyles({
  root: {
    '& .MuiTypography-root': {
      color: '#FFFFFF',
    },
    marginRight: '100px',
  },
})(FormControlLabel);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '442px',
      flexDirection: 'column',
    },
  },
  icon: {
    color: '#425AE0',
  },
}));

const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '239px',
    marginRight: '10px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const vacanciesRegisterValidationschema = yup.object().shape({
  company: yup.string().required('Por favor, preencha o nome da empresa'),
  name: yup.string().required('Por favor, preencha o nome da vaga'),
  typeVacancies: yup.string(),
  hasConsulting: yup.string().required('Por favor, escolha uma das opções'),
  whichConsulting: yup.string(),
  openSince: yup.string().required('Por favor preencha quando a vaga foi criada'),
  requester: yup.string().required('Por favor, preencha um requisitante'),
  nVacancies: yup.number().min(1, 'Insira um número de vagas maior que 0')
    .required('Por favor, insira o número de vagas'),
  confidential: yup.string(),
  isNewVacancie: yup.string().required('Por favor, escolha uma das opções'),
  problemsWithLastCandidate: yup.string(),
  isPermanent: yup.boolean(),
  hasWorkedWithConsulting: yup.boolean(),
  isConfidential: yup.boolean(),
  isSubstitution: yup.boolean(),
  companyId: yup.number(),
});

const vacanciesRegisterInitialValue = {
  company: '',
  name: '',
  typeVacancies: '',
  hasConsulting: '',
  whichConsulting: '',
  openSince: '',
  requester: '',
  nVacancies: '',
  confidential: '',
  isNewVacancie: '',
  problemsWithLastCandidate: '',
  isPermanent: false,
  hasWorkedWithConsulting: false,
  isConfidential: false,
  isSubstitution: false,
  companyId: 0,
};

function VacanciesRegisterPart1({ changePage }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [disableField, setDisableField] = useState(false);
  const {
    requesterId, requesters, vacancyAdded, vacancyId, pageRegister, authType,
  } = useSelector(
    ({ features, entities }) => ({
      requesterId: features.requester.requesterId,
      requesters: entities.requesters,
      vacancyAdded: entities.vacancies,
      vacancyId: features.vacancy.vacancyCreatedId,
      pageRegister: features.vacancy.pageRegister,
      authType: features.auth.type,
    }),
  );

  useEffect(() => {
    if (authType === 'requester') {
      setDisableField(true);
    }
  }, [dispatch, requesterId, authType]);

  /*  useEffect(() => {
    if (vacancyCreatedObj) {
      dispatch(mergeNewVacancy(vacancyCreatedObj));
    }
  }, [dispatch, vacancyCreatedObj]); */

  const handleRegisterVacancies = (values, { setFieldError, setSubmitting }) => {
    const postObject = JSON.parse(JSON.stringify(values));
    postObject.isPermanent = postObject.typeVacancies === 'Permanente';
    postObject.hasWorkedWithConsulting = postObject.hasConsulting === 'Sim';
    postObject.isConfidential = postObject.confidential === 'Sim';
    postObject.isSubstitution = postObject.isNewVacancie === 'Substituição';
    delete postObject.typeVacancies;
    delete postObject.company;
    delete postObject.requester;
    delete postObject.confidential;
    delete postObject.hasConsulting;
    delete postObject.isNewVacancie;
    if (vacancyId !== 0) {
      dispatch(updateVacancyData(vacancyId, postObject))
        .then(() => {
          toast.info('Vaga atualizada com sucesso');
          changePage(1);
          dispatch(fetchNextPage(0));
        }).catch((rejection) => {
          const { data } = rejection;
          if (data.data) {
            setFieldError(data.data, data.message);
          }
          setSubmitting(false);
          toast.error('Erro ao atualizar vaga');
        });
    } else {
      dispatch(addNewVacancy(postObject))
        .then(() => {
          toast.info('Vaga criada com sucesso');
          changePage(1);
          dispatch(fetchNextPage(0));
        }).catch((rejection) => {
          const { data } = rejection;
          if (data.data) {
            setFieldError(data.data, data.message);
          }
          setSubmitting(false);
          toast.error('Erro ao criar vaga');
        });
    }
  };

  return (
    <ComponentTheme>
      <FormDiv>
        <Formik
          initialValues={vacanciesRegisterInitialValue}
          validationSchema={vacanciesRegisterValidationschema}
          onSubmit={handleRegisterVacancies}
          validateOnChange={false}
        >
          {(formik) => {
            if (formik.dirty === false && pageRegister === 1) {
              dispatch(fetchNextPage(0));
            }

            if (requesters.allIds) {
              const requesterCompany = requesters.byId[requesterId];
              if (requesterCompany) {
                formik.values.company = requesterCompany.company.name;
                formik.values.requester = requesterCompany.user.name;
                formik.values.companyId = requesterCompany.companyId;
              }

              const vacancyUp = vacancyAdded.byId[vacancyId];
              if (vacancyUp) {
                if (formik.dirty && pageRegister === 0) {
                  dispatch(fetchNextPage(1));
                }
                formik.initialValues.name = vacancyUp.name;
                formik.initialValues.typeVacancies = vacancyUp.isPermanent === 1 ? 'Permanente' : 'Temporária';
                formik.initialValues.hasConsulting = vacancyUp.hasWorkedWithConsulting === 1 ? 'Sim' : 'Não';
                formik.initialValues.whichConsulting = vacancyUp.whichConsulting;
                formik.initialValues.openSince = vacancyUp.openSince ? moment(vacancyUp.openSince).format('YYYY-MM-DD') : '';
                formik.initialValues.nVacancies = vacancyUp.nVacancies;
                formik.initialValues.isNewVacancie = vacancyUp.isSubstitution === 1 ? 'Substituição' : 'Nova';
                formik.initialValues.confidential = vacancyUp.isConfidential === 1 ? 'Sim' : 'Não';
                formik.initialValues.problemsWithLastCandidate = vacancyUp.problemsWithLastCandidate;
              } else {
                formik.initialValues.name = '';
                formik.initialValues.typeVacancies = '';
                formik.initialValues.hasConsulting = '';
                formik.initialValues.whichConsulting = '';
                formik.initialValues.openSince = '';
                formik.initialValues.nVacancies = 0;
                formik.initialValues.isNewVacancie = '';
                formik.initialValues.confidential = '';
                formik.initialValues.problemsWithLastCandidate = '';
              }
            }
            return (
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid className={classes.root} item xs={4}>
                    <ThemedTextField
                      id="company"
                      data-testid="company-input"
                      label="Empresa"
                      name="company"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.company ? formik.errors.company : ''}
                      error={formik.errors.company && Boolean(formik.errors.company)}
                      onChange={(e) => {
                        formik.setFieldValue('company', e.target.value); formik.setFieldError('company', undefined);
                      }}
                      value={formik.values.company}
                      InputProps={{
                        readOnly: disableField,
                      }}
                    />
                    <DivBox>
                      <ThemedTextField
                        id="vacancie-name"
                        data-testid="vacancie-name-input"
                        label="Nome da vaga"
                        name="name"
                        autoComplete="off"
                        variant="outlined"
                        helperText={formik.errors.name ? formik.errors.name : ''}
                        error={formik.errors.name && Boolean(formik.errors.name)}
                        onChange={(e) => {
                          formik.setFieldValue('name', e.target.value);
                          formik.setFieldError('name', undefined);
                        }}
                        value={formik.values.name}
                        InputProps={{
                          readOnly: disableField,
                        }}
                      />
                    </DivBox>
                    <DivBox>
                      <Title>Tipo de Vaga</Title>
                    </DivBox>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="typeofVacancies" name="typeVacancies" value={formik.values.typeVacancies}>
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Permanente"
                          onChange={() => formik.setFieldValue('typeVacancies', 'Permanente')}
                          control={<ThemedRadio />}
                          label="Permanente"
                        />
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Temporária"
                          onChange={() => formik.setFieldValue('typeVacancies', 'Temporária')}
                          control={<ThemedRadio />}
                          label="Temporária"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Title>Já trabalhou com consultoria?</Title>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="consultingGroup" name="hasConsulting" value={formik.values.hasConsulting}>
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Sim"
                          onChange={() => {
                            formik.setFieldValue('hasConsulting', 'Sim');
                            formik.setFieldValue('whichConsulting', '');
                          }}
                          control={<ThemedRadio />}
                          label="Sim"
                        />
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Não"
                          onChange={() => formik.setFieldValue('hasConsulting', 'Não')}
                          control={<ThemedRadio />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                    {formik.errors.hasConsulting && (
                    <div
                      style={{
                        color: '#f44336',
                        fontSize: '0.75rem',
                        fontFamily: "'Roboto', sans-serif'",
                      }}
                    >
                      {formik.errors.hasConsulting}
                    </div>
                    )}
                    <ThemedTextField
                      id="whichConsulting"
                      data-testid="consulting-input"
                      label="Qual consultoria?"
                      name="whichConsulting"
                      autoComplete="off"
                      variant="outlined"
                      multiline
                      rows={3}
                      helperText={formik.errors.whichConsulting ? formik.errors.whichConsulting : ''}
                      error={formik.errors.whichConsulting && Boolean(formik.errors.whichConsulting)}
                      onChange={(e) => {
                        formik.setFieldValue('whichConsulting', e.target.value);
                        formik.setFieldError('whichConsulting', undefined);
                      }}
                      value={formik.values.hasConsulting === 'Não' ? '' : formik.values.whichConsulting}
                      InputProps={{
                        readOnly: disableField || formik.values.hasConsulting !== 'Sim',
                      }}
                    />
                    <DivBox>
                      <ThemedTextField
                        id="openSince"
                        data-testid="date-input"
                        label="A vaga está aberta desde quando?"
                        name="openSince"
                        autoComplete="off"
                        variant="outlined"
                        type="date"
                        style={{ marginTop: '50px', marginBottom: '38px' }}
                        helperText={formik.errors.openSince ? formik.errors.openSince : ''}
                        error={formik.errors.openSince && Boolean(formik.errors.openSince)}
                        onChange={(e) => {
                          formik.setFieldValue('openSince', e.target.value);
                          formik.setFieldError('openSince', undefined);
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.openSince}
                        InputProps={{
                          readOnly: disableField,
                        }}
                      />
                    </DivBox>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid className={classes.root} item xs={4}>
                    <ThemedTextField
                      id="requester"
                      data-testid="requester-input"
                      label="Requisitante"
                      name="requester"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.requester ? formik.errors.requester : ''}
                      error={formik.errors.requester && Boolean(formik.errors.requester)}
                      onChange={(e) => {
                        formik.setFieldValue('requester', e.target.value);
                        formik.setFieldError('requester', undefined);
                      }}
                      value={formik.values.requester}
                      InputProps={{
                        readOnly: disableField,
                      }}
                    />
                    <DivBox>
                      <ThemedTextField
                        id="nVacancies"
                        data-testid="vacancie-number-input"
                        label="Número de vagas"
                        name="nVacancies"
                        autoComplete="off"
                        variant="outlined"
                        helperText={formik.errors.nVacancies ? formik.errors.nVacancies : ''}
                        error={formik.errors.nVacancies && Boolean(formik.errors.nVacancies)}
                        onChange={(e) => {
                          formik.setFieldValue('nVacancies', e.target.value.replace('.', ''));
                          formik.setFieldError('nVacancies', undefined);
                        }}
                        value={formik.values.nVacancies}
                        InputProps={{
                          readOnly: disableField,
                          inputComponent: TextNumberFormatCustom,
                        }}
                      />
                    </DivBox>
                    <DivBox>
                      <Title>Vaga confidencial?</Title>
                    </DivBox>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="confidentialGroup" name="confidential" value={formik.values.confidential}>
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Sim"
                          onChange={() => formik.setFieldValue('confidential', 'Sim')}
                          control={<ThemedRadio />}
                          label="Sim"
                        />
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Não"
                          onChange={() => formik.setFieldValue('confidential', 'Não')}
                          control={<ThemedRadio />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Title>A vaga é nova ou substituição?</Title>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="newVacancieGroup"
                        name="isNewVacancie"
                        value={formik.values.isNewVacancie}
                      >
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Nova"
                          onChange={() => {
                            formik.setFieldValue('isNewVacancie', 'Nova');
                            formik.setFieldValue('problemsWithLastCandidate', '');
                          }}
                          control={<ThemedRadio />}
                          label="Nova"
                        />
                        <ThemedFormControlLabel
                          disabled={disableField}
                          value="Substituição"
                          onChange={() => formik.setFieldValue('isNewVacancie', 'Substituição')}
                          control={<ThemedRadio />}
                          label="Substituição"
                        />
                      </RadioGroup>
                    </FormControl>
                    {formik.errors.isNewVacancie && (
                    <div
                      style={{
                        color: '#f44336',
                        fontSize: '0.75rem',
                        fontFamily: "'Roboto', sans-serif'",
                      }}
                    >
                      {formik.errors.isNewVacancie}
                    </div>
                    )}
                    <ThemedTextField
                      id="problemsWithLastCandidate"
                      data-testid="problem-input"
                      label="Qual era o problema com o antigo candidato?"
                      name="problemsWithLastCandidate"
                      autoComplete="off"
                      variant="outlined"
                      multiline
                      rows={3}
                      helperText={formik.errors.problemsWithLastCandidate
                        ? formik.errors.problemsWithLastCandidate : ''}
                      error={formik.errors.problemsWithLastCandidate
                          && Boolean(formik.errors.problemsWithLastCandidate)}
                      onChange={(e) => {
                        formik.setFieldValue('problemsWithLastCandidate', e.target.value);
                        formik.setFieldError('problemsWithLastCandidate', undefined);
                      }}
                      value={formik.values.isNewVacancie !== 'Substituição'
                        ? '' : formik.values.problemsWithLastCandidate}
                      InputProps={{
                        readOnly: disableField || formik.values.isNewVacancie !== 'Substituição',
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <DivButton>
                      {
                        authType !== 'requester'
                          ? (
                            <ThemedButton disabled={formik.isSubmitting} type="submit">
                              Próximo
                            </ThemedButton>
                          )
                          : <></>
                      }
                    </DivButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </FormDiv>
    </ComponentTheme>
  );
}

export default VacanciesRegisterPart1;
