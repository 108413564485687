import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';

import store from 'app/store';
import Toast from 'components/ToastContainerCustom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './app/sentryConfig';
import 'app/hotjarConfig';

const ErrorWrapper = process.env.REACT_APP_ENV === 'production' ? Sentry.ErrorBoundary : Fragment;

ReactDOM.render(
  <React.StrictMode>
    <ErrorWrapper fallback="Ocorreu um erro">
      <Provider store={store}>
        <Toast />
        <App />
      </Provider>
    </ErrorWrapper>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
