/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  button: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '15px',
    letterSpacing: '0px',
    alignSelf: 'center',
    padding: '6px 14px',
    width: '140px',
    maxWidth: '100%',
    textTransform: 'capitalize',
  },
  list: {
    backgroundColor: '#171836',
    padding: '0px',
  },
  paper: {
    backgroundColor: '#171836',
    width: '138px',
    border: 'solid 1px #425AE0',
    marginTop: '37px',
  },
  menuItem: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&:selected': {
      backgroundColor: 'black',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
  },
});

/* eslint-enable import/prefer-default-export */
