import React from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const TitleRegister = styled.h2`
  font-size: "26px";
  font-weight: bolder;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const TitleRegisterAccordion = styled.h3`
  font-size: "26px";
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const ContainerTextFields = styled.div`
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10vh;
`;

const ThemedCheckBox = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
  },
  checked: {},
})(MaterialCheckbox);

const ThemedAccordion = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root': {
      padding: '0px',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
      height: '100%',
      width: '100%',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '9%',
    },
    '&$expanded': {
      margin: '0px',
    },
    boxShadow: 'none',
  },
  expanded: {},
})(Accordion);

export default function FilterHardskill({ onChange, value }) {
  const areaOfPractice = useSelector((state) => state.entities.areaofpractice);

  return (
    <div>
      <ContainerTextFields>
        <TitleRegister>Area de atuação</TitleRegister>
      </ContainerTextFields>
      {
                areaOfPractice.allIds ? areaOfPractice.allIds.map((skill) => {
                  const areaOfPrcticeItem = areaOfPractice.byId[skill];
                  return (
                    <ThemedAccordion key={areaOfPrcticeItem.id}>
                      <AccordionSummary
                        expandIcon={(
                          <ExpandMoreIcon
                            style={{ color: 'white', marginLeft: '50px' }}
                          />
                    )}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <TitleRegisterAccordion>
                          {areaOfPrcticeItem.name}
                        </TitleRegisterAccordion>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          {areaOfPrcticeItem ? (
                            areaOfPrcticeItem.areasOfPractice.map((linha) => (
                              <Grid item xs={3}>

                                <FormControl>
                                  <FormGroup>
                                    <FormControlLabel
                                      style={{ color: 'white', width: '100%' }}
                                      control={(
                                        <ThemedCheckBox
                                          value={linha.id}
                                          checked={value.includes(linha.id)}
                                          name={linha.id}
                                          onChange={onChange}
                                        />
                                      )}
                                      label={linha.name}
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                            ))
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </ThemedAccordion>
                  );
                })
                  : <></>
                  }
    </div>
  );
}
