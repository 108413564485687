import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik, FieldArray } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import TextFieldMask from 'components/TextFieldMaks';
import voltarImg from 'assets/Voltar.svg';
// import FormikField from '../../components/FormikField';
import { createPassword } from 'features/auth';
import { toast } from 'react-toastify';
import { fetchLinesOfBusinessAvailables } from '../linesofbusiness';
import { addNewCompany, fetchCompanyById, updateCompanyData } from '.';

const FormContainer = styled.div`
  margin-top: 2%;
  margin-left: 80px;
  margin-right: 96px;
  display: flex;
  justify-content: center;
`;

const ComponentTheme = styled.div`
  background: #171836;
`;

const TitleRegister = styled.h2`
  font-size: '26px';
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const ButtonHeader = styled.div``;

const ButtonFooter = styled.div`
  padding: 1%;
  display: flex;
  justify-content: center;
`;

const BusinessLineList = styled.div`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
`;

const ButtonBox = styled.div`
  display: flex;
  margin-top: 3%;
  justify-content: center;
`;

const ThemedButtonAddCompany = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '239px',
  },
  label: {
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: '0.3',
    color: '#FFFFFF !important',
  },
})(Button);

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '70px',
    marginLeft: '48px',
    marginTop: '24px',
  },
})(Button);

const ThemedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
  },
})(TextField);

const ThemedCheckBox = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
  },
  checked: {},
})(Checkbox);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
      width: '442px',
      flexDirection: 'column',
    },
  },
}));

const companyRegisterValidationschema = yup.object({
  name: yup.string().required('Por favor, preencha o nome da empresa'),
  lineOfBusiness: yup.array().min(1, 'Escolha pelo menos uma linha de negócio'),
  website: yup.string().url('Por favor, digite uma URL válida'),
  context: yup
    .string()
    .max(
      1000,
      'Por favor, digite um cenário da empresa com menos de 1000 caracteres',
    ),
  job: yup.string(),
  phoneNumber: yup.string(),
  namereq: yup.string().required('Por favor, digite um nome de requisitante'),
  email: yup.string().email('Por favor, digite um e-mail válido').required('Por favor, digite um e-mail'),
});

const companyRegisterInitialValue = {
  name: '',
  website: '',
  context: '',
  job: '',
  phoneNumber: '',
  email: '',
  namereq: '',
  lineOfBusiness: [],
  isActive: true,
};

function CompanyRegister({ location }) {
  const isAdd = location.state !== undefined ? location.state.id : 0;
  const requesterId = location.state !== undefined ? location.state.idrequester : 0;
  const { businessLine } = useSelector(({ entities }) => ({
    businessLine: entities.linesofbusiness,
  }));
  const { companyInfo } = useSelector(({ entities }) => ({
    companyInfo: entities.companies,
  }));
  const { requesterInfo } = useSelector(({ entities }) => ({
    requesterInfo: entities.requesters,
  }));
  const [buttonText, setButtonText] = useState('Adicionar Empresa');

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [linesOfBusiness, setLinesOfBusiness] = useState(null);

  useEffect(() => {
    dispatch(fetchLinesOfBusinessAvailables()).then((linesofbusiness) => {
      setLinesOfBusiness(linesofbusiness);
    });

    if (isAdd !== 0) {
      setButtonText('Atualizar Empresa');
      dispatch(fetchCompanyById(isAdd));
    }
  }, [dispatch, isAdd]);

  async function handleNewCompany(values, { setFieldError, setSubmitting }) {
    setSubmitting(true);
    const dataInsert = values;

    if (isAdd !== 0) {
      const prevEmail = requesterInfo.byId[requesterId].user.email.toLowerCase();
      const newEmail = dataInsert.email.toLowerCase();
      await dispatch(updateCompanyData(isAdd, { ...dataInsert, requesterId })).then(() => {
        if (prevEmail !== newEmail) dispatch(createPassword(dataInsert.email));
        toast.info('Empresa atualizada com sucesso');
        history.goBack();
      }).catch((rejection) => {
        const { data } = rejection;
        if (data.data) {
          setFieldError(data.data, data.message);
        } else {
          toast.error('Erro ao atualizar Empresa');
        }
      });
    } else {
      await dispatch(addNewCompany(dataInsert)).then(() => {
        dispatch(createPassword(dataInsert.email));
        history.goBack();
        // setSubmitting(false);
        toast.info('Empresa adicionada com sucesso');
      }).catch((rejection) => {
        const { data } = rejection;
        if (data.data) {
          setFieldError(data.data, data.message);
        } else {
          toast.error('Erro ao adicionar Empresa');
        }
      });
    }
  }

  return (
    <ComponentTheme>
      <ButtonHeader>
        <BackButton onClick={history.goBack} />
      </ButtonHeader>
      <FormContainer>
        <Formik
          // enableReinitialize={true}
          initialValues={companyRegisterInitialValue}
          validationSchema={companyRegisterValidationschema}
          onSubmit={handleNewCompany}
          validateOnChange={false}
        >
          {(formik) => {
            if (isAdd !== 0) {
              if (companyInfo !== undefined && requesterInfo !== undefined) {
                const companyAtt = companyInfo.byId[isAdd];
                const requesterAtt = requesterInfo.byId[requesterId];
                if (companyAtt !== undefined && requesterAtt !== undefined) {
                  formik.initialValues.context = companyAtt.context;
                  formik.initialValues.job = requesterAtt.job;
                  formik.initialValues.name = companyAtt.name;
                  formik.initialValues.website = companyAtt.website;
                  formik.initialValues.namereq = requesterAtt.user.name;
                  formik.initialValues.phoneNumber = requesterAtt.user.phoneNumber;
                  formik.initialValues.email = requesterAtt.user.email;
                  formik.initialValues.lineOfBusiness = [];
                  if (companyAtt.companyLineOfBusiness) {
                    companyAtt.companyLineOfBusiness.map((item) => {
                      if (!formik.initialValues.lineOfBusiness.includes(item.lineOfBusinessId)) {
                        formik.initialValues.lineOfBusiness.push(item.lineOfBusinessId);
                      }
                      return item;
                    });
                  }
                }
              }
            } else {
              companyRegisterInitialValue.lineOfBusiness = [];
              companyRegisterInitialValue.context = '';
              companyRegisterInitialValue.email = '';
              companyRegisterInitialValue.job = '';
              companyRegisterInitialValue.name = '';
              companyRegisterInitialValue.namereq = '';
              companyRegisterInitialValue.phoneNumber = '';
              companyRegisterInitialValue.website = '';
            }
            return (
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid className={classes.root} item xs={4}>
                    <TitleRegister style={{ marginBottom: '35px' }}>
                      Empresa
                    </TitleRegister>
                    <ThemedTextField
                      id="name"
                      data-testid="name-input"
                      label="Nome"
                      name="name"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.name ? formik.errors.name : ''}
                      error={formik.errors.name && Boolean(formik.errors.name)}
                      onChange={(e) => {
                        formik.setFieldValue('name', e.target.value); formik.setFieldError('name', undefined);
                      }}
                      value={formik.values.name}
                    />
                    <TitleRegister
                      style={{ marginTop: '35px', marginBottom: '24px' }}
                    >
                      Linha de negócios
                    </TitleRegister>
                    <FieldArray
                      name="lineOfBusiness"
                      render={(arrayHelpers) => (
                        <BusinessLineList>
                          {linesOfBusiness ? (
                            linesOfBusiness.map((linha) => {
                              const lineBusiness = businessLine.byId[linha];
                              return (
                                <div>
                                  <FormControlLabel
                                    value={linha.id}
                                    style={{ color: 'white' }}
                                    control={(
                                      <ThemedCheckBox
                                        checked={formik.values.lineOfBusiness.includes(
                                          lineBusiness.id,
                                        )}
                                        onChange={(e) => {
                                          formik.setFieldError('lineOfBusiness', undefined);
                                          if (e.target.checked) arrayHelpers.push(lineBusiness.id);
                                          else {
                                            const idx = formik.values.lineOfBusiness.indexOf(
                                              lineBusiness.id,
                                            );
                                            arrayHelpers.remove(idx);
                                          }
                                        }}
                                      />
                                    )}
                                    label={lineBusiness.name}
                                    labelPlacement="end"
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </BusinessLineList>
                      )}
                    />
                    {formik.errors.lineOfBusiness && (
                      <div
                        style={{
                          color: '#f44336',
                          fontSize: '0.75rem',
                          fontFamily: "'Roboto', sans-serif'",
                        }}
                      >
                        {formik.errors.lineOfBusiness}
                      </div>
                    )}
                    <ThemedTextField
                      id="website-text"
                      data-testid="website-input"
                      name="website"
                      label="Web site"
                      autoComplete="off"
                      variant="outlined"
                      helperText={
                        formik.errors.website ? formik.errors.website : ''
                      }
                      error={
                        formik.errors.website && Boolean(formik.errors.website)
                      }
                      onChange={(e) => {
                        formik.setFieldValue('website', e.target.value); formik.setFieldError('website', undefined);
                      }}
                      value={formik.values.website}
                    />
                  </Grid>
                  <Grid item xs={2} />
                  <Grid className={classes.root} item xs={4}>
                    <ThemedTextField
                      id="context-text"
                      data-testid="context-input"
                      multiline
                      label="Cenário da empresa"
                      name="context"
                      autoComplete="off"
                      variant="outlined"
                      helperText={
                        formik.errors.context ? formik.errors.context : ''
                      }
                      error={
                        formik.errors.context && Boolean(formik.errors.context)
                      }
                      onChange={(e) => {
                        formik.setFieldValue('context', e.target.value); formik.setFieldError('context', undefined);
                      }}
                      value={formik.values.context}
                      rows={7}
                    />
                    <TitleRegister
                      style={{ marginTop: '30px', marginBottom: '38px' }}
                    >
                      Requisitante
                    </TitleRegister>
                    <ThemedTextField
                      id="namereq-text"
                      data-testid="namereq-input"
                      label="Nome"
                      name="namereq"
                      autoComplete="off"
                      variant="outlined"
                      helperText={
                        formik.errors.namereq ? formik.errors.namereq : ''
                      }
                      error={
                        formik.errors.namereq && Boolean(formik.errors.namereq)
                      }
                      onChange={(e) => {
                        formik.setFieldValue('namereq', e.target.value);
                        formik.setFieldError('namereq', undefined);
                      }}
                      value={formik.values.namereq}
                    />
                    <ThemedTextField
                      id="role-text"
                      data-testid="role-input"
                      label="Cargo"
                      name="job"
                      autoComplete="off"
                      variant="outlined"
                      helperText={formik.errors.job ? formik.errors.job : ''}
                      error={formik.errors.job && Boolean(formik.errors.job)}
                      onChange={(e) => {
                        formik.setFieldValue('job', e.target.value); formik.setFieldError('job', undefined);
                      }}
                      value={formik.values.job}
                    />
                    {formik.errors.job && (
                      <div id="feedback">{formik.errors.job}</div>
                    )}
                    <ThemedTextField
                      id="email-text"
                      label="Email"
                      name="email"
                      autoComplete="off"
                      variant="outlined"
                      helperText={
                        formik.errors.email ? formik.errors.email : ''
                      }
                      error={
                        formik.errors.email && Boolean(formik.errors.email)
                      }
                      onChange={(e) => {
                        formik.setFieldValue('email', e.target.value);
                        formik.setFieldError('email', undefined);
                      }}
                      value={formik.values.email}
                    />
                    <ThemedTextField
                      id="phoneNumber-text"
                      data-testid="phoneNumber-input"
                      label="Telefone"
                      name="phoneNumber"
                      autoComplete="off"
                      variant="outlined"
                      helperText={
                        formik.errors.phoneNumber
                          ? formik.errors.phoneNumber
                          : ''
                      }
                      error={
                        formik.errors.phoneNumber
                        && Boolean(formik.errors.phoneNumber)
                      }
                      onChange={(e) => {
                        formik.setFieldValue('phoneNumber', e.target.value);
                        formik.setFieldError('phoneNumber', undefined);
                      }}
                      value={formik.values.phoneNumber}
                      InputProps={{
                        inputComponent: TextFieldMask,
                      }}
                    />
                  </Grid>
                  <Grid style={{ marginTop: '70px' }} item xs={10}>
                    <ButtonBox>
                      <ThemedButtonAddCompany disabled={formik.isSubmitting} type="submit">
                        {buttonText}
                      </ThemedButtonAddCompany>
                    </ButtonBox>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </FormContainer>
      <ButtonFooter />
    </ComponentTheme>
  );
}

export default CompanyRegister;
