import styled from 'styled-components';

export const ContainerHeader = styled.div`
    padding: 0px var(--horizontal-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
`;

export const TitleRegister = styled.h2`
    font-size: '26px';
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    opacity: 1;
    text-align: left;
    margin: 0 18px 0 0;
`;
