import React from 'react';
import { Radio, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ThemedRadio = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
  },
  checked: {},
})(Radio);

const ThemedFormControlLabel = withStyles({
  root: {
    '& .MuiTypography-root': {
      color: '#FFFFFF',
    },
    marginRight: '100px',
  },
})(FormControlLabel);

export default function SoftSkillLevelRadioButton({ areas, disabled }) {
  return (
    <div>
      <ThemedFormControlLabel
        disabled={disabled}
        value={areas.id.toString()}
        control={<ThemedRadio />}
        label={areas.level}
      />
    </div>
  );
}
