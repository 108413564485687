import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as yup from 'yup';
import { InputAdornment } from '@material-ui/core';

import NumericInput from './NumericInput';

import {
  DivButton, ContainerTitle, TitleRegister, SubTitleRegister, FormContainer,
  ThemedButton, ThemedTextField,
} from './styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '23px',
  },
  paper: {
    backgroundColor: '#171836',
    border: '2px solid #425AE0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '23px',
    width: '500px',
    maxWidth: '100%',
  },
}));

const placementValidationschema = yup.object({
  closeValue: yup.number().required('Por favor, preencha o valor do salário definido')
    .moreThan(0, 'O valor deve ser maior que zero'),
});

const placementInitialValue = {
  closeValue: '',
};

export default function TransitionsModal({
  open, close, sendPlacement, talentName,
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <ContainerTitle>
              <TitleRegister>
                {`Deseja realmente mudar o talento ${talentName} para a coluna de Placements?`}
              </TitleRegister>

            </ContainerTitle>
            <ContainerTitle>
              <SubTitleRegister>
                Uma das vagas abertas será preenchida
              </SubTitleRegister>
            </ContainerTitle>
            <ContainerTitle style={{ marginTop: 18 }}>
              <TitleRegister>
                Valor de fechamento
              </TitleRegister>
            </ContainerTitle>
            <Formik
              initialValues={placementInitialValue}
              validationSchema={placementValidationschema}
              onSubmit={sendPlacement}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormContainer>
                    <ThemedTextField
                      id="closeValue"
                      data-testid="closeValue-input"
                      label="Valor"
                      name="closeValue"
                      value={formik.values.closeValue}
                      onChange={(value) => formik.setFieldValue('closeValue', value)}
                      autoComplete="off"
                      variant="outlined"
                      helperText={
                          formik.errors.closeValue ? formik.errors.closeValue : ''
                        }
                      error={
                          formik.errors.closeValue && Boolean(formik.errors.closeValue)
                        }
                      InputProps={{
                        startAdornment: (<InputAdornment position="start">R$</InputAdornment>),
                        inputComponent: NumericInput,
                      }}
                    />

                  </FormContainer>
                  <DivButton>
                    <ThemedButton type="submit">
                      Sim
                    </ThemedButton>
                    <ThemedButton onClick={close} type="button">
                      Não
                    </ThemedButton>
                  </DivButton>
                </form>
              )}
            </Formik>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
