import styled from 'styled-components';
import { withStyles, Button as MaterialButton, TextField } from '@material-ui/core';

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
  button {
    &:last-child {
      margin: 0;
    }
  }
`;

export const ContainerTitle = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const TitleRegister = styled.span`
  font-size: 26px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: center;
`;

export const SubTitleRegister = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  width: 100%;
  margin-top: 18px;
`;

export const FormContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

export const ThemedButton = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '50%',
    marginRight: '10px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(MaterialButton);

export const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputAdornment-root .MuiTypography-root ': {
      color: '#fff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
    width: '100%',
  },
})(TextField);
