import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formatToCurrency } from 'utils/numberFormat';
import Person from 'assets/Person.svg';

import DisapproveModal from '../DisapproveModal';

import {
  Card, CardContent, Row, Avatar, Name, Salary, ActionButton,
} from './styles';

function KanbanCard({
  showActionButton, onDisapprove, onReapprove, data, data: {
    isActive,
    talentInfo: {
      id, intendedSalary, name, pictureLink,
    },
  },
}) {
  const [img, setImg] = useState(pictureLink);
  const [showDisapproveConfirmation, setShowDisapproveConfirmation] = useState(false);

  function onConfirmDisapprove(reason) {
    setShowDisapproveConfirmation(false);
    onDisapprove(reason, data);
  }

  return (
    <>
      <Card to={`/talents/edit/${id}`}>
        <CardContent isActive={isActive}>
          <Row>
            <Avatar src={img} onError={() => setImg(Person)} />
            <Name>{name}</Name>
          </Row>
          <Row style={{ justifyContent: 'space-between', marginTop: 8 }}>
            <Salary>{intendedSalary ? formatToCurrency(intendedSalary) : 'Não informado'}</Salary>
            {showActionButton
              ? (
                <ActionButton onClick={(e) => {
                  e.preventDefault();
                  if (isActive) setShowDisapproveConfirmation(true);
                  else onReapprove(data);
                }}
                >
                  {isActive ? 'Reprovar' : 'Reaprovar'}
                </ActionButton>
              ) : null}
          </Row>
        </CardContent>
      </Card>
      <DisapproveModal
        visible={showDisapproveConfirmation}
        onClose={() => setShowDisapproveConfirmation(false)}
        onConfirm={(reason) => onConfirmDisapprove(reason)}
      />
    </>
  );
}

KanbanCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    pictureLink: PropTypes.string,
    intendedSalary: PropTypes.number,
  }).isRequired,
  showActionButton: PropTypes.bool,
  onDisapprove: PropTypes.func,
  onReapprove: PropTypes.func,
};

KanbanCard.defaultProps = {
  showActionButton: true,
  onDisapprove: () => { },
  onReapprove: () => { },
};

export default KanbanCard;
