function FormatExpertise(props) {
  let expertise = props;

  switch (expertise) {
    case 'Tecnologia':
      expertise = 'tech';
      break;
    case 'Digital':
      expertise = 'digital';
      break;
    case 'Vendas':
      expertise = 'sales';
      break;
    case 'tech':
      expertise = 'Tecnologia';
      break;
    case 'digital':
      expertise = 'Digital';
      break;
    case 'sales':
      expertise = 'Vendas';
      break;
    default:
      break;
  }
  return expertise;
}

export default FormatExpertise;
