import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { favoriteTalent, removeFavoriteTalent } from 'features/favorite';

import TalentCard from 'components/TalentCard';

function FavoriteItem(props) {
  const { favorites } = props;
  const { vacancyId } = props;
  const { pageList } = props;
  const { totalList } = props;
  const { authType } = props;
  const [isFavorite, setIsFavorite] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
  }, [dispatch, pageList, vacancyId]);

  function handleClickIcon() {
    const { talentId } = favorites;
    const { id } = favorites;
    const data = {
      id,
      talentId,
      vacancyId,
      totalList,
    };

    if (isFavorite) {
      dispatch(removeFavoriteTalent(data)).then(() => {
        toast.info('Talento removido dos favoritos');
      })
        .catch(() => {
          toast.error('Erro ao remover Talento dos favoritos');
        });
      setIsFavorite(null);
    } else {
      dispatch(favoriteTalent(data)).then(() => {
        toast.info('Talento adicionado aos favoritos');
      })
        .catch(() => {
          toast.error('Erro ao adicionar Talento aos favoritos');
        });
      setIsFavorite(1);
    }
  }

  return (
    <TalentCard
      authType={authType}
      talent={favorites?.talentInfo}
      vacancyId={vacancyId}
      onFavoriteClick={handleClickIcon}
      isFavorite={Boolean(isFavorite)}
    />
  );
}
export default FavoriteItem;
