import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';

import './App.scss';

export default function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}
