import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Formik } from 'formik';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { fetchRequesterById } from 'features/requester';
import { fetchCompanyByParams, setCompanyIdChoosed } from 'features/company';
import { sendTalent, fetchVacancyByCompanyNonPaginated } from 'features/vacancy';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import voltarImg from 'assets/Voltar.svg';
import * as yup from 'yup';

const ContainerHeader = styled.div`
  display: inline-flex;
  width: 100%;
`;

const FormContainer = styled.div`
  margin-top: 2%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: center;
`;

const LabelContainer = styled.div`
  margin-top: 9%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
`;

const ContainerTitulo = styled.div`
    flex: 50%;
    display: inline;
`;
const TitleRegister = styled.span`
  font-size: 26px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: center;
`;

const BackButton = withStyles({
  root: {
    backgroundImage: `url(${voltarImg})`,
    height: '34px',
    width: '85px',
    marginTop: '10px',
  },
})(Button);

const ThemedButtonAddCompany = withStyles({
  root: {
    background: '#171836',
    color: '#FFFFFF',
    borderColor: '#425AE0',
    border: '1px solid',
    fontSize: '20px',
    letterSpacing: '0px',
    width: '200px',
    height: '50%',
    marginRight: '10%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const ThemedTextField = withStyles({
  root: {
    '& .PrivateNotchedOutline-root-3': {
      borderRadius: '6px',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'red',
    },
    '& .MuiSelect-icon': {
      color: '#425AE0',
    },
    width: '442px',
    height: '45px',
  },
})(TextField);

const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: 'black',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
  },
})(MenuItem);

const MuiListThem = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#171836',
      },
    },
  },
});

const sendTalentValidationSchema = yup.object().shape({
  vacancyId: yup.string().required('Necessário informar a vaga'),
  talentId: yup.number(),
  company: yup.string().required('Necessário informar a empresa'),
  vacancy: yup.string(),
});

const vacanciesRegisterInitialValue = {
  vacancyId: '',
  talentId: '',
  company: '',
  vacancy: '',
};

function VacancySending() {
  const history = useHistory();
  const { id: talentId } = useParams();
  const dispatch = useDispatch();
  const {
    talents, requesters, vacancyAdded, authType, user, companies, vacancyId, companyId,
  } = useSelector(
    ({ features, entities }) => ({
      talents: entities.talents,
      requesters: entities.requesters,
      companies: entities.companies,
      companyId: features.company.companyId,
      vacancyAdded: entities.vacancies,
      vacancyId: features.vacancy.vacancyCreatedId,
      talentId: features.talent.talentCreatedId,
      authType: features.auth.type,
      user: features.auth.user,
    }),
  );

  useEffect(() => {
    if (!talents.byId[talentId]) history.replace(`/talents/edit/${talentId}`);
  }, []);

  useEffect(() => {
    if (authType) {
      if (authType === 'requester') {
        dispatch(fetchRequesterById(user.id));
      } else {
        dispatch(fetchCompanyByParams({ isActive: 1 }));
      }
    }
    // eslint-disable-next-line
  }, [dispatch, authType]);

  const handleCompanyChange = (selectedCompanyId) => {
    dispatch(fetchVacancyByCompanyNonPaginated(selectedCompanyId));
    dispatch(setCompanyIdChoosed(selectedCompanyId));
  };

  useEffect(() => {
    if (authType && authType === 'requester' && requesters.byId[user?.id]) {
      handleCompanyChange(requesters.byId[user?.id].companyId);
    }
  }, [requesters.byId, authType, user?.id]);

  const handleSendTalent = (values) => {
    const data = {
      talentId: values.talentId,
      vacancyId: Number(values.vacancyId),
    };
    dispatch(sendTalent(data))
      .then(() => {
        toast.info('Talento enviado com sucesso para a vaga');
        history.goBack();
      })
      .catch((res) => {
        toast.error(res?.data?.displayMessage || 'Erro ao enviar Talento para a vaga');
      });
  };

  return (
    <div style={{ marginTop: '2%' }}>
      <ContainerHeader>
        <BackButton onClick={history.goBack} />
        <ContainerTitulo style={{ marginLeft: '20%', marginTop: '1%' }}>
          <TitleRegister>
            Para qual vaga você quer encaminhar o talento?
          </TitleRegister>
        </ContainerTitulo>
      </ContainerHeader>
      <Formik
        onSubmit={handleSendTalent}
        validationSchema={sendTalentValidationSchema}
        initialValues={vacanciesRegisterInitialValue}
        validateOnChange={false}
      >
        {(formik) => {
          const vacancySelected = vacancyAdded.byId[vacancyId];

          if (authType === 'requester') {
            const companyVacancy = requesters.byId[user?.id];
            if (companyVacancy) {
              formik.initialValues.company = companyVacancy.company.name;
            }
          } else {
            const companyVacancy = companies.byId[companyId];
            if (companyVacancy) {
              formik.initialValues.company = companyVacancy.id;
            }
          }

          if (vacancySelected) {
            formik.initialValues.vacancy = vacancySelected.name;
            formik.initialValues.vacancyId = vacancySelected.id;
          }
          formik.initialValues.talentId = talentId;

          return (
            <form onSubmit={formik.handleSubmit}>
              <LabelContainer>
                <TitleRegister>
                  Empresa
                </TitleRegister>
              </LabelContainer>
              <FormContainer>
                {
                authType === 'requester'
                  ? (
                    <ThemedTextField
                      id="name"
                      data-testid="name-input"
                      label="Nome"
                      name="name"
                      value={formik.initialValues.company}
                      autoComplete="off"
                      variant="outlined"
                    />
                  )
                  : (
                    <MuiThemeProvider theme={MuiListThem}>
                      <ThemedTextField
                        id="company"
                        data-testid="company"
                        label="Empresa"
                        name="company"
                        autoComplete="off"
                        variant="outlined"
                        helperText={formik.errors.company ? formik.errors.company : ''}
                        error={formik.errors.company && Boolean(formik.errors.company)}
                        onChange={(e) => {
                          formik.setFieldValue('company', e.target.value);
                          formik.setFieldValue('vacancyId', '');
                          formik.setFieldError('company', undefined);
                          handleCompanyChange(e.target.value);
                        }}
                        value={formik.values.company}
                        select
                      >
                        {
                          companies.allIds ? companies.allIds.map((item) => {
                            const companyItem = companies.byId[item];
                            return (
                              <ThemedMenuItem
                                key={companyItem.id}
                                value={companyItem.id}
                              >
                                {companyItem.name}
                              </ThemedMenuItem>
                            );
                          })
                            : <ThemedMenuItem key="na1" value="NA">NA</ThemedMenuItem>
                        }
                      </ThemedTextField>
                    </MuiThemeProvider>
                  )
              }
              </FormContainer>
              <LabelContainer>
                <TitleRegister>
                  Vaga
                </TitleRegister>
              </LabelContainer>
              <FormContainer>
                <MuiThemeProvider theme={MuiListThem}>
                  <ThemedTextField
                    id="vacancyId"
                    data-testid="vacancy-input"
                    label="Vaga"
                    name="vacancyId"
                    value={formik.values.vacancyId}
                    autoComplete="off"
                    variant="outlined"
                    helperText={formik.errors.vacancyId ? formik.errors.vacancyId : ''}
                    error={formik.errors.vacancyId && Boolean(formik.errors.vacancyId)}
                    onChange={(e) => {
                      formik.setFieldValue('vacancyId', e.target.value);
                      formik.setFieldError('vacancyId', undefined);
                    }}
                    select
                  >
                    {
                          vacancyAdded.allIds ? vacancyAdded.allIds.map((item) => {
                            const vacancyItem = vacancyAdded.byId[item];
                            return (
                              <ThemedMenuItem
                                key={vacancyItem.id}
                                value={vacancyItem.id}
                              >
                                {vacancyItem.name}
                              </ThemedMenuItem>
                            );
                          })
                            : <ThemedMenuItem key="na" value="NA">NA</ThemedMenuItem>
                        }
                  </ThemedTextField>
                </MuiThemeProvider>
              </FormContainer>
              <ButtonContainer>
                <ThemedButtonAddCompany type="submit">
                  Enviar
                </ThemedButtonAddCompany>
              </ButtonContainer>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default VacancySending;
