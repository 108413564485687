import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';

import { TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';

import { login, LoadingSteps as AuthLoadingSteps } from 'features/auth';

import './Login.module.scss';

import logo from 'assets/Jobler.svg';
import fundo from 'assets/J.svg';

const Background = styled.div`
    background: transparent linear-gradient(180deg, #0A0B3A 0%, #425AE0 67%, #4662FF 100%);
    opacity: 1;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top:0;
  left:0;
  background-repeat: no-repeat;
`;

const BgImage = styled.div`
    background: url(${fundo});
    height: 50%;
    width: 100%;
    position: fixed;
    background-size: cover;
    
`;

const DivForm = styled.div`
    top:30%;
    left:11.49%;
    width: 32.35%;
    position: fixed;
`;

const LabelLogin = styled.div`
    
    width: 148px;
    height: 56px;
`;

const TitleLogin = styled.h1`
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 400;
    opacity: 1;
    font-family: 'Spartan', sans-serif;
`;

const DivLogin = styled.div`

    height: 48px;
    
    padding-top:8%;
`;

const LoginTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
    },
    opacity: '1',
    width: '100%',
    height: '48px',
  },
})(TextField);

const DivPassword = styled.div`
    height: 48px;
    
    padding-top:8%;
`;

const PasswordTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
        color: '#ffffff',
      },
    },
    opacity: '1',
    width: '100%',
    height: '48px',
  },
})(TextField);

const DivButton = styled.div`
    height: 44px;
    width:100%;
    align-items: center;
    display: flex;
    margin-top:9%;
`;

const ButtonLogin = withStyles({
  root: {
    background: '#161B5D 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 6px #00000029',
    borderRadius: '4px',
    opacity: '1',
    width: '50%',
    height: '44px',
    color: '#ffffff',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})(Button);

const DivLabel = styled.div`
    top:30%;
    left:57.61%;
    position: fixed;
    width: 29%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LogoImage = styled.img`
    height: 100%;
    display:inline;
`;

const DivForgot = styled.div`
    width:100%;
    align-items: center;
    display: flex;
    padding-top:1%;
`;

const ForgotText = styled.p`
    text-align: left;
    text-decoration: underline;
    letter-spacing: 0px;
    font-family: 'Segoe UI', sans-serif;
    font-size: 20px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
`;

const loginValidationschema = yup.object({
  email: yup.string().email('Insira um email válido').required('Insira um email válido'),
  password: yup.string().required('Senha incorreta'),
});

const loginInitialValue = {
  email: '',
  password: '',
};

function Login() {
  const { userId, authLoadingStep } = useSelector(({ features: { auth } }) => ({
    userId: auth.user,
    authLoadingStep: auth.loadingStep,
  }));

  const dispatch = useDispatch();

  function handleLogin(values, { setFieldError }) {
    const { email, password } = values;
    dispatch(login(email, password)).catch((rejection) => {
      const { code, message } = rejection;
      if (code === 'auth/wrong-password') {
        setFieldError('password', 'Senha inválida');
      } else {
        setFieldError('email', code === 'auth/user-not-found' ? 'Não há usuário correspondente' : message);
      }
    });
  }

  const hasFechedAuthUser = !!userId;
  const history = useHistory();
  const location = useLocation();
  const needRedirect = location.pathname === '/login'
    && hasFechedAuthUser && authLoadingStep === AuthLoadingSteps.AUTH_READY;
  useEffect(() => {
    if (needRedirect) {
      const { from } = location.last || { from: { pathname: '/' } };
      history.replace(from);
    }
  }, [needRedirect, location, history]);

  const handleForgot = () => {
    history.push('/forgotpassword');
  };

  return (
    <Background>
      <BgImage />
      <DivForm>
        <Formik
          validationSchema={loginValidationschema}
          onSubmit={handleLogin}
          initialValues={loginInitialValue}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <LabelLogin>
                <TitleLogin>Login</TitleLogin>
              </LabelLogin>
              <DivLogin>
                <LoginTextField
                  id="login-text"
                  label="Email"
                  name="email"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  variant="outlined"
                  helperText={formik.errors.email ? formik.errors.email : ''}
                  error={formik.errors.email && Boolean(formik.errors.email)}
                />
              </DivLogin>
              <DivPassword>
                <PasswordTextField
                  id="password-text"
                  value={formik.values.password}
                  label="Senha"
                  autoComplete="off"
                  name="password"
                  onChange={formik.handleChange}
                  type="password"
                  variant="outlined"
                  helperText={formik.errors.password ? formik.errors.password : ''}
                  error={formik.errors.password && Boolean(formik.errors.password)}
                />
              </DivPassword>
              <DivButton>
                <ButtonLogin type="submit">Entrar</ButtonLogin>
              </DivButton>
            </form>
          )}
        </Formik>

        <DivForgot>
          <ForgotText style={{ cursor: 'pointer' }} onClick={handleForgot}>Esqueci a senha</ForgotText>
        </DivForgot>
      </DivForm>
      <DivLabel>
        <LogoImage src={logo} alt="Jobler" />
      </DivLabel>

    </Background>
  );
}

export default Login;
