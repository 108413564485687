import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const ThemedFormGroup = withStyles({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})(FormGroup);

export const ThemedCheckBox = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
    '&$disabled': {
    },
  },
  disabled: { },
  checked: {},
})(Checkbox);

export const ThemedFormControlLabel = withStyles({
  root: {
    width: '100%',
  },
  label: {
    color: 'white !important',
  },
})(FormControlLabel);
