import React from 'react';
import { Link } from 'react-router-dom';

import SetaEmpresas from 'assets/SetaEmpresas.svg';
import Switch from 'components/Switch';

import {
  Container, Header, Cell, Body, Row, Card,
} from './styles';

function Table({ data, onSpecialistStatusChange, showingActive }) {
  return (
    <Container>
      <Header>
        <Cell>Nome</Cell>
        <Cell>Entrevistas</Cell>
        <Cell>CVs Enviados</Cell>
        <Cell>Placements</Cell>
        <Cell />
        <Cell />
      </Header>
      <Body>
        {data.map((item) => (
          <Row key={item.id}>
            <Card>
              <Cell>{item.name}</Cell>
              <Cell>{item.specialistAccount.interviewed}</Cell>
              <Cell>{item.specialistAccount.CVsSent}</Cell>
              <Cell>{item.specialistAccount.placements}</Cell>
              <Cell>
                <Link to={{ pathname: '/specialistregister', state: { id: item.id } }}>
                  <img
                    width="16px"
                    height="24px"
                    src={SetaEmpresas}
                    alt="Acessar Empresa"
                  />
                </Link>
              </Cell>
            </Card>
            <Cell>
              <Switch
                name={item.id}
                onChange={onSpecialistStatusChange}
                defaultChecked={showingActive}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Cell>
          </Row>
        ))}
      </Body>

    </Container>
  );
}

export default Table;
