import { createEntitySlice } from 'utils/redux';
import axios from 'app/axios';

const name = 'users';
const endpoint = '/user';
const slice = createEntitySlice(name, endpoint, axios);

export const {
  merge,
  // fetch,
  // fetchById,
  addId,
  dropId,
} = slice.actions;

export default slice.reducer;
