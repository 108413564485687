import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Switch, Route, useHistory, useLocation, Redirect,
} from 'react-router-dom';
import {
  init as initAuth, logout, LoadingSteps as AuthLoadingSteps,
} from 'features/auth';
import { setTalentId } from 'features/talent';
import { fetch as fetchHardSkillsAreaAvailable } from 'entities/hardskillAreas';
import { fetchAreaOfPracticeAvailables } from 'features/areaofpractice';
import { fetchSoftSkillsAvailable } from 'features/softskill';
import Login from 'features/login/Login';
import HardskillAreasList from 'features/hardskillArea/HardskillAreasList';
import CompanyList from 'features/company/CompanyList';
import SpecialistList from 'features/specialist/SpecialistList';
import CompanyRegister from 'features/company/CompanyRegister';
import SpecialistRegister from 'features/specialist/SpecialistRegister';
import SkillMenu from 'features/skills/SkillRegisterMenu';
import Talent from 'features/talent/TalentMainPage';
import CompanyBySpecialist from 'features/company/specialist/CompanyListSpecialist';
// import TalentView from 'features/talent/talentlist/ViewTalents';
import TalentView from 'features/talent/talentlist/TalentsPage';
import VacancyRegisterMain from 'features/vacancy/list/VacancyList';
import VacancyRegisterMainDois from 'features/vacancy/VacancyRegisterMain';
import FavoriteView from 'features/vacancy/favoritelist/ViewFavorites';
import VacancySending from 'features/vacancy/VacancySending';
import CloseVacancy from 'features/kanban/CloseVacancy';
import KanbanNotes from 'features/kanban/Notes';
import Kanban from 'features/kanban/KanbanPage';
import ChangePassword from 'features/changepassword';
import DownloadData from 'features/download/DataDownload';
import Forgot from 'features/forgotpassword';
import ResetPassword from 'features/redefinepassword';

import LoadingProvider from 'components/Loading/LoadingProvider';
import LoadingComponent from 'components/Loading/LoadingComponent';
import Sidebar from './components/SideBar';
import AdminRoute from './AdminRoute';

import { Content } from './styles';

const MuiProgressTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0A0B3A',
    },
    secondary: {
      main: '#000000',
    },
  },
});

export default function MainLayout() {
  // FIXME: Quando houver conhecimento do funcionamento de tipo de usuário logad
  // será necessário criar um mecanismo simples de if/else de disponibilização de rotas
  // e de visualização do menu, de acordo com o usuário logado
  const {
    user, authLoadingStep, type,
  } = useSelector(({ features: { auth } }) => ({
    user: auth.user,
    authLoadingStep: auth.loadingStep,
    type: auth.type,
  }));
  const [selectedPage, setSelectedPage] = useState('Especialistas');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initAuth());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(fetchHardSkillsAreaAvailable());
      dispatch(fetchSoftSkillsAvailable());
      dispatch(fetchAreaOfPracticeAvailables());
    }
  }, [user, dispatch]);

  const hasFechedAuthUser = !!user;
  const history = useHistory();
  const location = useLocation();
  const needLoginRedirect = location.pathname !== '/login'
    && !hasFechedAuthUser && authLoadingStep === AuthLoadingSteps.AUTH_READY;

  useEffect(() => {
    if (needLoginRedirect && location.pathname !== '/forgotpassword' && location.pathname !== '/resetpassword') {
      history.push({
        pathname: '/login',
        last: { from: location },
      });
    }

    if (type !== 'admin' && location.pathname === '/') {
      setSelectedPage('talentview');
    } else if (location.pathname === '/') {
      setSelectedPage('companylist');
    }

    if (!selectedPage.includes('talentview')
      && !location.pathname.includes('talentlist')
      && !location.pathname.includes('vacancysending')) {
      dispatch(setTalentId(0));
    }
    setSelectedPage(location.pathname.replace('/', ''));
  }, [needLoginRedirect, location, history, selectedPage]);

  function handleLogout() {
    dispatch(logout());
  }

  return (

    authLoadingStep < AuthLoadingSteps.AUTH_READY ? <CircularProgress />
      : (
        <Grid container style={{ height: '100vh' }}>
          <LoadingProvider value>
            <MuiThemeProvider theme={MuiProgressTheme}>
              <LoadingComponent />
            </MuiThemeProvider>
            <Sidebar
              handleLogout={handleLogout}
              authType={type}
            />
            <Content>
              <Switch>
                {/*
                  /companies
                  /companies/new
                  /companies/1
                  /specialists
                  /specialists/new
                  /specialists/:id
                  /vacancies/companies
                  /vacancies/companies/:id
                  /vacancies/new
                  /vacancies/:id
                  /kanban/:id
                  /kanban/:id/notes
                  /kanban/:id/close
                  /talents/new
                  /talents/:id
                  /talents/:id/vacancy
                  /talents/:id/:id
                  /favorites/:id/:id
                */}

                <Route path="/talents/new" component={Talent} />
                <Route path="/talents/edit/:id/vacancy" component={VacancySending} />
                <Route path="/talents/edit/:id" component={Talent} />
                <Route path="/talents/:companyId?/:vacancyId?" component={TalentView} />

                <Route path="/favorites/:companyId?/:vacancyId?" component={FavoriteView} />

                <Route path="/vacancies/company/:id" component={VacancyRegisterMain} />
                <Route path="/vacancies/company" component={CompanyBySpecialist} />

                <Route path="/vacancies/:id/close" component={CloseVacancy} />
                <Route path="/vacancies/:id/kanban/notes" component={KanbanNotes} />
                <Route path="/vacancies/:id/kanban" component={Kanban} />

                <Route path="/hardskillAreas" component={HardskillAreasList} />
                <Route path="/login" component={Login} />
                <Route path="/vacancyregistervaga" component={VacancyRegisterMainDois} />
                <Route path="/changepassword" component={ChangePassword} />
                <Route path="/forgotpassword" component={Forgot} />
                <Route path="/resetpassword" component={ResetPassword} />

                <AdminRoute path="/listcompany" component={CompanyList} />
                <AdminRoute path="/company" component={CompanyRegister} />
                <AdminRoute path="/specialist" component={SpecialistList} />
                <AdminRoute path="/specialistregister" component={SpecialistRegister} />
                <AdminRoute path="/skill" component={SkillMenu} />
                <AdminRoute path="/download" component={DownloadData} />

                <Redirect to={type === 'admin' ? '/listcompany' : '/talents'} />
              </Switch>
            </Content>
          </LoadingProvider>
        </Grid>
      )

  );
}
