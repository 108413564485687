import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputAdornment, Paper, Tabs } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Table from 'features/company/components/CompanyTable';
import Pagination from 'components/Pagination';
import SearchTextField from 'components/SearchTextField';
import Tab from 'components/Tab';
import Button from 'components/Button';

import { fetchRequesterByParams } from '../requester';
import { updateCompany } from '.';

import {
  HeaderContainer, TabsContainer, SearchContainer,
} from './styles';

function ListarEmpresa() {
  const { companyItem } = useSelector((
    { entities },
  ) => ({
    companyItem: entities.requesters,
  }));

  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const data = (companyItem.allIds || []).slice(page * 5, page * 5 + 5).map((item) => companyItem.byId[item]);

  useEffect(() => {
    dispatch(fetchRequesterByParams({ isActive: status ? 1 : 0, name: `%${searchText}%` }));
  }, [dispatch, status]);

  useEffect(() => {

  }, [page]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setStatus(newValue === 0);
    setPage(0);
  };

  const handleSearchTextChange = (value) => {
    setSearchText(value);
    dispatch(fetchRequesterByParams(
      { isActive: status ? 1 : 0, name: `%${value}%` },
    ));
  };

  async function handleCompanyStatusChange(event) {
    const statusCheck = event.target.checked;
    dispatch(updateCompany(event.target.name, event.target.checked))
      .then(() => {
        dispatch(fetchRequesterByParams({ isActive: status ? 1 : 0, name: `%${searchText}%` }));
        if (statusCheck) {
          toast.info('Empresa ativada com sucesso');
        } else {
          toast.info('Empresa desativada com sucesso');
        }
      })
      .catch(() => {
        if (statusCheck) {
          toast.error('Erro ao ativar empresa');
        } else {
          toast.error('Erro ao desativar empresa');
        }
      });
  }

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  const handleAddCompanyPage = () => {
    history.push('/company', { id: 0 });
  };

  return (
    <>
      <HeaderContainer>
        <div style={{ flex: 1 }}>
          <Button
            onClick={handleAddCompanyPage}
            style={{
              width: '239px',
              heigth: '44px',
            }}
          >
            Adicionar Empresa
          </Button>
        </div>
        <TabsContainer>
          <Paper square style={{ background: '#171836' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab
                style={{ backgroundColor: activeTab === 0 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
                disableRipple
                label="Ativadas"
              />
              <Tab
                style={{ backgroundColor: activeTab === 1 ? 'rgba(66, 90, 224, 0.2)' : 'transparent' }}
                label="Desativadas"
              />
            </Tabs>
          </Paper>
        </TabsContainer>
        <div style={{ flex: 1 }} />
      </HeaderContainer>

      <SearchContainer>
        <SearchTextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onDebounceValue={handleSearchTextChange}
          InputProps={{
            endAdornment: <InputAdornment position="start"><FaSearch size={20} /></InputAdornment>,
          }}
        />
      </SearchContainer>

      <Table data={data} onCompanyStatusChange={handleCompanyStatusChange} showingActive={status} />

      <Pagination
        count={companyItem.allIds ? Math.ceil(companyItem.allIds.length / 5) : 1}
        page={page + 1}
        onChange={handlePageChange}
        shape="rounded"
      />
    </>
  );
}

export default ListarEmpresa;
