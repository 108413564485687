import React from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const TitleRegister = styled.h2`
  font-size: "26px";
  font-weight: bolder;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const TitleRegisterAccordion = styled.h3`
  font-size: "26px";
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

const SecondaryFourLineList = styled.div`
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
  column-count: 1;
`;

const ContainerTextFields = styled.div`
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10vh;
`;

const ThemedCheckBox = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
  },
  checked: {},
})(MaterialCheckbox);

const ThemedAccordion = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root': {
      padding: '0px',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
      height: '100%',
      width: '200px',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '9%',
    },
    '&$expanded': {
      margin: '0px',
    },
    boxShadow: 'none',
  },
  expanded: {},
})(Accordion);

export default function FilterSoftskill({ value, onChange }) {
  const softSkill = useSelector((state) => state.entities.softskill);

  return (

    <div>
      <ContainerTextFields>
        <TitleRegister>Soft Skill</TitleRegister>
      </ContainerTextFields>
      <Grid container>
        {
                softSkill.allIds ? softSkill.allIds.map((skill) => {
                  const softskillItem = softSkill.byId[skill];
                  return (
                    <Grid key={softskillItem.id} item xs={4}>
                      <ThemedAccordion key={softskillItem.id}>
                        <AccordionSummary
                          expandIcon={(
                            <ExpandMoreIcon
                              style={{ color: 'white', marginLeft: '50px' }}
                            />
                    )}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <TitleRegisterAccordion>
                            {softskillItem.name}
                          </TitleRegisterAccordion>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SecondaryFourLineList>
                            {softskillItem ? (
                              softskillItem.softskillLevel.map((linha) => (
                                <FormControl>
                                  <FormGroup>
                                    <FormControlLabel
                                      style={{ color: 'white' }}
                                      control={(
                                        <ThemedCheckBox
                                          value={linha.id}
                                          checked={value.includes(linha.id)}
                                          name={linha.id}
                                          onChange={onChange}
                                        />
                                      )}
                                      label={linha.level}
                                    />
                                  </FormGroup>
                                </FormControl>
                              ))
                            ) : (
                              <></>
                            )}
                          </SecondaryFourLineList>
                        </AccordionDetails>
                      </ThemedAccordion>
                    </Grid>
                  );
                })
                  : <></>
                  }
      </Grid>
    </div>
  );
}
