import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import Pagination from 'components/Pagination';
import SearchTextField from 'components/SearchTextField';
import * as qs from 'utils/queryString';

import Table from './components/CompanyTable';
import { fetchRequesterByParams } from '../../../requester';
import { ContainerHeader, TitleRegister } from './styles';

function ListarEmpresa() {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = qs.qsStringToObject(history.location.search);

  const [searchText, setSearchText] = useState((search.name || '').replaceAll('%', ''));

  const { allIds, byId } = useSelector((state) => (state.entities.requesters));
  const { type: authType, user } = useSelector((state) => (state.features.auth));
  const totalList = useSelector((state) => state.features.requester.totalList);

  const data = (allIds || [])
    .slice((Number(search.page || 1) - 1) * 5, (Number(search.page || 1) - 1) * 5 + 5)
    .map((item) => byId[item]);

  useEffect(() => {
    if (!authType) return;
    dispatch(fetchRequesterByParams({
      ...search,
      isActive: 1,
    }));
    // eslint-disable-next-line
  }, [dispatch, authType, history.location.search]);

  useEffect(() => {
    if (!user?.id) return;
    const requester = byId[user.id];
    if (authType === 'requester' && requester) {
      history.replace(`${history.location.pathname}/${requester.id}`);
    }
    // eslint-disable-next-line
  }, [byId, authType, user]);

  const handleSearchTextChange = (value) => {
    history.push(`${history.location.pathname}${qs.qsObjectToString({
      ...search, page: 1, name: value ? `%${value}%` : undefined,
    })}`);
  };

  const handlePageChange = (event, value) => {
    history.push(`${history.location.pathname}${qs.qsObjectToString({ ...search, page: value })}`);
  };

  const onItemClick = (requesterId) => {
    history.push(`${history.location.pathname}/${requesterId}`);
  };

  return (
    <>
      <ContainerHeader>
        <TitleRegister>
          EMPRESAS CADASTRADAS
        </TitleRegister>
        <SearchTextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onDebounceValue={handleSearchTextChange}
          InputProps={{
            endAdornment: <InputAdornment position="start"><FaSearch size={20} /></InputAdornment>,
          }}
        />
      </ContainerHeader>

      <Table data={data} onItemClick={onItemClick} />

      <Pagination
        count={Math.ceil(totalList / 5) || 1}
        page={Number(qs.qsStringToObject(history.location.search).page) || 1}
        onChange={handlePageChange}
        shape="rounded"
      />
    </>
  );
}

export default ListarEmpresa;
