import { fetchById as fetchHardskillAreaById } from 'entities/hardskillAreas';
import { createSlice } from '@reduxjs/toolkit';

import axios from 'app/axios';

const initialState = {
  hardskillSel: [],
};

export const hardskillSlice = createSlice({
  name: 'hardskill',
  initialState,
  reducers: {
    setHardskillSelected: (state, { payload: hardskillSelected }) => {
      state.hardskillSel = !hardskillSelected ? initialState.hardskillSel : hardskillSelected;
    },
  },
});

const {
  setHardskillSelected,
} = hardskillSlice.actions;

const hardskillsEndpoint = '/hardskill';

export const add = ({ name, hardskillAreaId }) => async (dispatch) => axios
  .post(hardskillsEndpoint, { name, hardskillAreaId }).then(() => {
    dispatch(fetchHardskillAreaById(hardskillAreaId));
  });

export const update = ({ id, name, hardskillAreaId }) => async (dispatch) => axios
  .patch(`${hardskillsEndpoint}/${id}`, { name }).then(() => {
    dispatch(fetchHardskillAreaById(hardskillAreaId));
  });

export const remove = ({ id, hardskillAreaId }) => async (dispatch) => axios
  .delete(`${hardskillsEndpoint}/${id}`).then(() => {
    dispatch(fetchHardskillAreaById(hardskillAreaId));
  });

export const selectHardskill = (hardskills) => (dispatch) => {
  dispatch(setHardskillSelected(hardskills));
};

export default hardskillSlice.reducer;
