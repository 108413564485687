import React from 'react';
import PropTypes from 'prop-types';
import { TextField, withStyles } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce/lib';

const StyledSearchTextField = withStyles({
  root: ({ value }) => ({
    width: value ? '40ch' : '4ch',
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: '23px',
    backgroundColor: '#fcfcfb',
    transition: 'width 0.4s',
    '&:hover': {
      backgroundColor: '#fff',
      width: '40ch',
    },
    '& .MuiInputBase-input': {
      marginLeft: '10%',
    },
    '& .Mui-focused': {
    },
  }),
})(TextField);

const SearchTextField = ({ onDebounceValue, onChange, ...rest }) => {
  const debounced = useDebouncedCallback(onDebounceValue, 500);

  return (
    <StyledSearchTextField
      {...rest}
      onChange={(e) => {
        onChange(e);
        debounced.callback(e.target.value);
      }}
    />
  );
};

SearchTextField.propTypes = {
  onDebounceValue: PropTypes.func,
  onChange: PropTypes.func,
};

SearchTextField.defaultProps = {
  onDebounceValue: () => {},
  onChange: () => {},
};

export default SearchTextField;
