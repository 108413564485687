import styled from 'styled-components';
import {
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  createMuiTheme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const MuiListTheme = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#425AE0',
        '&$selected': {
          backgroundColor: '#425AE0',
        },
      },
    },
  },
});

export const Container = styled.div`
    flex: 1;
    padding: 0 var(--horizontal-padding);
    display: flex;
    flex-direction: column;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  color: #ffffff;
  align-items: center;
  justify-content: space-between;
`;

export const SelectDiv = styled.div`
    margin-top: 32px;
    display: flex;
`;

export const ThemedFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& label': {
      color: '#ffffff',
    },
    '& input': {
      color: '#ffffff',
    },
    '& textarea': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&:hover fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425AE0',
        color: '#425AE0',
      },
    },
    width: '20%',
  },
})(FormControl);

export const ThemedSelect = withStyles({
  root: {
    color: '#ffffff',
  },
})(Select);

export const ThemedMenuItem = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#171836',
    '&$selected': {
      backgroundColor: '#777777 !important',
    },
    '&:hover': {
      backgroundColor: '#425AE0',
    },
    selected: {},
  },
})(MenuItem);

export const EmptyList = styled.div`
  text-align: center;
  color: #9F9F9F;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 18px 18px 0;
`;

export const ContainerSearch = styled.div`
  display: flex; 
  justify-content: flex-end;
  align-items: center;
`;

export const ThemedFormControlLabel = withStyles({
  root: {
    '& .MuiTypography-root': {
      color: '#FFFFFF',
    },
  },
})(FormControlLabel);

export const ThemedRadio = withStyles({
  root: {
    color: '#425AE0',
    '&$checked': {
      color: '#425AE0',
    },
  },
  checked: {},
})(Radio);

export const HelpButton = styled.button`
background: #171836;
border: #171836;
margin-left: 10px; 
height: 30px;
`;

export const HelpImg = styled.img`
  cursor: pointer;
  width: 30px;
  height:30px;
`;

export const ListCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
